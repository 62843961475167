import { useMemo } from "react";
import { bool, number, object, string } from "prop-types";
import { observer } from "mobx-react";
import { Grid, Tooltip } from "@material-ui/core";
import { TimelineChartBar } from "./TimelineChartBar/TimelineChartBar";
import useStyle from "./ChartCell.style";
import useCellStyle from "@components/ProjectEditor/Tables/cells/commonStyles.style";
import classnames from "classnames";
import { roundFloat } from "project-structure";
import { useStructureStore } from "@hooks";
import { useTranslation } from "react-i18next";

export const ChartCell = observer(({
  index,
  color,
  className,
  isMonth,
  timelineChartSegments,
  displayCellContent,
  value=0,
}) => {
  const {t} = useTranslation();
  const classes = useStyle();
  const cellClasses = useCellStyle();
  const structure = useStructureStore();
  const { modifier, timeModifier, roundHours, usedRoundDecimals, showDecimals } = structure.settings;
  
  const usedTimelineChartSegments = useMemo(() => (
    // timelineChartSegments?.find(s => (s.start - s.start%4) === monthIndex*4)
    timelineChartSegments?.filter(s => (isMonth ? s.startMonth * 4 : s.startWeek) === index)
  ), [timelineChartSegments, index, isMonth])
  
  const displayValue = useMemo(() => (
    displayCellContent
      ? roundFloat(
        value ? (isMonth ? value : (value / modifier)) : 0,
        showDecimals ? roundHours : undefined,
        showDecimals,
        usedRoundDecimals
      )
      : ""
  ), [value, isMonth, modifier, showDecimals, usedRoundDecimals, displayCellContent]);
  
  return (
    <Tooltip
      title={displayValue ? `${displayValue} ${timeModifier === 1 ? t("times.hour") : t("times.day")}` : ""}
      placement="top-start"
    >
      <Grid
        item container
        alignItems="center"
        className={classnames(
          "h-full",
          cellClasses.collapseCellInner,
          cellClasses.fullDashBorderRight,
          isMonth ? classes.cell : classes.narrow,
          className,
        )}
      >
        {
          displayCellContent &&
          usedTimelineChartSegments?.map(({ weight, ...timelineChartSegmentStart }, i) => (
            <TimelineChartBar
              key={i}
              color={color}
              value={weight}
              {...timelineChartSegmentStart}
            />
          ))
        }
      </Grid>
    </Tooltip>
  )
})

ChartCell.propTypes = {
  index: number.isRequired,
  color: string.isRequired,
  className: string,
  timeline: object,
  isMonth: bool,
  displayCellContent: bool,
  value: number,
}