import { ValueCell } from "@components";
import { observer } from "mobx-react";
import { useStructureStore, useVisibilityModeCellClassName } from "@hooks";

export const TimeCell = observer(() => {
  const structure = useStructureStore();
  const { hideSummaryTime } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryTime);
  
  return <ValueCell widest className={className} />;
})