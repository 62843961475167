import { useMemo, useEffect } from "react";
import { arrayOf, bool, func, oneOf, string } from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ControlledTextField, InfoLabel } from "@components";
import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { emailRegex } from "./emailRegex";

const workspaceInviteSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .matches(emailRegex, t("errors.email.required"))
      .required(t("errors.email.required"))
      .max(64, t("errors.email.max")),
  });

export const WorkspaceInviteForm = ({
  onSubmit,
  onChange,
  fieldName,
  forbiddenEmails,
  useFieldSpecificName,
}) => {
  const { t } = useTranslation();
  const schema = useMemo(() => workspaceInviteSchema(t), []);

  const {
    watch,
    control,
    handleSubmit,
    setError,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    const subscription = watch(checkField);
    return () => subscription.unsubscribe();
  }, [watch]);

  const checkField = (value) => {
    if (schema.isValidSync(value)) onChange && onChange(value.email);
  };

  const submitHandler = async (data) => {
    if (forbiddenEmails.includes(data.email)) {
      setError("email", { type: "manual", message: t("errors.email.invited") });
      return;
    }
    await onSubmit(data);
    resetForm();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <InfoLabel
        label={t(
          `forms.board_invite.${
            useFieldSpecificName ? `invite_${fieldName}` : "invite_user"
          }`
        )}
        tooltipText={
          useFieldSpecificName && t(`role_descriptions.${fieldName}`)
        }
      />
      <ControlledTextField
        type="email"
        name="email"
        control={control}
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton aria-label="accept value" color="primary" type="submit">
              <Add />
            </IconButton>
          ),
        }}
      />
    </form>
  );
};

WorkspaceInviteForm.propTypes = {
  onSubmit: func.isRequired,
  onChange: func,
  fieldName: oneOf(["technical", "business"]).isRequired,
  forbiddenEmails: arrayOf(string).isRequired,
  useFieldSpecificName: bool,
};
