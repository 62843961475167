import { APIMethod, fetchData } from "@client";

export async function getNewNotificationsQuery() {
  const res = await fetchData("/user/notifications", APIMethod.GET, {
    params: {
      type: "unread",
      page: 1,
      limit: 100
    }
  });
  return res.results;
}
