import { FolderOpen } from "@material-ui/icons";
import { Alert, MenuButton } from "@components";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { changeProjectStatusQuery } from "@query";
import { PROJECT_STATUS } from "@utils";
import { useState } from "react";
import { string } from "prop-types";
import { useActiveProjectStore, useActiveProjectsWebsocket } from "@hooks";

export const ArchiveProject = ({ uuid }) => {
  
  const { t } = useTranslation();
  const activeProjects = useActiveProjectStore();
  const socket = useActiveProjectsWebsocket();
  
  const [archiveDialog, setArchiveDialog] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [isLoading, setLoading] = useState(false);
  
  const openArchiveDialog = (s) => () => {
    setArchiveDialog(s);
  };
  
  const archiveProject = async (e) => {
    e.stopPropagation();
    setLoading(true);
    try {
      await changeProjectStatusQuery(uuid, PROJECT_STATUS.ARCHIVE);
      socket?.requestProjectArchive(uuid);
      activeProjects.editLocalProjectData(uuid, "archive");
    } catch (e) {
      setAlertText(e.response.data.error);
    }
    setArchiveDialog(false);
    setLoading(false);
  };
  
  return <>
    <MenuButton icon={<FolderOpen />} onClick={openArchiveDialog(true)}>
      {t("common.archive")}
    </MenuButton>
    <Alert
      isOpen={archiveDialog}
      onAccept={archiveProject}
      onCancel={openArchiveDialog(false)}
      title={t("views.active.popup.archive_question")}
      acceptText={
        isLoading ? (
          <CircularProgress
            aria-label="progress indicator"
            size={24}
            color="inherit"
          />
        ) : (
          t("views.active.popup.archive_accept")
        )
      }
    />
    <Alert
      title={alertText}
      isOpen={!!alertText}
      onAccept={() => setAlertText("")}
      acceptText={t("common.close")}
    />
  </>
}

ArchiveProject.propTypes = {
  uuid: string.isRequired
}