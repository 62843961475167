import { bool, func, string } from "prop-types";
import { Button, ControlledTextField } from "@components";
import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const newBoardFormSchema = (t) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t("errors.board.required"))
      .min(4, t("errors.board.min"))
      .max(64, t("errors.board.max")),
  });

export const NewBoardForm = ({
  onSubmit,
  defaultName,
  isFirst
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(newBoardFormSchema(t)),
    defaultValues: {
      name: defaultName || "",
    },
  });

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors } = result;

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  return (
    <form
      autoComplete="on"
      aria-label="new board form"
      onSubmit={handleSubmit(submitHandler)}
      className="mt-1"
    >
      <Grid item container direction={isFirst ? "column" : "row"} alignItems="center">
        <ControlledTextField
          name="name"
          control={control}
          label={t("views.board.name")}
          helperText={isFirst ? t("views.board.create_first") : undefined}
          fullWidth
        />
        {
          isFirst && <>
            <h1 className="mt-4 mb-8">
              { t("views.board.start_trial") }
            </h1>
            
            <p className="mb-1 text-center font-lighter">
              { t("views.board.desc_1") }
            </p>
            <p className="mt-4 text-center font-lighter">
              { t("views.board.desc_2") }
            </p>
          </>
        }
        
        <Grid
          item container
          justifyContent={isFirst ? "center" : "flex-end"}
        >
          <Button
            isSubmit
            name="submit"
            variant="contained"
            fullWidth={isFirst}
            className="mt-6"
            icon={
              isSubmitting ? (
                <CircularProgress
                  aria-label="progress indicator"
                  size={ 24}
                  color="inherit"
                />
              ) : undefined
            }
          >
            {!isSubmitting && t(`views.board.${isFirst ? "start" : "create"}`)}
          </Button>
        </Grid>
      </Grid>
      
      {
        isFirst && <>
          <Divider className="mt-4 mb-6" />
  
          <Grid item container direction="column" alignItems="center">
            <p className="text-xs font-lighter my-2">
              {t("views.board.info_1")}
            </p>
            <p className="text-xs font-lighter my-2">
              {t("views.board.info_2")}
            </p>
            <p className="text-xs font-lighter my-2">
              {t("views.board.info_3")}
            </p>
            <p className="text-xs font-lighter my-2">
              {t("views.board.info_4")}
            </p>
          </Grid>
        </>
      }
    </form>
  );
};

NewBoardForm.propTypes = {
  onSubmit: func.isRequired,
  defaultName: string,
  isFirst: bool,
};
