import { useMemo } from "react";
import { arrayOf, bool, func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStores } from "@hooks";
import { uploadFontToS3 } from "@client";
import { createCustomFontQuery } from "@query";
import { Alert, Button, ControlledTextField, Dialog, FileUploader, InfoLabel } from "@components";
import { CircularProgress } from "@material-ui/core";

const customFontFormSchema = (t, usedFontNames) =>
  yup.object().shape({
    name: yup
      .string()
      .test(
        "duplicate_name",
        t("errors.font.name.duplicate"),
        (val) => !usedFontNames.includes(val.toLowerCase())
      )
      .required(t("errors.font.name.required"))
      .max(64, t("errors.font.name.max")),
    files: yup.mixed().test(
      "required",
      t("errors.font.file.required"),
      (files) => files?.length > 0)
  });

export const CustomFontDialog = ({
  open,
  onClose,
  onFontAdd,
  usedFontNames,
}) => {
  const { t } = useTranslation();
  const { stateStore } = useStores();
  
  const defaultValues = useMemo(() => ({
    name: "",
    files: []
  }), []);
  
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isSubmitted },
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(customFontFormSchema(t, usedFontNames)),
    defaultValues,
  });
  
  const handleFileError = (message) => {
    setError("files", { type: "custom", message })
  }
  
  const submitFontFile = async ({ files, name }) => {
    const file = files[0];
    
    const { Location } = await uploadFontToS3(
      stateStore.getFontFilePath(name, file.name.split(".").slice(-1)[0]),
      file.slice(),
      file.type
    );
    const id = await createCustomFontQuery(name, Location);
    onFontAdd(id, name, Location, true);
  }
  
  const handleSuccessClose = () => {
    onClose();
    resetForm(defaultValues);
  }
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("views.editor.preset.custom_font.title")}
      actions={
        <>
          <Button
            variant="outlined"
            onClick={onClose}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(submitFontFile)}
          >
            {
              isSubmitting
                ? <CircularProgress size={16} className="text-alt" />
                : t("views.editor.preset.custom_font.upload")
            }
          </Button>
        </>
      }
    >
      <InfoLabel spacing={8} label="views.editor.preset.custom_font.name" />
      <ControlledTextField
        control={control}
        name="name"
        placeholder={t("views.new_project.enter_name")}
      />
      
      <InfoLabel spacing={8} label="views.editor.preset.custom_font.upload" />
      <Controller
        name="files"
        control={control}
        render={({ field: { onChange, value, name }, fieldState: { error }}) => (
          <FileUploader
            onAdd={onChange}
            onRemove={() => onChange([])}
            files={value}
            inputName={name}
            acceptedFormats={{ "font/*": [".otf", ".ttf", ".woff", ".woff2"]}}
            maxSize={5*1024*1024}
            text={t("views.editor.preset.custom_font.text")}
            textActive={t("views.editor.preset.custom_font.text_active")}
            errorText={error?.message}
            setError={handleFileError}
            showBrowseLink
          />
        )}
      />
      <Alert
        title={t("views.editor.preset.custom_font.success")}
        isOpen={isSubmitted}
        onAccept={handleSuccessClose}
        acceptText={t("common.close")}
      >
        {t("views.editor.preset.custom_font.success2")}
      </Alert>
    </Dialog>
  );
};

CustomFontDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onFontAdd: func.isRequired,
  usedFontNames: arrayOf(string).isRequired, //lowercase font names
};