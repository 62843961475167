import { useState } from "react";
import { arrayOf, bool, func, number, oneOfType, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { useStores } from "@hooks";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Alert, MenuButton, Dialog, Button, PopMenu, TextField } from "@components";
import { Trash, Pencil } from "@assets";
import { MoreHoriz } from "@material-ui/icons";
import { removeWorkspaceQuery, updateWorkspaceNameQuery } from "@query";

export const BoardsMenu = ({
  uuid,
  enableDeletion,
  loadData,
  handleSelect
}) => {

	const { t } = useTranslation();
	const { userStore} = useStores();

	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [menuVisible, openMenu] = useState(null);
	const [isSaving, setIsSaving] = useState(false);
	const [deletionAlertOpen, openDeletionAlert] = useState(false);
	const [nameEditorOpen, openNameEditor] = useState(false);
	const [alertText, setAlertText] = useState("");
	const [tempName, setTempName] = useState("");


	const removeBoard = async () => {
		try {
			setIsSaving(true);
			await removeWorkspaceQuery(uuid);
      userStore.removeBoard(uuid);
			setIsSaving(false);
			setTempName("");
			if(userStore.workspace && userStore.workspace.uuid === uuid)
				handleSelect(userStore.availableWorkspaces[0].uuid);
			else
				loadData();
		} catch(e) {
			setIsSaving(false);
			setAlertText(e.response.data ? e.response.data.message || "Something went wrong" : "Something went wrong");
		}
	};

	const setWorkspaceName = async () => {
		try {
			setIsSaving(true);
      await updateWorkspaceNameQuery(uuid, tempName)
			userStore.changeCurrentBoardName(uuid, tempName);
			setIsSaving(false);
			setTempName("");
			openNameEditor(false);
			setMenuAnchorEl(null);
			loadData();
		} catch(e) {
			setIsSaving(false);
			setAlertText(e.response.data ? e.response.data.message || "Something went wrong" : "Something went wrong");
		}
	};
	
	return <>
		<IconButton
			color="primary"
			onClick={() => openMenu(!menuVisible)}
			ref={setMenuAnchorEl}
			aria-label="menu"
		>
			<MoreHoriz />
		</IconButton>
		<PopMenu
			show={menuVisible}
			anchor={menuAnchorEl}
			placement="bottom"
			// style={{padding:"8px 0 8px 0", width: 240}}
			onClickAway={() => openMenu(false)}
		>
			<MenuButton
        icon={<Pencil />}
        onClick={() => openNameEditor(true)}
      >
				{t("views.board.edit_name")}
			</MenuButton>
			{
				enableDeletion &&
				<MenuButton
          icon={<Trash color="error" />}
          onClick={() => openDeletionAlert(true)}
        >
					{t("views.board.delete")}
				</MenuButton>
			}
		</PopMenu>
		{
			deletionAlertOpen &&
      <Alert
				title={t("views.board.deletion_alert.title")}
				onCancel={() => openDeletionAlert(false)}
				onAccept={removeBoard}
				isOpen={deletionAlertOpen}
			/>
		}
		{
			nameEditorOpen &&
      <Dialog
				open={nameEditorOpen}
				onClose={() => openNameEditor(false)}
				width={420}
				title={t("views.board.edit_name")}
				actions={<>
					<Button
						variant="outlined"
						onClick={() => openNameEditor(false)}
						className="mr-2"
					>
						{t("common.cancel")}
					</Button>
					<Button
						variant="contained"
						icon={isSaving ? <CircularProgress color="inherit" size={20}/> : null}
						onClick={setWorkspaceName}
					>
						{t("common.save")}
					</Button>
				</>}
			>
				<TextField
					id="feedback"
					value={tempName}
					label={t("views.board.name")}
					onChange={e => setTempName(e.target.value)}
				/>
			</Dialog>
		}
		{
			!!alertText &&
      <Alert
				title={alertText}
				isOpen={!!alertText}
				useCancel={false}
				onAccept={() => setAlertText("")}
				acceptText={t("common.close")}
			/>
		}
	</>;
};

BoardsMenu.propTypes = {
	uuid: oneOfType([string, number]).isRequired,
	handleSelect: func.isRequired,
	loadData: func.isRequired,
	enableDeletion: bool,
	workspaceUserEmails: arrayOf(shape({
		email: string.isRequired,
	})).isRequired,
};
