import { db } from "@client";
import axios from "axios";

export async function persistUserData(userId, token, persist) {
  try {
    if(!userId || !token) return;
    const timestamp = new Date().getTime();
    axios.defaults.headers.common["Authorization"] = token;
    // const token = axios.defaults.headers.common["Authorization"];
    // if(!token) return;
    await db.user.put({ userId, token, persist, timestamp });
  } catch (e) {
    console.error(e);
  }
}