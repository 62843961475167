import { fetchData, APIMethod } from "@client";
import { CURRENT_STRUCTURE_VERSION } from "project-structure";

export async function getProjectVersionStructureQuery(uuid, cacheOff) {
  return await fetchData(`/project/${uuid}`, APIMethod.GET, {
    params: {
      v: CURRENT_STRUCTURE_VERSION,
      structure: 1,
      cacheOff: cacheOff ? 1 : undefined
    },
    mapRequestKeysToSnakeCase: false,
  });
}
