import { bool, func, node, string, number, oneOfType, object } from "prop-types";
import { ClearInput } from "@components";
import { Divider, Grid } from "@material-ui/core";
import useStyle from "../Totals.style";
import classnames from "classnames";

export const TotalValueEditable = ({
  min,
  max,
  symbolStart,
  symbolEnd,
  showMaxValue,
  onValueChange,
  children,
  showDecimals,
  textSmaller,
  style,
  maxValue,
  decimals
}) => {
  const classes = useStyle();
  
  const handleValueChange = (isMax) => (value) => {
    onValueChange && onValueChange(value, isMax);
  };
  
  return (
    <Grid
      item
      container
      alignItems="center"
      className={classnames(
        "preset-total w-max font-bold",
        textSmaller ? "text-2xl mt-2 text-secondary" : "text-4xl"
      )}
      style={style}
    >
      {symbolStart}
      <ClearInput
        value={min.toString()}
        onChange={handleValueChange(false)}
        alignContent="center"
        fixedDecimalScale={showDecimals}
        decimals={decimals}
        maxWidth
        style={style}
        max={maxValue}
      />
      {
        showMaxValue &&
        <Divider className={classes.totalCostDivider} />
      }
      {
        showMaxValue &&
        <ClearInput
          value={max}
          onChange={handleValueChange(true)}
          alignContent="center"
          fixedDecimalScale={showDecimals}
          maxWidth
          style={style}
          max={maxValue}
        />
      }
      {symbolEnd}
      {children}
    </Grid>
  )
}

TotalValueEditable.propTypes = {
  min: oneOfType([number, string]).isRequired,
  max: oneOfType([number, string]),
  children: node, //additional items to show after value & symbols
  symbolStart: string,
  symbolEnd: string,
  showMaxValue: bool,
  onValueChange: func,
  showDecimals: bool,
  textSmaller: bool,
  style: object,
  maxValue: number,
  decimals: number,
}