import { SvgIcon } from "@material-ui/core";

export const XlsFile = (props) => (
  <SvgIcon {...props} viewBox="0 0 64 64" fill="none">
    <path
      d="M34.666 30.666C30.986 30.666 28 27.68 28 24C28 21.36 29.56 19.014 31.88 17.946C32.254 17.226 32.706 16.572 33.254 16H19.666C17.64 16 16 17.64 16 19.666V44.332C16 46.36 17.64 48 19.666 48H39C41.026 48 42.666 46.36 42.666 44.334V30.668H34.666V30.666ZM25.208 43.848C25.476 44.332 25.304 44.94 24.82 45.208C24.666 45.294 24.5 45.334 24.336 45.334C23.984 45.334 23.644 45.148 23.46 44.82L22.668 43.392L21.876 44.82C21.694 45.148 21.352 45.334 21 45.334C20.836 45.334 20.67 45.294 20.516 45.208C20.032 44.94 19.86 44.332 20.128 43.848L21.524 41.334L20.128 38.82C19.86 38.336 20.032 37.728 20.516 37.46C21 37.192 21.608 37.366 21.876 37.848L22.668 39.276L23.46 37.848C23.726 37.366 24.336 37.192 24.82 37.46C25.304 37.728 25.476 38.336 25.208 38.82L23.812 41.334L25.208 43.848ZM31 45.334H27.666C27.114 45.334 26.666 44.886 26.666 44.334V38.334C26.666 37.782 27.114 37.334 27.666 37.334C28.218 37.334 28.666 37.782 28.666 38.334V43.334H31C31.552 43.334 32 43.782 32 44.334C32 44.886 31.552 45.334 31 45.334ZM35.722 40.334H36.276C37.594 40.334 38.666 41.456 38.666 42.834C38.666 44.212 37.594 45.334 36.276 45.334H34.332C33.78 45.334 33.332 44.886 33.332 44.334C33.332 43.782 33.78 43.334 34.332 43.334H36.276C36.486 43.334 36.666 43.104 36.666 42.834C36.666 42.564 36.488 42.334 36.276 42.334H35.722C34.404 42.334 33.332 41.212 33.332 39.834C33.332 38.456 34.404 37.334 35.722 37.334H37.666C38.218 37.334 38.666 37.782 38.666 38.334C38.666 38.886 38.218 39.334 37.666 39.334H35.722C35.512 39.334 35.332 39.564 35.332 39.834C35.334 40.104 35.512 40.334 35.722 40.334Z"
      fill="#26DAA4"
    />
    <path
      d="M43.334 28H34.668C32.462 28 30.668 26.206 30.668 24C30.668 22.056 32.06 20.432 33.9 20.074C34.592 17.704 36.802 16 39.334 16C41.332 16 43.182 17.064 44.192 18.75C46.368 19.148 48 21.06 48 23.334C48 25.906 45.906 28 43.334 28Z"
      fill="#26DAA4"
      fillOpacity="0.5"
    />
  </SvgIcon>
);
