import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ProjectEditorStore, ProjectEditorStoreProvider } from "@stores";
import { useStores } from "@hooks";
import { checkProjectAccessQuery } from "@query";
import { PROJECT_TYPE } from "@utils";
import { home } from "@paths";
import { PageCircularProgress } from "@components";
import { ProjectLoadingAlert } from "@dialogs";
import { PdfEditorView } from "./PdfEditorView";

export const PdfEditor = () => {
  const navigate = useNavigate();
  const { stateStore } = useStores();
  const { projectUuid } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [store, setStore] = useState(null);
  const [hasError, setError] = useState(0);

  useEffect(() => {
    onLoad();
    return () => {
      stateStore.resetFilesUploadProgress();
    }
  }, []);

  const onLoad = async () => {
    try {
      const {
        logo,
        projectData,
      } = await checkProjectAccessQuery(projectUuid);
      
      if(searchParams.get("new"))
        setSearchParams(undefined, {replace: true});
      
      setStore(new ProjectEditorStore({
        logo,
        projectData,
        projectType: PROJECT_TYPE.MINI
      }));
    } catch(e) {
      if(e.status === 403)
        navigate(home, { replace: true });
      else
        setError(2);
    }
  };

  if (hasError) return <ProjectLoadingAlert structureError={hasError === 1} />;

  return !store ? (
    <PageCircularProgress />
  ) : (
    <ProjectEditorStoreProvider value={store}>
      <PdfEditorView />
    </ProjectEditorStoreProvider>
  );
};
