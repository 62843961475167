import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  EditorCommentsStore,
  PresetStore,
  ProjectEditorStore,
  EditorCommentsStoreProvider,
  PresetStoreProvider,
  ProjectEditorStoreProvider,
} from "@stores";
import { checkProjectAccessQuery, checkProjectVersionQuery, sendBrokenStructureFeedbackFileQuery } from "@query";
import { COMPANY_SETTINGS, EditorSocketProvider, PROJECT_COMPANY_SETTINGS, USER_COMPANY_SETTINGS } from "@client";
import { useStores } from "@hooks";
import { PROJECT_TYPE } from "@utils";
import { PageCircularProgress, PageCircularProgressWithLabel } from "@components";
import { home } from "@paths";
import { NewEditorVersionAlert, ProjectLoadingAlert } from "@dialogs";
import { ProjectEditorHandlers } from "./ProjectEditorHandlers";
import { ProjectEditorInactivityMonitor } from "./ProjectEditorInactivityMonitor";

export const ProjectEditor = () => {
  const {t} = useTranslation();
  const { userStore, stateStore } = useStores();
  const navigate = useNavigate();

  const { projectUuid } = useParams();

  const [store, setStore] = useState(null);
  const [projectHash, setProjectHash] = useState(null);
  const [presetStore, setPresetStore] = useState(null);
  const [hasError, setError] = useState(0);
  const [hasNewEditorVersion, setHasNewEditorVersion] = useState(false);
  
  useEffect(() => {
    document.title = `Apropo App | ${t("routes.editor")}`;
  }, [])
  
  const commentStore = useMemo(() => new EditorCommentsStore({
    commenterData: {
      uuid: userStore.data.uuid,
      fullname: userStore.data.fullname,
      color: userStore.data.avatarColor
    }
  }), [userStore.data]);

  useEffect(() => {
    setupEditorStore();
    return () => {
      stateStore.resetFilesUploadProgress();
    }
  }, []);

  const loadProjectData = async () => {
    try {
      await checkProjectVersionQuery();
    } catch(e) {
      console.log("RELOAD:", e.reload);
      if(e.reload)
        setHasNewEditorVersion(true);
      throw e;
    }
    
    try {
      const {
        hash,
        companySettings,
        projectData,
        projectPreset,
        teamMembers,
        workTypes,
      } = await checkProjectAccessQuery(projectUuid, [
        USER_COMPANY_SETTINGS.AVERAGE_ALERT(userStore.userUuid),
        USER_COMPANY_SETTINGS.RISK_ALERT(userStore.userUuid),
        PROJECT_COMPANY_SETTINGS.VALUE_ALERT(projectUuid),
        PROJECT_COMPANY_SETTINGS.VALUE_PROJECT(projectUuid),
        COMPANY_SETTINGS.RATES,
        COMPANY_SETTINGS.PRESET,
      ]);
      
      return {
        hash,
        companySettings,
        workTypes,
        projectData,
        teamMembers,
        projectPreset,
      }
    } catch(e) {
      if(e.status === 403)
        navigate(home, { replace: true });
      else throw e;
    }
  };
  
  const reloadProjectData = async () => {
    const { hash, projectData, projectPreset, teamMembers, companySettings } = await loadProjectData();
    setProjectHash(hash);
    if(projectPreset)
      presetStore.setProjectPreset(projectPreset, false);
    store.setTeamMembers(teamMembers);
    store.setCompanySettings(companySettings);
    store.setProjectData(projectData, false, false);
    return { projectData, projectPreset, teamMembers };
  };
  
  const setupEditorStore = async () => {
    try {
      const { hash, projectPreset, ...data } = await loadProjectData();
      
      setProjectHash(hash);
      setPresetStore(new PresetStore(projectPreset, data.companySettings.defaultPresetId));
      setStore(new ProjectEditorStore({
        useHistoryManager: true,
        projectType: PROJECT_TYPE.STANDARD,
        ...data
      }));
    } catch (e) {
      // @todo: check if needed
      // send structure to s3 and add feedback
      // if (e.json) {
      //   if (process.env.REACT_APP_NODE_ENV === "production")
      //     await sendBrokenStructureFeedbackFileQuery(e.json);
      //   setError(1);
      //   return;
      // }

      setError(2);
    }
  };
  
  if (hasError)
    return <ProjectLoadingAlert structureError={hasError === 1} />;

  return <>
    {
      !store ? (
        <PageCircularProgressWithLabel value={0} />
      ) : (
        <ProjectEditorStoreProvider value={store}>
          <PresetStoreProvider value={presetStore}>
            <EditorCommentsStoreProvider value={commentStore}>
              <EditorSocketProvider reloadProjectData={reloadProjectData}>
                <ProjectEditorHandlers projectHash={projectHash} />
                <ProjectEditorInactivityMonitor reloadProjectData={reloadProjectData} />
              </EditorSocketProvider>
            </EditorCommentsStoreProvider>
          </PresetStoreProvider>
        </ProjectEditorStoreProvider>
      )
    }
    {
      hasNewEditorVersion &&
      <NewEditorVersionAlert />
    }
  </>
};
