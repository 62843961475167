import { useState } from "react";
import { bool, string } from "prop-types";
import { useActiveProjectStore, useActiveProjectsWebsocket } from "@hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TextField } from "@components";
import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import useStyle from "./NameEditor.style";
import { Clear } from "@material-ui/icons";
import { changeProjectNameQuery } from "@query/project/editor/changeProjectNameQuery";

export const NameEditor = observer(({
  uuid,
  name,
  allowEdition=true
}) => {
  const { t } = useTranslation();
  const socket = useActiveProjectsWebsocket();
  const classes = useStyle();
  const activeProjects = useActiveProjectStore();

  const [isEditable, setIsEditable] = useState(false);
  const [value, setValue] = useState("");

  const handleDescriptionFocus = () => {
    if (allowEdition) {
      setValue(name);
      setIsEditable(true);
    }
  };

  const handleEditEnd = (save) => async (e) => {
    e.preventDefault();
    if (save && !value.length) return;
    if (save && name !== value) {
      const newName = value.trim();
      await changeProjectNameQuery(uuid, newName);
      socket?.requestProjectRename(newName, uuid);
      activeProjects.editLocalProjectData(uuid, "name", newName);
    }
    setValue("");
    setIsEditable(false);
  };

  return isEditable ? (
    <form
      onSubmit={handleEditEnd(true)}
      aria-label="project name edition form"
      className={classes.form}
    >
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleEditEnd(true)}>
        <TextField
          value={value}
          error={isEditable && !value.length}
          autoFocus
          name="description"
          placeholder={t("views.active.popup.name")}
          onChange={(e) => setValue(e.target.value?.slice(0, 128))}
          InputProps={{
            className: classes.input,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  tabIndex={-1}
                  onClick={handleEditEnd(false)}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </ClickAwayListener>
    </form>
  ) : (
    <h2 className={classes.text} onClick={handleDescriptionFocus}>
      {name}
    </h2>
  );
});

NameEditor.propTypes = {
  uuid: string,
  name: string.isRequired,
  allowEdition: bool,
};
