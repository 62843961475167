import { forwardRef, useState } from "react";
import { bool, string, any, object, node, func, oneOf, oneOfType } from "prop-types";
import {
  TextField as Field,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  ExpandMore,
} from "@material-ui/icons";
import useStyle from "./TextField.style";
import classnames from "classnames";

export const TextField = forwardRef(({
  label,
  id,
  name,
  className,
  select,
  endAdornment,
  startAdornment,
  type="text",
  InputProps={},
  fullWidth=true,
  ...rest
}, ref) => {
  const classes = useStyle();

  const [showPassword, setShowPassword] = useState(false);

  let inputType = type;
  if (type === "password" && showPassword) inputType = "text";

  return (
    <Field
      ref={ref}
      className={classnames(
        {
          [classes.root]: true,
          [classes.noLabel]: !label,
        },
        className
      )}
      fullWidth={fullWidth}
      label={label}
      name={name || id}
      type={inputType}
      data-private={type === "password" ? "redacted" : undefined}
      select={select}
      variant="outlined"
      SelectProps={select ? { IconComponent: ExpandMore } : undefined}
      InputProps={{
        endAdornment:
          !endAdornment && type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
              >
                {showPassword ? (
                  <VisibilityOffOutlined />
                ) : (
                  <VisibilityOutlined />
                )}
              </IconButton>
            </InputAdornment>
          ) : (
            endAdornment
          ),
        startAdornment,
        ...InputProps,
      }}
      {...rest}
    />
  );
});

TextField.propTypes = {
  label: oneOfType([string, node]),
  id: string,
  error: bool,
  value: any,
  helperText: string,
  className: string,
  name: string,
  onChange: func,
  type: oneOf(["text", "password", "email", "number"]),
  InputProps: object,
  select: bool,
  fullWidth: bool,
  endAdornment: oneOfType([node, string]),
  startAdornment: oneOfType([node, string]),
};
