import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";

export async function storeSetUpQuery(data) {
  return await fetchData(`api/set-up`, APIMethod.POST, {
    baseURL: ACCESS_DOMAINS.btApi,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
}
