import { bool } from "prop-types";
import { observer } from "mobx-react";
import { usePresetFieldNames, useProjectEditorStore, useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { ValueCell, VisibilityButton } from "@components";

export const HoursCell = observer(({
  allowEdition,
  isProposal,
}) => {
  
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { hideSummaryHours } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryHours);
  
  return (
    <ValueCell
      widest 
      isHeader
      size="xSmall"
      className={className}
      style={{ cursor: allowEdition && "grab" }}
    >
      <span className="preset-tableSummaryHeader pt-time">
        {getFieldName("pt-time")}
      </span>
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={32}
          hidden={hideSummaryHours}
          onChange={structure.visibility.setHideSummaryHours}
        />
      }
    </ValueCell>
  )
})

HoursCell.propTypes = {
  isProposal: bool,
  allowEdition: bool,
}