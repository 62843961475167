import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { TotalContainer } from "../components";

export const TotalCompanyCost = observer(() => {
  const structure = useStructureStore();
  
  const {
    settings,
    usesTwoValues,
    displayTotalCompanyCost,
    displayTotalCompanyCostMax
  } = structure;
  const { currencyObj, roundHours } = settings;
  
  const showMaxValue = usesTwoValues && displayTotalCompanyCost !== displayTotalCompanyCostMax;
  
  return (
    <TotalContainer
      totalName="Cost"
      min={displayTotalCompanyCost}
      max={displayTotalCompanyCostMax}
      showMaxValue={showMaxValue}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      textSmaller
      showDecimals={!roundHours}
    />
  );
})