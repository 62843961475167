import { SvgIcon } from "@material-ui/core";

export const Star = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M17.9997 21.9548C17.8067 21.9548 17.6127 21.8988 17.4447 21.7868L11.9997 18.1567L6.55474 21.7868C6.20674 22.0188 5.74974 22.0107 5.40974 21.7628C5.07174 21.5158 4.92374 21.0837 5.03874 20.6807L6.87675 14.2457L2.29275 9.66175C2.00675 9.37675 1.92174 8.94575 2.07574 8.57175C2.22974 8.19875 2.59574 7.95475 2.99974 7.95475H8.38174L11.1057 2.50775C11.4447 1.83075 12.5557 1.83075 12.8947 2.50775L15.6187 7.95475H20.9997C21.4037 7.95475 21.7697 8.19875 21.9237 8.57175C22.0777 8.94575 21.9927 9.37675 21.7067 9.66175L17.1227 14.2457L18.9607 20.6807C19.0757 21.0837 18.9277 21.5158 18.5897 21.7628C18.4137 21.8908 18.2067 21.9548 17.9997 21.9548Z"
      fill="currentColor"
    />
  </SvgIcon>
);
