import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    // height: "max-content",
    minHeight: 24,
    cursor: "text",
    // borderWidth: 2,
    // borderStyle: "solid",
    // borderColor: "transparent",
    "& > div": { height: "100%" },
    "& > div > div": { border: "none" },
    // marginLeft: -6,
    // padding: 4,
    overflow: "auto",
    // minWidth: 200,
    width: "max-content",
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
    lineHeight: 1.42,
    outlineOffset: 2,
    padding: 2,
    "&:hover": {
      outlineWidth: 2,
      outlineStyle: "solid",
      outlineColor: theme.palette.primary.main,
      // borderColor: theme.palette.primary.main,
    },
  },
  focused: {
    width: "100%",
    outlineWidth: 2,
    outlineStyle: "solid",
    outlineColor: theme.palette.primary.main,
    // borderColor: theme.palette.primary.main,
    // padding: "2px 4px",
  },
  minimal: {
    border: "none",
    outline: "none",
    "&:hover": {
      outlineWidth: 2,
      outlineStyle: "solid",
      outlineColor: theme.palette.primary.main,
      // borderColor: theme.palette.primary.main,
    },
  },
  input: {
    padding: 0,
    width: "100%",
    height: "max-content !important",
    letterSpacing: "inherit",
    lineHeight: "1.42 !important",
  },
  inputBaseMultiline: {
    padding: "0 !important"
  },
  inputMultiline: {
    minHeight: "max-content"
  },
  placeholder: {
    color: theme.palette.text.primary + "66",
    fontSize: "inherit",
  },
  inheritFont: {
    fontSize: "inherit",
    color: "inherit",
    fontWeight: "inherit",
    wordBreak: "break-word",
    overflow: "hidden",
  },
  error: {
    borderColor: theme.palette.error.main + " !important",
  },
  linkWrapper: {
    textDecoration: "none !important",
    cursor: "text",
    color: "inherit",
  },
}));

export default useStyle;
