import { createContext } from "react";
import { instanceOf, node } from "prop-types";
import { action, makeObservable, observable } from "mobx";
import {
  createCompanyPreset,
  fetchCompanyPresetsQuery,
  removeProjectPresetQuery,
  setCompanySettingsQuery,
  setProjectPresetQuery,
  updateCompanyPresetQuery,
} from "@query";
import { basicFonts, DEFAULT_FONT_NAME } from "@utils";
import { FontManager } from "@samuelmeuli/font-manager";
import defaultPreset from "@styles/presets/default";
import { COMPANY_SETTINGS } from "@client";

export class PresetStore {
  constructor(projectPreset, defaultPresetId, useFontManager) {
    makeObservable(this);

    if (projectPreset) this.setProjectPreset(projectPreset, useFontManager);
    if (defaultPresetId) this.defaultPresetId = defaultPresetId;
  }

  @action cleanup = () => {
    this.setSelectedPreset();
    this.selectedPresetData = { ...defaultPreset };
    this.selectedPresetId = -1;
    this.selectedPresetFont = undefined;
  };
  
  @observable defaultPresetId = -1;
  @observable presets = [];
  @observable fonts = [];
  @observable presetChanged = false;
  @observable selectedPresetData = { ...defaultPreset };
  @observable selectedPresetId = -1;
  @observable selectedPresetName = "";
  @observable selectedPresetFont;
  @observable defaultPresetFontObj;
  
  @action setFonts(fonts) {
    this.fonts = fonts;
  }
  
  @action addFont(font) {
    this.fonts = [...this.fonts, font];
  }

  @action setPresetChangedFlag(v) {
    this.presetChanged = v;
  }
  @action setDefaultPresetFontObj(defaultPresetFontObj) {
    this.defaultPresetFontObj = defaultPresetFontObj;
  }

  @action async getPresets(defaultName) {
    const res = await fetchCompanyPresetsQuery();

    this.presets = [
      {
        id: -1,
        name: defaultName,
        data: { ...defaultPreset },
      },
      ...res.map((preset) => {
        preset.data = JSON.parse(preset.params);
        delete preset.params;
        return preset;
      }),
    ];
  }

  @action setProjectPreset({ id, name, params, font }, useFontManager) {
    if (!id || !params) return { ...defaultPreset };

    const d = JSON.parse(params);
    this.selectedPresetData = this.updateOldPreset(d, font);
    this.selectedPresetId = id;
    this.selectedPresetName = name;
    this.selectedPresetFont = font;

    if (
      !this.selectedPresetFont &&
      useFontManager &&
      d.fontFamily &&
      d.fontFamily !== DEFAULT_FONT_NAME &&
      !basicFonts.includes(d.fontFamily)
    ) {
      const fontManager = new FontManager(
        process.env.REACT_APP_FONTS_API_KEY,
        d.fontFamily,
        { families: [d.fontFamily, DEFAULT_FONT_NAME] },
        (e, n) => {
          if (!n) return;
          fontManager.setActiveFont(n.family);
        }
      );
      fontManager.init();
      this.selectedPresetFont = { name: d.fontFamily };
    }
  }

  @action updateOldPreset(data = {}, font, presetId) {
    const fontColor = {
      ...defaultPreset.fontColor,
      ...(data?.fontColor || {}),
    };
    /** @note: backwards compatibility when both headers had single color: **/
    if (data?.fontColor?.header) {
      fontColor.breakdownHeader = data.fontColor.header;
      fontColor.summaryHeader = data.fontColor.header;
      fontColor.timelineHeader = data.fontColor.header;
      delete fontColor.header;
      this.presetChanged = true;
    }
    
    if (data?.fontColor?.summaryTableHeader) {
      fontColor.summaryHeader = data.fontColor.summaryTableHeader;
      delete fontColor.summaryTableHeader;
      this.presetChanged = true;
    }
    if (!data?.rowTimelineColor) {
      fontColor.timelineText = data.fontColor?.text;
      fontColor.timelineTitleText = data.fontColor?.titleText;
      fontColor.timelineHeader = data.fontColor?.breakdownHeader;
      data.rowTimelineColor = data.rowColor;
      data.rowActiveTimelineColor = data.activeColor1;
      data.rowTimelineBorderColor = data.rowBorderColor;
      this.presetChanged = true;
    }
    
    if (data?.hoverColor1) {
      data.activeColor1 = data.hoverColor1;
      data.activeColor2 = data.hoverColor2;
      data.activeColor3 = data.hoverColor3;
      this.presetChanged = true;
    }
    
    if (data?.text && !data.titleText) {
      data.titleText = data.text;
      data.summaryText = data.text;
      data.summaryTitleText = data.text;
      this.presetChanged = true;
    }
    
    if(data?.fontColor && !data?.fontColor?.sectionRow) {
      fontColor.sectionRow = data.fontColor?.section;
      fontColor.moreDesc = data.fontColor?.section;
      fontColor.moreDescSection = data.fontColor?.section;
      fontColor.titleTextSection = data.fontColor?.section;
      this.presetChanged = true;
    }
    
    if(data?.fontColor && !data?.fontColor?.totalCost) {
      fontColor.totalTime = data.fontColor?.total;
      fontColor.totalHours = data.fontColor?.total;
      fontColor.totalCost = data.fontColor?.total;
      fontColor.totalTimeTitle = data.fontColor?.summaryHeader;
      fontColor.totalHoursTitle = data.fontColor?.summaryHeader;
      fontColor.totalCostTitle = data.fontColor?.summaryHeader;
      this.presetChanged = true;
    }
    
    if(!data?.paperTimelineColor) {
      data.paperTimelineColor = data.paperColor;
      this.presetChanged = true;
    }
    
    if(fontColor?.tableHeader) {
      fontColor.tableBreakdownHeader = fontColor.tableHeader;
      fontColor.tableTimelineHeader = fontColor.tableHeader;
      fontColor.tableTimelineBilling = fontColor.tableHeader;
      fontColor.tableSummaryHeader = fontColor.tableHeader;
      delete fontColor.tableHeader;
      this.presetChanged = true;
    }
    
    if(data?.tableExpandButton) {
      data.tableBreakdownExpandButton = data.tableExpandButton;
      data.tableSummaryExpandButton = data.tableExpandAltButton;
      data.tableTimelineExpandButton = data.tableExpandAltButton;
      fontColor.tableBreakdownExpandButton = fontColor.tableExpandButton;
      fontColor.tableSummaryExpandButton = fontColor.tableExpandAltButton;
      fontColor.tableTimelineExpandButton = fontColor.tableExpandAltButton;
      delete data.tableExpandButton;
      delete data.tableExpandAltButton;
      delete fontColor.tableExpandButton;
      delete fontColor.tableExpandAltButton;
      this.presetChanged = true;
    }

    if (presetId) {
      const toUpdate = this.presets.find((preset) => preset.id === presetId);
      if (toUpdate) toUpdate.data = { ...defaultPreset, ...data, fontColor };
    }
    
    return data;
  }

  @action async setSelectedPreset(id = -1, name = "", data = {}, font) {
    if (this.presetChanged) {
      await this.updatePreset();
      this.presetChanged = false;
    }
    this.selectedPresetData = this.updateOldPreset(data, font, id);
    this.selectedPresetId = id;
    this.selectedPresetName = name;
    this.selectedPresetFont = id === -1 && this.defaultPresetFontObj ? { ...this.defaultPresetFontObj } : font;
  }
  
  @action setSelectedPresetFont(fontObj, noPresetChangeFlag=false) {
    this.selectedPresetFont = fontObj;
    if(this.selectedPresetId !== -1 || !noPresetChangeFlag)
      this.presetChanged = true;
  }

  @action changePresetData(key, val) {
    this.selectedPresetData[key] = val;
    this.presetChanged = true;
    if (this.selectedPresetId !== -1) {
      const pr = this.presets.find(
        (preset) => preset.id === this.selectedPresetId
      );
      if (pr) pr.data[key] = val;
    }
  }

  @action changePresetFontColor(key, val) {
    this.selectedPresetData.fontColor[key] = val;
    this.presetChanged = true;
    if (this.selectedPresetId !== -1) {
      const pr = this.presets.find(
        (preset) => preset.id === this.selectedPresetId
      );
      if (pr) pr.data.fontColor[key] = val;
    }
  }
 
  @action changePresetText(key, val) {
    this.selectedPresetData.texts[key] = val;
    this.presetChanged = true;
    if (this.selectedPresetId !== -1) {
      const pr = this.presets.find(
        (preset) => preset.id === this.selectedPresetId
      );
      if (pr) {
        if (!pr.data.texts) pr.data.texts = {};
        pr.data.texts[key] = val;
      }
    }
  }
  
  @action removeLogo() {
    delete this.selectedPresetData.logo;
    if (this.selectedPresetId !== -1) {
      const pr = this.presets.find(
        (preset) => preset.id === this.selectedPresetId
      );
      if (pr) delete pr.data.logo;
    }
  }
  @action setLogo(logo) {
    this.selectedPresetData.logo = logo;
    if (this.selectedPresetId !== -1) {
      const pr = this.presets.find(
        (preset) => preset.id === this.selectedPresetId
      );
      if (pr) pr.data.logo = logo;
    }
  }
  
  @action async setDefaultPresetId(defaultPresetId) {
    this.defaultPresetId = defaultPresetId;
    await setCompanySettingsQuery(COMPANY_SETTINGS.PRESET, defaultPresetId);
  }

  @action resetDefaultPreset() {
    this.selectedPresetData = { ...defaultPreset };
    this.selectedPresetFont = this.defaultPresetFontObj ? {...this.defaultPresetFontObj} : undefined;
    this.presetChanged = false;
  }

  @action async removePreset(id) {
    this.presets = this.presets.filter(p => p.id !== id);
  }

  @action async addPresetToProject(projectUuid) {
    if (this.selectedPresetId === -1) {
      try {
        await removeProjectPresetQuery(projectUuid); // @note: api should not treat it as an error?
      } catch(e) {
        console.warn("Preset already removed");
      }
      return;
    }

    await setProjectPresetQuery(projectUuid, this.selectedPresetId);
  }

  @action async createPreset(name) {
    const res = await createCompanyPreset(name, this.selectedPresetData, this.selectedPresetFont?.id);
    if (res) {
      this.selectedPresetId = res.id;
      this.selectedPresetName = name;
      this.presetChanged = false;
      this.presets.push({
        ...res,
        data: { ...this.selectedPresetData },
      });
      return res;
    }
  }

  @action async updatePreset() {
    this.presetChanged = false;
    if (this.selectedPresetId !== -1)
      await updateCompanyPresetQuery(
        this.selectedPresetId,
        this.selectedPresetName,
        this.selectedPresetData,
        this.selectedPresetFont?.id
      );
  }
}

export const PresetStoreContext = createContext(null);
export const PresetStoreProvider = ({ children, value }) => (
  <PresetStoreContext.Provider value={value}>
    {children}
  </PresetStoreContext.Provider>
);

PresetStoreProvider.propTypes = {
  children: node.isRequired,
  value: instanceOf(PresetStore).isRequired,
};