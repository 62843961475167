import { observable, action, makeObservable } from "mobx";

export class MessageStore {
  constructor() {
    makeObservable(this);
  }
  // =============== PROPERTIES ===============

  @observable newMessages = [];
  //@todo: possibly handle alerts ?

  // ================ ACTIONS =================
  
  @action addMessage(message) {
    this.newMessages = [
      ...this.newMessages,
      message
    ]
  }

  @action setNewMessages(messages=[]) {
    
    if (!messages?.length || messages?.length === this.newMessages.length)
      return;
    
    const results = messages.map((x) => x.id);
    const current = this.newMessages.map((x) => x.id);
    const diff = current
      .filter((x) => !results.includes(x))
      .concat(results.filter((x) => !current.includes(x)));
    if (!diff.length) return;
    
    this.newMessages = messages;
  }

  @action readMessage(id) {
    this.newMessages = this.newMessages.filter((x) => x.id !== id);
  }
}
