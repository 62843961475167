import { db } from "@client";

export async function updateUserWorkspace(boardUuid) {
  if(!boardUuid)
    return;
  
  const timestamp = new Date().getTime();
  const users = await db.user.toArray();
  
  if (!users?.[0])
    return;
  
  await db.user.update(users[0].id, {
    ...users[0],
    timestamp,
    board: boardUuid,
  });
}