import { ACCESS_DOMAINS } from "@client";

export function checkBudgetTrackingQuery(
  projectId,
  pathName,
  companyId
) {
  return window.location.replace(
    ACCESS_DOMAINS.btApi +
    "check-budget-tracking?project_id=" +
    projectId +
    "&pathName=" +
    pathName +
    "&company_id=" +
    companyId
  );
}