import { useMemo } from "react";
import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useProjectEditorStore, useStructureStore, useSearchableList, useEditorWebsocket } from "@hooks";
import { simplifyTextString } from "@utils";
import { TextClearInput, Tag, SearchableListContent, SearchableListItem } from "@components";
import { createCustomWorkTypeQuery } from "@query";
import { MAX_WORKTYPE_LENGTH } from "project-structure";
import classnames from "classnames";

export const WorkTypeSelect = observer(({
  workType,
  useExpandCell
}) => {
  const structure = useStructureStore();
  const editorStore = useProjectEditorStore();
  const socket = useEditorWebsocket();

  const { t } = useTranslation();
  const { workTypes } = editorStore;
  const { name, autoFocus, treePath } = workType;

  const handleNewWorkType = async ({ name: newName }) => {
    let newWorkType = workTypes.find(
      ({ name }) => name === newName
    );
    if (!newWorkType) newWorkType = await createCustomWorkTypeQuery(newName);

    if (newWorkType) {
      workType.changeWorkType(
        newWorkType.id,
        newWorkType.name,
        newWorkType.backgroundColor,
        editorStore?.getWorkTypeDefaultRate(newWorkType.id),
      );
      socket?.requestCommentMove(treePath.join("/"), workType.treePath.join("/"));
      socket?.sendNewWorkType(newWorkType);
      editorStore.addCustomWorkType(newWorkType.id, newWorkType.name, newWorkType.backgroundColor);
    }
  };

  const getOptionDisabled = (value) => Boolean(
    structure.workTypes.find(
      ({ name }) => name === value
    )
  )

  const {
    containerProps,
    inputProps,
    looseFocus
  } = useSearchableList({
    defaultValue: name,
    autoFocus,
    onAutoFocus: workType.removeAutofocus,
    onChange: handleNewWorkType,
    getOptionDisabled,
  });

  const selectExistingWorkType = (newId, name, color) => async () => {
    looseFocus();
    workType.changeWorkType(
      newId,
      name,
      color,
      editorStore?.getWorkTypeDefaultRate(newId)
    );
    socket?.requestCommentMove(treePath.join("/"), workType.treePath.join("/"));
    editorStore?.addWorkTypeWithStatus(workType.id);
  };

  const availableWorkTypes = useMemo(
    () =>
      name === inputProps.value
        ? workTypes
        : workTypes.filter(
            ({ name }) =>
              simplifyTextString(name).indexOf(
                simplifyTextString(inputProps.value)
              ) >= 0
          ),
    [workTypes, inputProps.value]
  );

  return (
    <>
      <TextClearInput
        {...inputProps}
        acceptOnClickAway
        confirmOnEnter
        placeholder={
          inputProps.focused
            ? t("views.editor.dialogs.work_types.focused")
            : t("common.work_type")
        }
        containerClass="name"
        className={classnames("w-max -m-0-5", !useExpandCell && "ml-2")}
        maxLength={MAX_WORKTYPE_LENGTH}
        preventClickAwayOnElement="#workTypeSelector"
        inputClassName="input-name"
      />
      <SearchableListContent {...containerProps} id="workTypeSelector">
        {availableWorkTypes.map(({ id, name, backgroundColor }) => {
          const isUsed = structure.hasWorkType(id);

          return (
            <SearchableListItem
              key={`wT_${id}`}
              disabled={isUsed}
              onClick={selectExistingWorkType(id, name, backgroundColor)}
            >
              <Tag text={name} color={backgroundColor} />
            </SearchableListItem>
          );
        })}
      </SearchableListContent>
    </>
  );
});

WorkTypeSelect.propTypes = {
  workType: object.isRequired,
  useExpandCell: bool.isRequired,
};
