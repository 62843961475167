import { SvgIcon } from "@material-ui/core";

export const File = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M15.414 2H6C4.897 2 4 2.898 4 4V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V6.586L15.414 2ZM18.001 20H6V4H14V8H18L18.001 20Z"
      fill="currentColor"
    />
  </SvgIcon>
);
