import { useEffect, useMemo, useState } from "react";
import { number } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { getCounterQuery } from "@query";
import { SUCCESS_UPLOAD, ERROR_UPLOAD } from "@utils";
import { Grid, LinearProgress } from "@material-ui/core";

export const UploadSnackBar = observer(({
  jiraExportId,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
  const [ counter, setCounter ] = useState(0);
  const [ allIssues, setAllIssues ] = useState(0);
  
  const fetchData = async () => {
    try {
      const response = await getCounterQuery(jiraExportId);
      setCounter(response.percentExported);
      setAllIssues(response.allIssues);
      if (response.percentExported === 100) {
        closeSnackbar(jiraExportId);
        enqueueSnackbar(t("views.bt.uploaded_successfully"), {
          variant: "success",
          key: SUCCESS_UPLOAD.BT_PROJECT,
          preventDuplicate: true,
        });
      }
    } catch {
      closeSnackbar(jiraExportId);
      enqueueSnackbar(t("views.bt.something_went_wrong"), {
        variant: "error",
        key: ERROR_UPLOAD.BT_PROJECT,
        preventDuplicate: true,
      });
    }
  };
  
  const fetchDataInterval = useMemo(() => (
    setInterval(fetchData, 5000)
  ), []);
  
  useEffect(() => {
    fetchData();
    return () => clearInterval(fetchDataInterval);
  }, []);

  return (
    <Grid container justifyContent="space-between" className="mx-3">
      <p className="mb-2" >
        {t("views.bt.upload_progress", { allIssues: allIssues, exported: counter })}
      </p>
      <LinearProgress value={counter} variant="determinate" color="secondary" />
    </Grid>
  );
});

UploadSnackBar.propTypes = {
  jiraExportId: number.isRequired,
};
