export const EditorCommands = {
  CONNECTION: "connect",
  RECONNECTION: "reconnect",
  DISCONNECT: "disconnect",
  CONNECT_ERROR: "connection_error",
  
  JOIN_COMPANY: "join_company",
  JOIN_WORKSPACE: "join_workspace",
  JOIN_WORKSPACE_PROJECTS: "join_workspace_projects",
  LEAVE_WORKSPACE_PROJECTS: "join_workspace_projects",
  JOIN_PROJECT: "join_project",
  CLOSE_PROJECT: "close_project",
  JOIN_PROPOSAL: "join_proposal",
  USER_JOINED: "user_joined",
  USER_LEFT: "user_left",
  
  USER_MOVE: "user_move",
  PATCH: "patch",
  
  PERMIT_ADD: "permit_add",
  PERMIT_REMOVE: "permit_remove",
  PERMIT_STATUS: "permit_update",
  PERMIT_USER_VISIBILITY: "user_permit_update",
  
  COVER_ADD: "cover_add",
  COVER_REMOVE: "cover_remove",
  COVER_PAGES: "cover_update_pages",
  COVER_SCALE: "cover_update_scale",
  
  COMMENT_ADD: "comment_add",
  COMMENT_EDIT: "comment_update",
  COMMENT_REMOVE: "comment_remove",
  COMMENT_REMOVE_PATH: "comment_path_remove",
  COMMENT_MOVE: "comment_move",
  
  VERSION_REQUEST: "version_get",
  VERSION_NEW: "version_add",
  VERSION_REMOVE: "version_remove",
  VERSION_RENAME: "version_rename",
  VERSION_ORDER: "version_order",
  VERSION_VISIBILITY: "version_visibility",
  VERSION_LOCK: "version_locked",
  VERSION_LIST: "version_list",
  
  PROJECT_PROGRESS: "project_creation_progress",
  PROJECT_RENAME: "project_rename",
  PROJECT_STATUS: "project_status",
  PROJECT_REMOVE: "project_remove",
  PROJECT_ARCHIVE: "project_archive",
  WORKTYPE_ADD: "wt_add",
  
  USER_ADD: "user_add",
  USER_REMOVE: "user_remove",
  USER_PROMOTE: "user_promote",
  
  NOTIFICATION: "notification",
  ALERT: "alert",
  
  LOGOUT: "logout",
}