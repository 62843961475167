import { useMemo, useState } from "react";
import { arrayOf, bool, number, object, shape } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  useProjectEditorStore,
  useStructureStore,
  useVisibilityModeCellClassName,
  useWorkTypePermitChecker
} from "@hooks";
import { Estimate } from "@dialogs";
import { ValueCell, VisibilityButton } from "@components";
import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import { HeaderWorkTypeActions } from "./HeaderWorkTypeActions";
import { Percent } from "@assets";
import classnames from "classnames";

export const HeaderWorkType = observer(({
  workType,
  allowEdition,
  section,
  isLibrary,
  isSellerOrClient,
  visibleWorkTypes,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { usesTwoValues } = structure;
  const { isHidden } = structure;

  const { editionGrant, isPending, hasPermit, userSeesOnlyPermittedWorkTypes } = useWorkTypePermitChecker(
    workType.id
  );
  
  const [estimateModalVisible, openEstimateModal] = useState(false);

  const editable = allowEdition;
  const visible = !structure.isWorkTypeOff(workType.id);
  
  const className = useVisibilityModeCellClassName(isHidden);
  
  const structureWorkType = useMemo(() => (
    structure.getWorkTypeById(workType.id)
  ), [workType.id]);

  return (
    <ValueCell
      key={workType.id}
      isHeader
      size="xSmall"
      wider={usesTwoValues}
      style={{
        cursor: editable && "grab",
        color:
          editable &&
          hasPermit &&
          (isPending ? theme.props.warning : theme.props.success),
      }}
      visible={visible && editionGrant}
      className={className}
    >
      <Tooltip
        title={t(`views.editor.status_${isPending ? "pending" : "done"}`)}
        disableHoverListener={!editable || !hasPermit}
      >
        <span className="preset-tableBreakdownHeader">
          {structureWorkType?.name}
        </span>
      </Tooltip>
      {
        workType.hasPercent && allowEdition &&
        <IconButton
          size="small"
          color="primary"
          className="text-sm absolute"
          onClick={() => openEstimateModal(!estimateModalVisible)}
          style={{ top: -16 }}
        >
          <Percent />
        </IconButton>
      }
      {editable && Boolean(structureWorkType) && (
        <HeaderWorkTypeActions
          workType={structureWorkType}
          sectionWorkType={workType}
          section={section}
          useLibraryManagementStyle={isLibrary}
          isSellerOrClient={isSellerOrClient}
          hasPermit={hasPermit && editionGrant}
          isPending={isPending}
          visibleWorkTypes={visibleWorkTypes}
          userSeesOnlyPermittedWorkTypes={userSeesOnlyPermittedWorkTypes}
          openWorkTypeEstimateDialog={openEstimateModal}
        />
      )}
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={2}
          hidden={workType.isHidden}
          onChange={workType.setHideWorkType}
        />
      }
      {estimateModalVisible && !isLibrary && (
        <Estimate
          open={true}
          workType={structureWorkType}
          section={section}
          sectionWorkType={workType}
          onClose={() => openEstimateModal(false)}
        />
      )}
    </ValueCell>
  );
});

HeaderWorkType.propTypes = {
  workType: object.isRequired,
  section: object.isRequired,
  allowEdition: bool,
  isLibrary: bool,
  isSellerOrClient: bool,
  visibleWorkTypes: arrayOf(shape({
    id: number,
    invisible: bool,
  })),
};
