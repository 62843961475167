import { SvgIcon } from "@material-ui/core";

export const Timer = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33317 2.02236C7.30656 0.703787 9.62664 0 12 0C15.1814 0.00384927 18.2314 1.26937 20.481 3.51897C22.7306 5.76857 23.9962 8.81858 24 12C24 14.3734 23.2962 16.6934 21.9776 18.6668C20.6591 20.6402 18.7849 22.1783 16.5922 23.0865C14.3995 23.9948 11.9867 24.2324 9.65893 23.7694C7.33116 23.3064 5.19296 22.1635 3.51473 20.4853C1.8365 18.807 0.693615 16.6688 0.230593 14.3411C-0.232429 12.0133 0.0052103 9.60051 0.913461 7.40779C1.82171 5.21508 3.35978 3.34094 5.33317 2.02236ZM6.30289 20.5263C7.98924 21.6531 9.97185 22.2545 12 22.2545C14.7187 22.2515 17.3252 21.1701 19.2477 19.2476C21.1701 17.3252 22.2515 14.7187 22.2545 12C22.2545 9.97184 21.6531 7.98923 20.5263 6.30288C19.3996 4.61653 17.798 3.30218 15.9243 2.52603C14.0505 1.74989 11.9886 1.54682 9.99945 1.94249C8.01026 2.33817 6.18308 3.31482 4.74896 4.74894C3.31483 6.18306 2.33818 8.01025 1.94251 9.99943C1.54683 11.9886 1.74991 14.0505 2.52605 15.9242C3.30219 17.798 4.61654 19.3995 6.30289 20.5263ZM11.1273 4.27344H12.8727V11.5752L17.1927 14.9672L16.1164 16.3403L11.1273 12.4247V4.27344Z"
      fill="currentColor"
    />
  </SvgIcon>
);
