import { ValueCell } from "@components";
import { observer } from "mobx-react";
import { useStructureStore, useVisibilityModeCellClassName } from "@hooks";

export const TeamMembersCell = observer(() => {
  const structure = useStructureStore();
  const { hideSummaryTeam } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryTeam);
  
  return <ValueCell wider className={className}/>;
})