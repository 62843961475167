import { observer } from "mobx-react";
import { useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { ValueCell } from "@components";

export const HourCell = observer(() => {
  const structure = useStructureStore();
  const { hideSummaryHours } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryHours);
  
  return <ValueCell widest className={className} />;
})