import { bool, number, object, func } from "prop-types";
import classnames from "classnames";
import { Columns } from "project-structure";

export const ExportWorkType = ({
  translate,
  element,
  allowShowingPrices,
  visibility,
  settings,
  depth = 1,
}) => {
  const {
    name,
    inBreakdown,
    displayHours,
    displayHoursMax,
    displayPrice,
    displayPriceMax,
    displayTime,
    displayTimeMax,
    displayRate,
    showMaxTime,
    showMaxHours,
    showMaxPrice,
    hasOnlyCost,
    teamMembers,
    totalTeamMembers,
    isOpened,
    hasResources,
    resources,
    turnOff,
  } = element;
  
  const {
    currencyObj,
    hasTeamMembersCol,
    visibleSummaryColumns,
  } = settings;
  
  const {
    apply,
    hideSummaryTime,
    hideSummaryTeam,
    hideSummaryHours,
    hideSummaryRate,
    hideSummaryCost,
    hideMinValues,
  } = visibility;
  
  const opened = hasTeamMembersCol && hasResources && isOpened;

  return (
    <>
      <tr className={classnames("system", opened && "hover")}>
        <td
          className={classnames("summary-name", turnOff && "off")}
          style={{ textAlign: "left", paddingLeft: 16 * depth }}
        >
          {name || translate("common.unnamed")}
        </td>
        {visibleSummaryColumns.map(({ name }) => {
          switch (name) {
            case Columns.TEAM:
              if(apply && hideSummaryTeam) return <></>
              return (
                <td
                  key={name}
                  className={classnames("summary", turnOff && "off")}
                >
                  {!hasOnlyCost && (totalTeamMembers || teamMembers || 1)}
                </td>
              );
            case Columns.TIME:
              if(apply && hideSummaryTime) return <></>
              return (
                <td
                  key={name}
                  className={classnames("summary", turnOff && "off")}
                >
                  { (!apply || !hideMinValues) && !hasOnlyCost && displayTime }
                  { showMaxTime && "  - " }
                  { !hasOnlyCost && showMaxTime && displayTimeMax }
                </td>
              );
            case Columns.RATE:
              if(apply && hideSummaryRate) return <></>
              return (
                allowShowingPrices && (
                  <td
                    key={name}
                    className={classnames("summary", turnOff && "off")}
                  >
                    {!(!inBreakdown && hasOnlyCost) && (
                      <>
                        { currencyObj.symbolStart || "" }
                        { displayRate }
                        { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
                      </>
                    )}
                  </td>
                )
              );
            case Columns.HOURS:
              if(apply && hideSummaryHours) return <></>
              return (
                <td
                  key={name}
                  className={classnames("summary", turnOff && "off")}
                >
                  { (!apply || !hideMinValues) && (inBreakdown || !hasOnlyCost) && displayHours }
                  { !hasOnlyCost && showMaxHours && "  -  "}
                  { !hasOnlyCost && showMaxHours && displayHoursMax }
                </td>
              );
            case Columns.COST:
              if(apply && hideSummaryCost) return <></>
              return (
                allowShowingPrices && (
                  <td key={name} /*className="cell-price"*/>
                    <p className={classnames("price", turnOff && "off")}>
                      {
                        (!apply || !hideMinValues) &&
                        <>
                          { currencyObj.symbolStart || "" }
                          { displayPrice }
                          { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
                        </>
                      }
                      { showMaxPrice && "  -  " }
                      { showMaxPrice && (
                        <>
                          { currencyObj.symbolStart || "" }
                          { displayPriceMax }
                          { currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : "" }
                        </>
                      )}
                    </p>
                  </td>
                )
              );
          }
        })}
      </tr>
      {isOpened &&
        hasResources &&
        resources.map((resource) => (
          <ExportWorkType
            translate={translate}
            key={resource.id}
            element={resource}
            currencyObj={currencyObj}
            allowShowingPrices={allowShowingPrices}
            depth={depth + 1}
            visibility={visibility}
            settings={settings}
          />
        ))}
    </>
  );
};

ExportWorkType.propTypes = {
  translate: func.isRequired,
  element: object.isRequired,
  settings: object.isRequired,
  allowShowingPrices: bool,
  depth: number,
  visibility: object,
};
