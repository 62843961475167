import { memo } from "react";
import { bool, node, oneOf, oneOfType, string } from "prop-types";
import { parsePrice } from "project-structure";
import { InputCellProps } from "../types";
import Grid from "@material-ui/core/Grid";
import { ValueCellReadOnlyContent } from "../components/ValueCellReadOnlyContent";
import { ValueCellEditableContent } from "../components/ValueCellEditableContent";
import useStyle from "./PriceCell.style";
import useCellStyle from "../commonStyles.style";
import classnames from "classnames";
import { capitalize } from "@material-ui/core";
import { MAX_VALUES } from "@utils";

export const PriceCell = memo(({
  onValueChange,
  displayContent,
  displayMax,
  editable,
  wider,
  widest,
  narrow,
  children,
  mini,
  noContainerBackground,
  highlightCellText,
  className,
  isHeader,
  valueCellWidth,
  usePresetClass = true,
  useSectionClass = true,
  style,
  allowNegative,
  size="small",
  ...valueProps
}) => {
  const classes = useStyle();
  const cellClasses = useCellStyle();
  
  return (
    <Grid
      item
      container
      role="rowitem"
      className={classnames(
        "cell",
        cellClasses.cell,
        classes.priceCell,
        cellClasses.bolder,
        isHeader && cellClasses.headCell,
        !isHeader  && classes.priceCellBody,
        cellClasses[`size${capitalize(size)}`],
        mini && classes.cellMini,
        narrow && classes.cellSmall,
        wider && classes.cellMedium,
        widest && classes.cellLarge,
        valueCellWidth && classes.valueCellWidth,
        highlightCellText && "highlightCellText",
        className
      )}
      style={style}
    >
      <Grid
        item container
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        className={classnames(isHeader && "h-full", !isHeader && cellClasses.cellInner)}
      >
        {displayContent && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            className={classnames(
              classes.priceContainer,
              !noContainerBackground &&
               classes.priceContainerBackground,
              usePresetClass &&
                (isHeader ? "preset-tableHeader" : "preset-price"),
              useSectionClass && "sectionTitle"
            )}
          >
            {editable ? (
              <ValueCellEditableContent
                {...valueProps}
                displayMax={displayMax}
                onValueChange={onValueChange}
                allowNegative={allowNegative}
                max={MAX_VALUES.PRICE}
                min={allowNegative ? -MAX_VALUES.PRICE : 0}
                isSmall
                fullWidth
              />
            ) : (
              <ValueCellReadOnlyContent
                {...valueProps}
                displayMax={displayMax}
                allowNegative={allowNegative}
                parser={parsePrice}
              />
            )}
          </Grid>
        )}
        {children}
      </Grid>
    </Grid>
  );
});

PriceCell.propTypes = {
  ...InputCellProps,
  widest: bool,
  noContainerBackground: bool,
  usePresetClass: bool,
  valueCellWidth: bool,
  children: oneOfType([node, string]),
  // common
  emptyInputs: bool,
  highlightCellText: bool,
  showDecimals: bool,
  className: string,
  isHeader: bool,
  size: oneOf(["xSmall", "small", "medium", "large", "xLarge"]),
  inputClassName: string,
};
