import { APIMethod, fetchData } from "@client";

export async function saveProjectCoverQuery(
  projectUuid,
  userUuid,
  coverId,
  coverData
) {
  await fetchData(
    `/v1/ws/project/${projectUuid}/${coverId}`,
    APIMethod.PUT,
    {
      data: { ...coverData, user: userUuid},
      useWssToken: true,
      mapRequestKeysToSnakeCase: false
    }
  );
}