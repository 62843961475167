import { bool, func, string } from "prop-types";
import { Alert } from "@components";
import { removeProjectCoverQuery } from "@query";
import { useTranslation } from "react-i18next";
import { useEditorWebsocket, useProjectEditorStore } from "@hooks";

export const DocumentRemoval = ({
  visible,
  onClose,
  projectUuid
}) => {
  
  const { t } = useTranslation();
  const editorStore = useProjectEditorStore();
  const socket = useEditorWebsocket();
  
  const removeAndCloseDialog = async () => {
    if(!editorStore.pdfDocument) return;
    await removeProjectCoverQuery(projectUuid, editorStore.pdfDocument.id);
    socket?.removeCover()
    editorStore.removeDocument();
    onClose();
  };
  
  return (
    <Alert
      title={t("alerts.pdf.doc_remove.title_all")}
      isOpen={visible}
      onAccept={removeAndCloseDialog}
      onCancel={onClose}
    >
      {t("alerts.pdf.doc_remove.content_all", {
        docName: editorStore.pdfDocument?.file?.name,
      })}
    </Alert>
  );
};

DocumentRemoval.propTypes = {
  visible: bool.isRequired,
  onClose: func.isRequired,
  projectUuid: string.isRequired
};