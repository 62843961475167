import { createContext } from "react";
import { node } from "prop-types";
import { action, computed, makeObservable, observable } from "mobx";
import { fetchProjectBudgetTrackingQuery, getUsersQuery } from "@query";
import { CELL_WIDTHS } from "@utils";
import { MAX_PAGE_WIDTH } from "@styles/themes";

export class BudgetTrackingStore {
  constructor() {
    makeObservable(this);
  }

  @observable jiraProject = true;
  @observable cost = false;
  @observable hours = false;

  @action async initProject(uuid) {
    return await fetchProjectBudgetTrackingQuery(uuid);
  }

  @action async getUsers() {
    return await getUsersQuery();
  }

  @action async getWorkTypeTable() {
    return this.workTypeTable;
  }

  @action setCost(cost) {
    this.cost = cost;
  }

  @action setHour(hour) {
    this.hours = hour;
  }

  @computed get calculateMaxWithcellSummary() {
    return (
      MAX_PAGE_WIDTH -
      (CELL_WIDTHS.VALUE.LARGE +
        (this.hours ? CELL_WIDTHS.VALUE.TINY * 4 : CELL_WIDTHS.VALUE.LARGE) +
        (this.cost ? CELL_WIDTHS.VALUE.TINY * 4 : CELL_WIDTHS.VALUE.LARGE))
    );
  }
}

export const BudgetTrackingStoreContext = createContext(null);
export const BudgetTrackingStoreProvider = ({ children }) => (
  <BudgetTrackingStoreContext.Provider value={new BudgetTrackingStore()}>
    {children}
  </BudgetTrackingStoreContext.Provider>
);

BudgetTrackingStoreProvider.propTypes = {
  children: node.isRequired,
};
