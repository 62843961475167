import { useEffect, useMemo, useState } from "react";
import { string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DEFAULT_VERSION_INFO } from "project-structure";
import { useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { fetchProposalCommentsQuery } from "@query";
import {
  useFonts,
  useProjectEditorStore,
  useEditorWebsocket,
  useCheckProjectAccess,
  useEditorCommentStore,
  useStores,
} from "@hooks";
import { onPatch } from "mobx-state-tree";
import { ProjectEditorView } from "@views/Projects/ProjectEditor/ProjectEditorView";
import { getStructureMetadata } from "@utils";
import { PageCircularProgressWithLabel } from "@components";

export const ProjectEditorHandlers = observer(({
  projectHash
}) => {
  const { t } = useTranslation();
  const { userStore, stateStore } = useStores();
  const editorStore = useProjectEditorStore();
  const { projectUuid } = useParams();
  const socket = useEditorWebsocket();
  const commentStore = useEditorCommentStore()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ searchParams ] = useSearchParams();
  const { unloadFonts } = useFonts();
  
  const [isLoading, setLoading] = useState(false);
  
  const {
    projectLoaded,
    isArchived,
    currentProjectStructure,
    projectUsers,
    isCreated,
  } = editorStore;
  
  const {
    hasEditorPrivileges,
    hasSellerPrivileges,
    isObserver
  } = useCheckProjectAccess(projectUsers);
  
  const actionListener = useMemo(
    () =>
      currentProjectStructure &&
      onPatch(
        currentProjectStructure,
        ({ path, value }) => {
          if(path.match(/\/historyManager\/history\/\d+/) && value?.patches)
            socket.requestStructurePatch(value.patches, getStructureMetadata(currentProjectStructure))
        }
      ),
    [currentProjectStructure]
  );
  
  useEffect(() => () => {
    actionListener && actionListener();
  }, [currentProjectStructure]);
  
  useEffect(() => {
    if (isArchived)
      enqueueSnackbar(t("alerts.editor.archive_readonly"));
  }, [isArchived]);
  
  const onLoad = async () => {
    setLoading(true);
    
    if(hasEditorPrivileges && commentStore)
      commentStore.setCommentsVisible(hasEditorPrivileges);
    
    socket?.joinProject(
      projectUuid,
      projectHash,
      isCreated,
      isCreated
        ? editorStore.sortedVersions[ 0 ].key
        : DEFAULT_VERSION_INFO.key,
    );
    
    if (searchParams.get("share")) editorStore.forceShareModal(true);
    
    if (!isObserver)
      enqueueSnackbar(t("alerts.editor.readonly_info"), {
        variant: "info",
        autoHideDuration: 6000,
      });
    
    setLoading(false);
  };
  
  useEffect(() => {
    fetchProjectComments();
  }, [hasSellerPrivileges, editorStore.currentVersionKey])
  
  useEffect(() => {
    if (projectLoaded) onLoad();
  }, [projectLoaded]);
  
  useEffect(() => () => {
    closeSnackbar(stateStore.lockedProjectVersionSnackbar);
    stateStore.lockedProjectVersionSnackbar = undefined;
    socket?.closeProject();
    
    return () => {
      unloadFonts();
      stateStore.resetProjectCreatorProgress();
    }
  }, []);
  
  const fetchProjectComments = async () => {
    if(!commentStore || !editorStore.currentVersionKey || !hasEditorPrivileges || commentStore.fetchedVersions.includes(editorStore.currentVersionKey))
      return;
    
    commentStore.setUseInternalComments(true);
    commentStore.setUseExternalComments(hasSellerPrivileges);
    const c = await fetchProposalCommentsQuery(projectUuid, editorStore.currentVersionKey, userStore.userUuid);
    commentStore.setComments(c, editorStore.currentVersionKey);
  }
  
  if (isLoading || !currentProjectStructure)
    return <PageCircularProgressWithLabel
      value={stateStore.projectCreatorProgress}
      error={stateStore.projectCreatorProgressError}
      label={stateStore.projectCreatorProgressErrorMessage || stateStore.projectCreatorProgressStepName}
    />;
  
  return <ProjectEditorView />
});

ProjectEditorHandlers.propTypes = {
  projectHash: string.isRequired,
}
