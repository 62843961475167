import { useEffect, useMemo, useState } from "react";
import { getTemplatesQuery, getCategoriesQuery } from "@query";

export const useTemplates = (onLoad) => {
  const [templates, setTemplates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filtersLoading, setFiltersLoading] = useState(true);

  const [areCompany, setCompanyState] = useState(false);
  const [filters, setFilters] = useState({
    query: "",
    category: "",
    type: "",
  });

  const filteredTypes = useMemo(
    () =>
      filters.category
        ? types.filter((x) => x.parent === filters.category.id)
        : [],
    [filters.category]
  );

  useEffect(() => {
    (async () => {
      const response = await getCategoriesQuery();
      setCategories(response.filter((x) => x.parent === 0));
      setTypes(response.filter((x) => x.parent));
      await getTemplates(true, true);

      if (onLoad) await onLoad();
      setLoading(false);
      setFiltersLoading(false);
    })();
  }, []);

  const clearFilters = () => {
    setFilters({ query: "", category: "", type: "" });
    getTemplates(true);
  };

  const getTemplates = async (
    reset = false,
    resetTemplates = false,
    limit = 11,
    companyTemplates = areCompany,
  ) => {
    if (reset || (!loading && hasMore)) {
      setLoading(true);
      const { paginate, results } = await getTemplatesQuery(
        reset ? 1 : page + 1,
        limit,
        filters,
        companyTemplates
      );
      
      setTemplates(reset ? results : [...templates, ...results]);
      
      if (resetTemplates)
        setFilters({ query: "", category: "", type: "" });
      
      setPage(paginate.page);
      setHasMore(paginate.page < paginate.pages);
      setLoading(false);
    }
  }

  const updateTemplate = (uuid, name, description, category) => {
    const t = [...templates];
    const tpl = t.find((t) => t.id === uuid);
    tpl.name = name;
    tpl.content = description;
    tpl.categoryId = category;
    setTemplates(t);
  };

  const removeTemplate = (uuid) => {
    setTemplates(templates.filter((t) => t.id !== uuid));
  }

  const switchCompanyTemplatesState = async () => {
    setCompanyState(!areCompany);
    await getTemplates(true, true, 11, !areCompany);
  };

  const setFilter = async (key, value) => {
    setFilters({ ...filters, [key]: value });
    getTemplates(true);
  };

  return {
    templates,
    categories,
    types: filteredTypes,

    areCompany,
    filters,
    isLoading: loading,
    filtersLoading,
    setFilter,
    clearFilters,
    getTemplates,
    updateTemplate,
    removeTemplate,
    switchCompanyTemplatesState,
  };
};
