import { string, number, instanceOf, oneOfType } from "prop-types";
import { useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { format } from "date-fns";
import { setNotificationReadQuery } from "@query";
import { HtmlParser } from "@utils";
import { Grid, IconButton } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import useStyle from "./NotificationItem.style";

export const NotificationItem = observer(({
  id,
  content,
  date
}) => {
  const { t } = useTranslation();
  const { messageStore } = useStores();
  const classes = useStyle();
  
  const handleReadMessage = async () => {
    await setNotificationReadQuery(id);
    messageStore.readMessage(id);
  }

  return (
    <Grid
      item
      container
      className={classes.root}
      wrap="nowrap"
      alignItems="center"
    >
      <Grid item container direction="column">
        <Grid item container>
          <p className={classes.message}>{HtmlParser(content)}</p>
        </Grid>
        <p className={classes.date}>
          {format(date, "dd.MM.yyyy ")}
          {t("common.at")}
          {format(date, " HH:mm a")}
        </p>
      </Grid>
      <IconButton
        color="primary"
        onClick={handleReadMessage}
        className="text-xl"
      >
        <Done />
      </IconButton>
    </Grid>
  );
});

NotificationItem.propTypes = {
  id: oneOfType([string, number]).isRequired,
  content: string.isRequired,
  date: instanceOf(window.Date).isRequired,
};
