import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  row: {
    zIndex: 1,
    minWidth: "100%",
    width: "initial",
    position: "relative",
    borderRadius: 5,
    backgroundColor: theme.props.backgroundSecondary,
    "&.d0": {
      "& .cell": { color: theme.props.text.alt },
      backgroundColor: theme.props.sectionColor,
      "& hr": {
        backgroundColor: theme.props.text.alt,
      },
      "& .hoverIcon": {
        color: theme.props.textAlt,
      },
    },
    // "&:not(.d0) .cell": { color: theme.palette.text.secondary },
    // "&:not(.d0) .cell.title": { color: theme.palette.text.primary },
    "& .hoverIcon": {
      "-webkit-transition": "opacity 0.4s ease",
      "-moz-transition": "opacity 0.4s ease",
      transition: "opacity 0.4s ease",
      opacity: 0.6,
    },
    "& .hoverIcon.opaque": {
      opacity: 1,
    },
    "&:not(:hover) .hoverIcon": {
      zIndex: -1,
      opacity: "0 !important",
    },
    // "& > div:not(.wt-container):last-child": {
    //   paddingRight: 12,
    // },
    // "& > div.cell:last-child": {
    //   paddingRight: 16,
    // },
  },
  rowSelected: {
    "&.d1": { backgroundColor: theme.props.backgroundLevel1 },
    "&.d2": { backgroundColor: theme.props.backgroundLevel2 },
    "&.d3": { backgroundColor: theme.props.backgroundSecondary },
  },
  rowOff: {
    "& > .cell > *:not(.opaque):not(.opaque-tContent)": { opacity: 0.3 },
    "& > .cell > *.opaque-tContent": { borderColor: theme.props.border+"4D !important" },
    "& > .cell > *.opaque-tContent .tm-bar": { opacity: 0.3 },
  },
}));

export default useStyle;
