import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Checkbox, ClearInput } from "@components";
import { WorkTypeCheckbox } from "./components/WorkTypeCheckbox";
import { Grid } from "@material-ui/core";
import { useProjectEditorStore } from "@hooks";
import { useMemo, useState } from "react";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Link from "@material-ui/core/Link";

export const SettingContents = ({
  editedWorkTypeName,
  usedWorkTypes,
  onWorkTypeSelect,
  percent,
  onPercentChange,
  availableWorkTypes,
  blacklistedWorkTypes,
  clickDialogMessage,
  clickDialogMessageTitle,
  hideList,
  maxVisible=3,
  onSelectAll,
}) => {
  
  const { t } = useTranslation();
  const editorStore = useProjectEditorStore();
  
  const [expand, setExpand] = useState(false);
  
  const allowHidingList = useMemo(() => (
    Boolean(hideList && availableWorkTypes.length > maxVisible)
  ), [hideList, availableWorkTypes])
  
  // const sortedAvailableWorkTypes = useMemo(() => (
  //   hideList
  //     ? availableWorkTypes.slice().sort((a,b) => {
  //         const includesA = usedWorkTypes.includes(a);
  //         const includesB = usedWorkTypes.includes(b);
  //
  //         if((includesA && includesB) || (!includesA && !includesB))
  //           return 0;
  //
  //         return includesA ? -1 : 1;
  //       })
  //     : availableWorkTypes
  // ), [availableWorkTypes, hideList, usedWorkTypes]);
  
  const handleWorkTypeSelect = (workType) => () => onWorkTypeSelect(workType);
  
  const handleShowMore = () => setExpand(!expand);
  
  return (
    <Grid item container wrap="nowrap" className="mt-2">
      <Grid
        item container
        direction="column"
        className="w-max"
      >
        <p className="mb-2">
          {t("common.percentage")}
        </p>
        <Grid
          item
          container
          alignItems="center"
          className="w-max"
          wrap="nowrap"
        >
          <ClearInput
            confirmOnEnter={false}
            name="percent of"
            value={percent}
            max={999}
            onChange={onPercentChange}
            clickDialogMessageContent={clickDialogMessage}
            clickDialogMessageTitle={clickDialogMessageTitle}
            addInitialBorder
            decimals={2}
            fixedDecimalScale
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        className="h-max w-16 mt-8 mx-4 transparent-2 text-md"
      >
        {`% ${t("common.of")}`}
      </Grid>
      <Grid item container direction="column">
        <Grid
          item container
          alignItems="center"
          className="mb-2"
          style={{height: 20}}
        >
          {
            onSelectAll
              ? <Checkbox
                name="selectAll"
                aria-label="select all"
                checked={usedWorkTypes.length >= availableWorkTypes.length}
                onChange={onSelectAll}
                indeterminate={Boolean(usedWorkTypes.length && availableWorkTypes.length && usedWorkTypes.length !== availableWorkTypes.length)}
                className="m-0 ml-3 -mt-0-5"
                label={t("common.work_types")}
              />
              : <p>
                {t("common.work_types")}
              </p>
          }
        </Grid>
        <Grid
          item container
          direction="column"
          className="bg-primary px-3 py-1-5 rounded"
        >
          { availableWorkTypes
            .slice(0, allowHidingList && !expand ? maxVisible : undefined)
            .map(( id ) => (
              <WorkTypeCheckbox
                key={ id }
                id={ id }
                name={ editorStore.getWorkTypeName(id) || t("common.unnamed")}
                editedWorkTypeName={editedWorkTypeName}
                checked={usedWorkTypes.indexOf(id) >= 0}
                onChange={handleWorkTypeSelect(id)}
                usedInSections={blacklistedWorkTypes?.find((bw) => id === bw.id)?.sections}
              />
            ))
          }
          {
            allowHidingList &&
            <Link
              className="text-sm font-bold cursor-pointer"
              onClick={handleShowMore}
            >
              <Grid item container alignItems="center">
                {expand ? <ExpandLess className="text-2xl" /> : <ExpandMore className="text-2xl" />}
                {t(expand ? "common.show_less" : "common.show_more")}
              </Grid>
            </Link>
          }
          {!availableWorkTypes?.length && (
            <p className="semi-transparent">
              {t("views.editor.dialogs.estimate.empty")}
            </p>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

SettingContents.propTypes = {
  usedWorkTypes: arrayOf(number).isRequired,
  onWorkTypeSelect: func.isRequired,
  percent: number.isRequired,
  onPercentChange: func.isRequired,
  editedWorkTypeName: string,
  availableWorkTypes: arrayOf(number),
  blacklistedWorkTypes: arrayOf(shape({
    id: number.isRequired,
    sections: arrayOf(string).isRequired
  })),
  clickDialogMessage: string,
  clickDialogMessageTitle: string,
  hideList: bool,
  maxVisible: number,
  onSelectAll: func,
};