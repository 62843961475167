import { useState, useEffect } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import {
  useProjectEditorStore,
  useEditorWebsocket,
  useCheckProjectAccess,
} from "@hooks";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { ActionButton, Alert, TextClearInput } from "@components";
import {
  PdfExport,
  Share,
  XlsExport,
  ProjectSettings,
  CreateTemplateDialog,
} from "@dialogs";
import useStyle from "./TableToolbar.style";
import classnames from "classnames";
import { FileAdd } from "@assets";
import { useParams } from "react-router-dom";
import { ProfitabilityMode } from "./components/ProfitabilityMode";
import { VisibilityMode } from "./components/VisibilityMode";

export const TableToolbar = observer(({ isSharedVersion }) => {
  const classes = useStyle();
  const { projectUuid } = useParams();
  const editorStore = useProjectEditorStore();
  const { t } = useTranslation();
  const socket = useEditorWebsocket();

  const [alertText, setAlertText] = useState("");
  const [titleEditorFocused, focusTitleEditor] = useState(undefined);
  const [editedTitle, setEditedTitle] = useState("");
  const [showTemplateDialog, openTemplateDialog] = useState(false);

  const {
    projectName,
    projectDesc,
    projectUsers,
    // projectDue,
    // projectStatus,
  } = editorStore;

  const { isBusinessUser, isObserver, isOwner, isMiniUser } =
    useCheckProjectAccess(projectUsers);
  

  useEffect(() => {
    if (projectName) setEditedTitle(projectName.replace(/<(.|\n)*?>/g, ""));
  }, [projectName]);

  const changeProjectName = () => {
    if (projectName !== editedTitle) {
      socket?.requestProjectRename(editedTitle, projectUuid);
      editorStore.setProjectName(editedTitle);
    }
    focusTitleEditor(false);
  };

  const handleTitleFocus = () => {
    focusTitleEditor(true);
  };

  // const isEndangered =
  //   projectStatus === "done" || !projectDue
  //     ? false
  //     : isAfter(new Date(), add(projectDue, { days: 1 }))
  //     ? theme.props.warning
  //     : isAfter(new Date(), sub(projectDue, { days: 1 }))
  //     ? theme.palette.error.main
  //     : false;

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      name="table_title"
      className={classnames(isSharedVersion && "mb-5")}
    >
      <Grid item container direction="column" className="w-max max-w-full">
        <h1 className={classnames(
          "preset-title w-max max-w-full mb-2",
          isSharedVersion && classes.titleProposal,
          isSharedVersion && "px-0-5",
        )}>
          {!isSharedVersion && !isMiniUser && isOwner ? (
            <TextClearInput
              onFocus={handleTitleFocus}
              onAccept={changeProjectName}
              onChange={setEditedTitle}
              value={editedTitle}
              confirmOnEnter
              acceptOnClickAway
              focused={titleEditorFocused}
              containerClass={classes.projectTitleEditor}
              className="w-max max-w-full"
            />
          ) : (
            projectName?.replace(/<(.|\n)*?>/g, "")
          )}
        </h1>
        {/*{!isSharedVersion && (*/}
        {/*  <Grid*/}
        {/*    item*/}
        {/*    container*/}
        {/*    wrap="nowrap"*/}
        {/*    alignItems="flex-start"*/}
        {/*    justifyContent="center"*/}
        {/*    className="w-max"*/}
        {/*  >*/}
        {/*    {projectDue && !isEndangered ? (*/}
        {/*      <ScheduleOutlined*/}
        {/*        className={classnames(classes.info, "mr-1 text-md")}*/}
        {/*      />*/}
        {/*    ) : (*/}
        {/*      <WatchLaterOutlined*/}
        {/*        className="mr-1 text-sm"*/}
        {/*        style={{ color: isEndangered }}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    <p className={classes.infoText}>*/}
        {/*      {projectDue*/}
        {/*        ? format(projectDue, "dd MMM yyyy")*/}
        {/*        : t("views.active.no_deadline")}*/}
        {/*    </p>*/}
        {/*  </Grid>*/}
        {/*)}*/}
      </Grid>
      {!isSharedVersion && (
        <Grid
          item
          container
          justifyContent="flex-end"
          className={classes.actionContainer}
        >
          { isBusinessUser && isObserver && <VisibilityMode /> }
          { isBusinessUser && isObserver && <ProfitabilityMode /> }
          { isBusinessUser && isObserver && <ProjectSettings /> }
          { isOwner && <>
            <ActionButton
              square
              icon={<FileAdd />}
              color="secondary"
              onClick={() => openTemplateDialog(true)}
              name={t("views.project.project_template.save_as")}
              tooltip
            />
            <CreateTemplateDialog
              projectUuid={projectUuid}
              open={showTemplateDialog}
              onClose={() => openTemplateDialog(false)}
              defaultValues={{
                description: projectDesc,
                name: projectName
              }}
            />
          </>}
          <PdfExport titleBarButton />
          { !isSharedVersion && <XlsExport /> }
          { isBusinessUser && <Share /> }
          <Alert
            title={alertText}
            isOpen={!!alertText}
            onAccept={() => setAlertText("")}
            acceptText={t("common.close")}
          />
        </Grid>
      )}
    </Grid>
  );
});

TableToolbar.propTypes = {
  isSharedVersion: bool,
  showProposal: bool,
};
