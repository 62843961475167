import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { SortableList } from "@components";
import { TotalsFactory } from "./components";

export const Totals = observer(({
  allowEdition,
  isSellerOrClient,
  ...factoryProps
}) => {
  const { settings }  = useStructureStore();
  
  const { visibleTotals } = settings;
  
  const handleTotalsReorder = (list) => {
    settings.reorderTotals(list);
  };
  
  return (
    visibleTotals?.length
      ? (
        <SortableList
          list={visibleTotals}
          path="totals"
          onListReorder={handleTotalsReorder}
          itemIdKey="name"
          className="w-max mt-4"
          displayAsFlexbox
          disabled={!allowEdition}
        >
          {
            visibleTotals
              .filter(({noDev}) => !noDev || isSellerOrClient)
              .map(({ name }, index) => (
                <TotalsFactory
                  key={name}
                  name={name}
                  allowEdition={allowEdition}
                  showDivider={index+1 < visibleTotals.length}
                  { ...factoryProps }
                />
              ))
          }
        </SortableList>
      )
      : <></>
  );
});

Totals.propTypes = {
  isProposal: bool,
  isSellerOrClient: bool,
};
