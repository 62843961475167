import Dexie from "dexie";

export const db = new Dexie("apropoDB");
db.version(1).stores({
  user: "++id, token, workspaceId, persist",
});
db.version(2).stores({
  user: "++id, token, workspaceId, persist",
  project: "&uuid, timestamp",
  commenter: "&id, apropoUser, fullName, timestamp",
});
db.version(5).stores({
  user: "++id, userId, token, workspaceId, persist",
  project: "&uuid, timestamp",
  commenter: "&id, apropoUser, fullName, timestamp",
});
db.version(6).stores({
  user: "++id, userId, token, workspaceId, persist",
  project: "&uuid, timestamp",
  commenter: "&id, project, fullName, timestamp",
});
db.version(8).stores({
  user: "++id, userId, token, workspaceId, persist",
  project: "&uuid, timestamp",
  comment: "++id, uuid, project, fullName, color, timestamp",
});
db.version(9).stores({
  user: "++id, userId, token, board, persist",
  project: "&uuid, timestamp",
  comment: "++id, uuid, project, fullName, color, timestamp",
});
