import { arrayOf, bool, number, object, shape } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { CELL_WIDTHS } from "@utils";
import { SummaryRow } from "@components";
import { AddChildTask } from "../../other";
import { TitleCell, PriceCell, DescriptionCell } from "../../cells";
import { TaskWorkType } from "../../TableBreakdown/components/TaskWorkType/TaskWorkType";
import { SummaryTitle } from "./components/SummaryTitle";
import useStyle from "./SectionSummaryRow.style";

export const SectionSummaryRow = observer(({
  section,
  isProposal,
  isLibrary,
  maxTitleCellWidth,
  maxDescriptionCellWidth,
  useClientActions,
  allowEdition,
  isFirst,
  pricesVisible,
  useDescriptionCell,
  visibleWorkTypes,
  noTurningOff,
}) => {
  const structure = useStructureStore();
  const classes = useStyle();
  
  const { usesTwoValues, settings } = structure;

  const {
    currencyObj,
    roundPrice,
    sectionFormat,
    descriptionMode,
    titleCellWidth,
    descriptionCellWidth
  } = settings;

  const {
    displayPrice,
    displayPriceMax,
    startingLevel,
    hasChildren
  } = section;

  const showSummary = sectionFormat === 2 && hasChildren;
  
  const showDescriptionCell = descriptionMode === 3 && useDescriptionCell;
  
  const useVisibilityCell = useClientActions && showSummary && !noTurningOff;
  
  const showMaxValue = usesTwoValues && displayPrice !== displayPriceMax;
  
  return (
    <SummaryRow
      useVisibilityCell={useVisibilityCell}
      displayMinMax={usesTwoValues}
      presetClass="preset-page"
      noBackground
      className={classes.sectionSummaryRow}
    >
      <TitleCell
        pinnedLeft={0}
        minWidth={descriptionMode === 3 ? titleCellWidth : CELL_WIDTHS.TITLE}
        maxWidth={maxTitleCellWidth}
        stretchToChildrenWidth={!showSummary}
        allowOverflowDisplay={descriptionMode < 3 && showSummary}
        actions={
          showSummary && descriptionMode < 3 ? (
            <SummaryTitle isProposal={isProposal} />
          ) : undefined
        }
      >
        {allowEdition && (
          <AddChildTask
            parent={section}
            tableDepth={isLibrary ? startingLevel : 1}
            showId={isFirst}
          />
        )}
      </TitleCell>
      {
        showDescriptionCell && showSummary &&
        <DescriptionCell
          allowOverflowDisplay={showSummary}
          minWidth={descriptionCellWidth}
          maxWidth={maxDescriptionCellWidth}
          left={titleCellWidth}
          actions={
            <SummaryTitle isProposal={isProposal} />
          }
        />
      }
      {showSummary && (
        <>
          {visibleWorkTypes?.map((workType) => (
            <TaskWorkType
              key={workType.id}
              element={section}
              workType={workType}
              displayContent
              fullIdPath={[section.id]}
              className="font-bold"
              textPresetClass="preset-tableFooter"
            />
          ))}
          {pricesVisible && (
            <PriceCell
              value={displayPrice}
              valueMax={displayPriceMax}
              displayMax={showMaxValue}
              wider={usesTwoValues}
              symbolStart={currencyObj.symbolStart}
              symbolEnd={currencyObj.symbolEnd}
              displayContent={showSummary}
              showDecimals={!roundPrice}
              className="font-bold"
            />
          )}
        </>
      )}
    </SummaryRow>
  );
});

SectionSummaryRow.propTypes = {
  section: object.isRequired,
  allowEdition: bool,
  useClientActions: bool,
  useDescriptionCell: bool,
  isProposal: bool,
  isLibrary: bool,
  isFirst: bool,
  pricesVisible: bool,
  noTurningOff: bool,
  maxTitleCellWidth: number,
  maxDescriptionCellWidth: number,
  visibleWorkTypes: arrayOf(shape({
    id: number,
    invisible: bool,
  })),
};
