import { useEffect, useRef } from "react";
import { bool, number, string, node, func, oneOf, oneOfType, shape, arrayOf } from "prop-types";
import { Grid, TableContainer } from "@material-ui/core";
import { useStores } from "@hooks";
import { handleFixedTableHeader } from "@utils";
import useStyle from "./Table.style";
import classnames from "classnames";

export const Table = ({
  id,
  children,
  headers,
  className,
  overflowContent,
  scrollablePage,
  loadNextPage,
  containerHeight,
  headerOffset=0,
}) => {
  const classes = useStyle();
  const { stateStore } = useStores();

  const ref = useRef();

  const handleScroll = (e) => {
    if(!scrollablePage || !ref.current) return;
    
    handleFixedTableHeader(ref.current, id, e.currentTarget.scrollTop, headerOffset);
  };

  useEffect(() => {
    if (scrollablePage)
      stateStore.setScrollHandler(handleScroll);
    return () => {
      stateStore.resetScrollHandler();
      stateStore.resetScrollEndHandler();
    };
  }, []);

  useEffect(() => {
    stateStore.resetScrollEndHandler();
    if (loadNextPage) stateStore.setScrollEndHandler(loadNextPage);
  }, [loadNextPage]);

  return (
    <TableContainer
      id={id}
      className={classnames(
        classes.tableContainer,
        containerHeight && "h-full",
        className,
        overflowContent ? "overflow-visible" : "overflow-auto"
      )}
    >
      <Grid container role="rowgroup" direction="column">
        <Grid
          item
          container
          role="row"
          wrap="nowrap"
          className={`${classes.headRow}`}
          ref={ref}
        >
          {headers.map((h, i) => {
            let { name, label, width, flexible, justify, sticky, shadow } = h;
            
            return (
              <Grid
                key={name || label || i}
                item
                container
                justifyContent={justify}
                role="columnheader"
                className={`${classes.cell} ${classes.headCell} ${
                  sticky >= 0 ? "sticky" : ""
                } ${shadow ? "shadow" : ""}`}
                style={
                  width
                    ? {
                      width: !flexible ? width : undefined,
                      maxWidth: !flexible ? width : undefined,
                      minWidth: width,
                      left: sticky >= 0 ? sticky : undefined,
                    }
                    : null
                }
              >
                {name || label}
              </Grid>
            );
          })}
        </Grid>
        {children}
      </Grid>
    </TableContainer>
  );
};

Table.propTypes = {
  id: string.isRequired,
  className: string,
  headers: arrayOf(
    shape({
      name: string,
      label: oneOfType([
        string,
        node,
        arrayOf(node),
      ]),
      width: oneOfType([string, number]), //minimal width of the column
      justify: oneOf(["flex-start", "center", "flex-end"]),
      flexible: bool, // will grow with the table (no width & maxWidth)
      sticky: number, // number of pixels from the left; element will be pinned after scrolled container moves this much
      shadow: bool, // a slight shadow will appear on the right side (useful for
    })
  ).isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  overflowContent: bool,
  scrollablePage: bool,
  loadNextPage: func,
  containerHeight: bool,
  headerOffset: number,
};
