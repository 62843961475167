import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useSummaryUnitCostInputTooltipAlert = (
  profitabilityMode,
  hasOnlyCost,
) => {
  const { t } = useTranslation();
  
  const messageTitle = useMemo(() => {
    if(profitabilityMode && hasOnlyCost)
      return t("views.editor.profitability_fixed_alert.title")
  }, [profitabilityMode, hasOnlyCost]);
  
  const messageContent = useMemo(() => {
    if(profitabilityMode && hasOnlyCost)
      return t("views.editor.profitability_fixed_alert.content")
  }, [profitabilityMode, hasOnlyCost]);
  
  return {
    messageTitle,
    messageContent,
  }
}