import { SvgIcon } from "@material-ui/core";

export const TemplatePlaceholder = (props) => (
  <SvgIcon {...props} viewBox="0 0 390 186" fill="none">
    <mask
      id="mask0"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="390"
      height="186"
    >
      <path
        d="M0.5 8C0.5 3.85787 3.85787 0.5 8 0.5H382C386.142 0.5 389.5 3.85786 389.5 8V185.5H0.5V8Z"
        fill="white"
        stroke="#E5E5E5"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect x="-12" y="-10" width="414" height="207" fill="#F5F6FA" />
      <rect
        x="27.5"
        y="51.5"
        width="1135"
        height="71"
        rx="7.5"
        fill="white"
        stroke="#E4E6EB"
      />
      <rect x="51" y="67" width="40" height="40" rx="20" fill="#64738E" />
      <rect x="99" y="70" width="115" height="16" rx="4" fill="#64738E" />
      <rect x="316" y="78" width="115" height="16" rx="4" fill="#64738E" />
      <rect x="99" y="92" width="103" height="12" rx="4" fill="#B4BCCB" />
      <g opacity="0.94">
        <rect
          x="27.5"
          y="129.5"
          width="1135"
          height="71"
          rx="7.5"
          fill="white"
          stroke="#E4E6EB"
        />
        <rect x="51" y="145" width="40" height="40" rx="20" fill="#64738E" />
        <rect x="99" y="148" width="115" height="16" rx="4" fill="#64738E" />
        <rect x="316" y="156" width="115" height="16" rx="4" fill="#64738E" />
        <rect x="99" y="170" width="103" height="12" rx="4" fill="#B4BCCB" />
      </g>
      <path
        d="M154 25L149.833 29.1667H158.167L154 25ZM149.833 30.8333L154 35L158.167 30.8333H149.833Z"
        fill="#B4BCCB"
      />
      <path
        d="M382 25L377.833 29.1667H386.167L382 25ZM377.833 30.8333L382 35L386.167 30.8333H377.833Z"
        fill="#B4BCCB"
      />
      <rect x="51" y="22" width="89" height="16" rx="4" fill="#B4BCCB" />
      <rect x="316" y="22" width="52" height="16" rx="4" fill="#B4BCCB" />
      <rect
        x="-12"
        y="-11"
        width="414"
        height="207"
        fill="url(#paint0_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="195"
        y1="-11"
        x2="195"
        y2="196"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.183575" stopColor="#F5F6FA" stopOpacity="0" />
        <stop offset="0.971014" stopColor="#F5F6FA" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
