import { forwardRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Add } from "@material-ui/icons";
import { Button } from "./Button";
import useStyle from "./CommonButtons.style";
import classnames from "classnames";

const ClearButton = forwardRef(({
  className,
  size = "small",
  ...props
}, ref) => {
  const classes = useStyle();
  return (
    <Button
      ref={ref}
      {...props}
      color={props.color || "primary"}
      variant="text"
      size={size}
      className={classnames(classes.clearButton, className)}
    />
  );
});
ClearButton.propTypes = Button.propTypes;

const MenuButton = forwardRef(({
  className,
  ...props
}, ref) => {
  const classes = useStyle();
  return (
    <Button
      ref={ref}
      color="secondary"
      variant="text"
      size="small"
      {...props}
      className={classnames(classes.menuButton, className)}
    />
  );
});
MenuButton.propTypes = Button.propTypes;

const ActionButton = forwardRef(( {
  size="large",
  variant="contained",
  ...props
}, ref) => (
  <Button
    ref={ ref }
    size={size}
    variant={variant}
    { ...props }
  />
));
ActionButton.propTypes = Button.propTypes;

const AddButtonFull = forwardRef(({
  className,
  ...props
}, ref) => {
  const classes = useStyle();
  return (
    <ClearButton
      ref={ref}
      {...props}
      className={classnames(classes.addButtonFull, className)}
      icon={
        <Grid
          container
          className={classes.circle}
          justifyContent="center"
          alignItems="center"
        >
          <Add className={classes.icon} />
        </Grid>
      }
    />
  );
});
AddButtonFull.propTypes = Button.propTypes;

export { ClearButton, Button, MenuButton, ActionButton, AddButtonFull };
