import { Switch } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";

export const MinMaxSwitch = observer(() => {
  
  const { t } = useTranslation();
  const structure = useStructureStore();
  const { useMinMax } = structure.settings;
  const { apply, useAvgValues } = structure.visibility;
  
  const handleMinMaxChange = () => {
    structure.historyManager.startGroup();
    if(apply)
      structure.visibility.setUseAvgValues(!useAvgValues)
    else
      structure.settings.setMinMax(!useMinMax);
    structure.generateTimeline();
    structure.historyManager.stopGroup();
  };
  
  return (
    <>
      <p className="mx-2">{t("views.editor.min_max")}</p>
      <Switch
        id="hoursRange"
        color="primary"
        checked={Boolean(apply ? !useAvgValues : useMinMax)}
        onChange={handleMinMaxChange}
        name={
          useMinMax
            ? "Revert to fixed values"
            : "Allow min-max values"
        }
      />
    </>
  )
});