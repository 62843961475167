export const LOCKED_FEATURES = {
  USER: { code: "user", serverCode: "feature_user" },
  ESTIMATION: { code: "estimation", serverCode: "feature_estimation" },
  ALLOCATION: {
    code: "allocation",
    serverCode: "feature_team_allocation",
    plan: "package_business",
  },
  TIMELINE: {
    code: "timeline",
    serverCode: "feature_project_timeline",
    plan: "package_pro",
  },
  PDF: {
    code: "pdf",
    serverCode: "feature_merging_estimate_with_pd",
    plan: "package_starter",
  },
  XLS_EXPORT: {
    code: "xls_export",
    serverCode: "feature_export_excel",
    plan: "package_pro",
  },
  RECORDINGS: {
    code: "recordings",
    serverCode: "feature_record",
    plan: "package_starter",
  },
  VISIBILITY: {
    code: "visibility",
    serverCode: "feature_visibility_management",
    plan: "package_business",
  },
  COMMENTS: {
    code: "comments",
    serverCode: "feature_external_comments",
    plan: "package_business",
  },
  DOMAIN: { code: "domain", serverCode: "feature_domain", plan: "package_pro" },
  JIRA: {
    code: "jira",
    serverCode: "feature_jira_integration",
    plan: "package_business",
  },
  COMPARISON: {
    code: "comparison",
    serverCode: "feature_estimation_vs_reality_co",
    plan: "package_pro",
  },
  AI_STORY: {
    code: "ai_story",
    serverCode: "feature_ai_user_story",
    plan: "package_business",
  },
  AI_DESC: {
    code: "ai_desc",
    serverCode: "feature_ai_description",
    plan: "package_business",
  },
  AI_ELEMENT: {
    code: "ai_element",
    serverCode: "feature_ai_project",
    plan: "package_business",
  },
  STORAGE: {
    code: "storage",
    serverCode: "feature_storage",
    plan: "package_business",
  },
  BOARDS: {
    code: "boards",
    serverCode: "feature_workspace",
    plan: "package_business",
  },
  PROFITABILITY: {
    code: "profitability",
    serverCode: "feature_profitability",
    plan: "package_business",
  },
  INTERNAL_COMMENTS: {
    code: "internal_comments",
    serverCode: "feature_internal_comments",
    plan: "package_business",
  },
  OVERHEADS: {
    code: "overheads",
    serverCode: "feature_overheads",
    plan: "package_business",
  },
  CUSTOM_OVERHEADS: {
    code: "custom_overheads",
    serverCode: "feature_custom_overheads",
    plan: "package_pro",
  },
};
