import {useEffect, useRef, useState} from "react";
import {ActiveProjectStore, ActiveProjectStoreProvider} from "@stores";
import { getProjectDetailsQuery } from "@query";
import { useParams } from "react-router-dom";
import { ProjectEditionDialog } from "@dialogs";
import { CircularProgress, Grid } from "@material-ui/core";
import {PageCircularProgress} from "@components";

export const ActiveProjectDetails = () => {

  const { projectUuid } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const activeProjectStore = useRef(new ActiveProjectStore());

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const details = await getProjectDetailsQuery(projectUuid);
    activeProjectStore.current.addProject(details);
    activeProjectStore.current.setCurrentProject(projectUuid);
    setIsLoading(false);
  }

  return isLoading
    ? <PageCircularProgress/>
    : (
    <ActiveProjectStoreProvider value={activeProjectStore.current}>
      <Grid item container justifyContent="center" alignItems="center">
        {isLoading ? (
          <CircularProgress
            color="primary"
            size={40}
            style={{ position: "absolute", top: "50%", marginTop: -20 }}
          />
        ) : (
          <ProjectEditionDialog projectUuid={projectUuid} noDialog />
        )}
      </Grid>
    </ActiveProjectStoreProvider>
  );
};
