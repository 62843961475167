import { SvgIcon } from "@material-ui/core";

export const PdfFile = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32" fill="none">
    <path
      d="M20.334 0H3.666C1.64 0 0 1.64 0 3.666V25.666C0 27.692 1.64 29.332 3.666 29.332H17.532C17.398 28.692 17.332 28.012 17.332 27.332V20.332C17.332 20.226 17.332 20.106 17.346 19.998H5.332C4.6 20 4 19.4 4 18.666C4 17.932 4.6 17.332 5.334 17.332H17.894C18.894 14.718 21.2 12.732 24 12.172V3.666C24 1.64 22.36 0 20.334 0ZM5.334 6.666H12C12.734 6.666 13.334 7.266 13.334 8C13.334 8.734 12.734 9.334 12 9.334H5.334C4.6 9.334 4 8.734 4 8C4 7.266 4.6 6.666 5.334 6.666ZM18.666 14.666H5.332C4.598 14.666 3.998 14.066 3.998 13.332C4 12.6 4.6 12 5.334 12H18.668C19.4 12 20 12.6 20 13.334C20 14.066 19.4 14.666 18.666 14.666Z"
      fill="#4098F4"
    />
    <path
      d="M27.334 32C24.762 32 22.668 29.906 22.668 27.334V20.334C22.668 18.68 24.014 17.334 25.668 17.334C27.322 17.334 28.668 18.68 28.668 20.334V27C28.668 27.552 28.22 28 27.668 28C27.116 28 26.668 27.552 26.668 27V20.334C26.668 19.782 26.218 19.334 25.668 19.334C25.118 19.334 24.668 19.782 24.668 20.334V27.334C24.668 28.804 25.864 30 27.334 30C28.804 30 30 28.804 30 27.334V18.334C30 17.782 30.448 17.334 31 17.334C31.552 17.334 32 17.782 32 18.334V27.334C32 29.906 29.906 32 27.334 32Z"
      fill="#4098F4"
      fillOpacity="0.5"
    />
  </SvgIcon>
)
