import { Children, isValidElement, cloneElement } from "react";
import { bool, object, arrayOf, func, node, oneOf } from "prop-types";
import { PopMenu } from "@components";
import useStyle from "./ActionPopMenu.style";
import classnames from "classnames";

export const ActionPopMenu = ({
  show,
  anchor,
  children,
  onMouseEnter,
  onMouseLeave,
  size="medium",
  color="secondary",
  placement="bottom",
}) => {
  const classes = useStyle();

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        ...child.props,
        variant: "text",
        size,
        color,
        className: classnames({
          [classes.actionButton]: true,
          [classes.actionButtonLarge]: size === "large",
          [classes.actionButtonSmall]: size === "small",
        }),
      });
    }
  });

  return (
    <PopMenu
      show={show}
      offset={[0, 0]}
      anchor={anchor}
      placement={placement}
      className={classes.actionMenu}
      noAutofocus
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {childrenWithProps}
    </PopMenu>
  );
};

ActionPopMenu.propTypes = {
  show: bool,
  anchor: object,
  children: arrayOf(node).isRequired,
  onMouseEnter: func.isRequired,
  onMouseLeave: func.isRequired,
  size: oneOf(["small", "medium", "large"]),
  color: oneOf(["primary", "secondary"]),
  placement: oneOf([
    "bottom-start",
    "bottom",
    "bottom-end",
    "left-start",
    "left",
    "left-end",
    "right-start",
    "right",
    "right-end",
  ]),
};

