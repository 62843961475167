import {makeStyles} from '@material-ui/core/styles';

const useStyle = makeStyles(() => ({
	spinner: {
    position: "relative",
    left: "calc(50% - 20px)",
    margin: 10,
    alignSelf: "center",
	},
}));

export default useStyle;
