import { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@components";
import Grid from "@material-ui/core/Grid";
import { useProjectCreatorStore } from "@hooks";

export const Sheets = observer(() => {
  const { t } = useTranslation();
  const creator = useProjectCreatorStore();

  const { xlsData, activeSheet } = creator;

  useEffect(() => {
    if (xlsData && activeSheet < 0) {
      creator.setActiveSheet(0);
      if (xlsData.length === 1) creator.unlockStep(2);
    }
  }, [xlsData]);

  return (
    <Grid
      item
      container
      wrap="nowrap"
      direction="column"
      className="px-6"
    >
      <p className="text-lg font-bold text-sm">
        {t("views.import.sheets_title")}
        {":"}
      </p>
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-end"
        className="mt-2 px-2"
      >
        {xlsData.map((s, i) => (
          <Checkbox
            key={s.title + i}
            name={s.title}
            checked={activeSheet === i}
            onChange={() => creator.setActiveSheet(i)}
            disabled={xlsData.length === 1}
            label={s.title}
            labelClassName="my-1"
          />
        ))}
      </Grid>
    </Grid>
  );
});
