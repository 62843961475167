import { useEffect, useState } from "react";
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  string,
} from "prop-types";
import { ClickAwayListener, Fade, Paper, Portal } from "@material-ui/core";
import { usePopper } from "react-popper";
import useStyle from "./PopMenu.style";
import classnames from "classnames";

export const PopMenu = ({
  id,
  show,
  children,
  placement="bottom",
  anchor,
  onClickAway,
  showArrow,
  className,
  style,
  arrowStyle={},
  offset,
  inline,
  onMouseEnter,
  onMouseLeave,
}) => {
  const classes = useStyle();

  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes, update } = usePopper(anchor, popperElement, {
    placement,
    modifiers: [
      ...(showArrow
        ? [{ name: "arrow", options: { element: arrowElement } }]
        : []),
      { name: "flip", options: { enabled: true } },
      { name: "offset", options: { offset: offset?.slice(0, 2) || [0, 8] } },
      {
        name: "preventOverflow",
        options: { enabled: true, padding: 16, rootBoundary: "viewport" },
      },
    ],
  });
  
  useEffect(() => {
    update && update();
  }, [show, anchor?.offsetHeight]);

  // useEffect(() => {
  //   if (!noAutofocus)
  //     popperElement?.querySelector("button:not([disabled])")?.focus();
  // }, [popperElement]);

  const content = (
    <Fade in={show}>
      <Paper className={classnames(classes.content, className)} style={style}>
        {children}
      </Paper>
    </Fade>
  );

  return <Portal>
    {
      (show || inline) &&
      <div
        id={id}
        ref={setPopperElement}
        style={styles.popper}
        className={classnames(classes.root, !show && "hidden")}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        role="presentation"
        {...attributes.popper}
      >
        {showArrow && (
          <div
            className={classnames("arrow", classes.arrow)}
            style={{ ...styles.arrow, ...arrowStyle }}
            ref={setArrowElement}
          />
        )}
        {onClickAway ? (
          <ClickAwayListener onClickAway={onClickAway}>
            {content}
          </ClickAwayListener>
        ) : (
          content
        )}
      </div>
    }
  </Portal>;
};

PopMenu.propTypes = {
  id: string,
  show: bool,
  anchor: object,
  children: oneOfType([arrayOf(node), node]).isRequired,
  onClickAway: func,
  showArrow: bool,
  placement: oneOf([
    "top",
    "top-start",
    "top-end",
    "bottom-start",
    "bottom",
    "bottom-end",
    "left-start",
    "left",
    "left-end",
    "right-start",
    "right",
    "right-end",
  ]),
  className: string,
  style: object,
  offset: arrayOf(number),
  arrowStyle: object,
  noAutofocus: bool,
  onMouseEnter: func,
  onMouseLeave: func,
  inline: bool,
};