import { APIMethod, fetchData } from "@client";

export async function getAllNotificationsQuery(page, limit=15) {
  return await fetchData("/user/notifications", APIMethod.GET, {
    params: {
      type: "all",
      page,
      limit
    }
  });
}
