import { SvgIcon } from "@material-ui/core";

export const Jira = (props) => (
  <SvgIcon {...props} viewBox="0 0 23 24">
    <path
      d="M22.5754 11.1505L12.4102 0.985334L11.4249 0L3.77297 7.65193L0.274413 11.1505C0.0992349 11.3259 0.000839233 11.5636 0.000839233 11.8115C0.000839233 12.0594 0.0992349 12.2972 0.274413 12.4726L7.2653 19.4635L11.4249 23.6231L19.0768 15.9711L19.1953 15.8527L22.5754 12.4726C22.7506 12.2972 22.849 12.0594 22.849 11.8115C22.849 11.5636 22.7506 11.3259 22.5754 11.1505V11.1505ZM11.4249 15.3039L7.93258 11.8115L11.4249 8.31922L14.9172 11.8115L11.4249 15.3039Z"
      fill="#2684FF"
    />
    <path
      d="M11.4249 8.31947C10.3265 7.22096 9.70737 5.73247 9.7027 4.17904C9.69802 2.62561 10.3082 1.13342 11.3999 0.0283203L3.75735 7.66778L7.91696 11.8274L11.4249 8.31947Z"
      fill="url(#paint0_linear_247_48)"
    />
    <path
      d="M14.9266 11.8018L11.4249 15.3034C11.9713 15.8496 12.4048 16.498 12.7006 17.2117C12.9963 17.9255 13.1486 18.6905 13.1486 19.463C13.1486 20.2356 12.9963 21.0006 12.7006 21.7143C12.4048 22.4281 11.9713 23.0765 11.4249 23.6227V23.6227L19.0862 15.9614L14.9266 11.8018Z"
      fill="url(#paint1_linear_247_48)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_247_48"
        x1="10.8012"
        y1="4.78661"
        x2="5.92447"
        y2="9.66339"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_247_48"
        x1="12.0922"
        y1="18.7958"
        x2="16.9596"
        y2="13.9283"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </SvgIcon>
)
