import { memo, useMemo } from "react";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { getGridSizes } from "./getGridSizes";

export const EditorTitleRow = memo(({
  noEdition,
  fullSettings,
  useTeamTogetherSwitch,
}) => {
  const { t } = useTranslation();
  const gridSizes = useMemo(() => getGridSizes(fullSettings, useTeamTogetherSwitch), [fullSettings, useTeamTogetherSwitch]);
  
  return (
    <Grid
      item
      container
      wrap="nowrap"
      alignItems="center"
      xs={12}
      spacing={1}
    >
      <Grid
        item
        container
        xs={gridSizes.name}
        justifyContent="space-between"
        alignItems="center"
      />
      {!noEdition && (
        <>
          <Grid item container wrap="nowrap" alignItems="center" xs={gridSizes.wta}>
            {t("views.editor.dialogs.timeline_settings.wta")}
          </Grid>
          {
            fullSettings &&
            <Grid item container wrap="nowrap" alignItems="center" xs={gridSizes.start} className="pl-4">
              {t("views.editor.timeline_table.work_start")}
            </Grid>
          }
          {
            fullSettings && useTeamTogetherSwitch &&
            <Grid item container wrap="nowrap" alignItems="center" xs={gridSizes.teamTogether} className="pl-4">
              {t("views.editor.timeline_table.team_together")}
            </Grid>
          }
        </>
      )}
    </Grid>
  );
});

EditorTitleRow.propTypes = {
  fullSettings: bool,
  noEdition: bool,
  useTeamTogetherSwitch: bool,
};
