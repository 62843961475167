import { ValueCell } from "@components";
import { observer } from "mobx-react";
import { useStructureStore, useVisibilityModeCellClassName } from "@hooks";

export const RateCell = observer(() => {
  const structure = useStructureStore();
  const { hideSummaryRate } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryRate);
  
  return <ValueCell widest className={className} />;
})