import { useMemo } from "react";
import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { parsePrice } from "project-structure";
import { useProjectEditorStore, useStructureStore, useSummaryUnitCostInputTooltipAlert } from "@hooks";
import { MAX_VALUES } from "@utils";
import { ValueCell } from "@components";

export const CompanyUnitCostCell = observer(({
  element,
  displayCellContent,
  allowEdition,
  useCompanyCurrency,
  profitabilityMode
}) => {
  const { currencyExchangeRate } = useProjectEditorStore();
  const structure = useStructureStore();
  const tooltip = useSummaryUnitCostInputTooltipAlert(profitabilityMode, element.hasOnlyCost);
  
  const { currencyObj, companyCurrencyObj, modifier, timeModifier } = structure.settings;
  const {
    hasResources,
    hasOnlyCost,
    companyUnitCost,
    displayCompanyUnitCost,
  } = element;
  
  const usedCurrency = useMemo(() => (
    useCompanyCurrency
      ? companyCurrencyObj
      : currencyObj
  ), [useCompanyCurrency, companyCurrencyObj, currencyObj]);
  
  const displayValue = useMemo( () => (
    useCompanyCurrency && currencyExchangeRate !== 1
      ? element.getDisplayCompanyUnitCostWithModifier(currencyExchangeRate)
      : displayCompanyUnitCost
), [companyUnitCost, useCompanyCurrency, currencyExchangeRate]);
  
  const handleCompanyUnitCostChange = (unitCost) => {
    element.setCompanyUnitCost(unitCost / modifier / (useCompanyCurrency ? currencyExchangeRate : 1));
  };
  
  return (
    <ValueCell
      widest
      emptyInputs={hasOnlyCost}
      displayContent={displayCellContent}
      value={displayValue}
      onValueChange={handleCompanyUnitCostChange}
      editable={!hasResources && allowEdition}
      max={timeModifier === 1 ? MAX_VALUES.RATE_HOUR : MAX_VALUES.RATE_DAY}
      symbolStart={usedCurrency.symbolStart}
      symbolEnd={usedCurrency.symbolEnd}
      showDecimals
      parser={parsePrice}
      inputClassName="input-unit-cost"
      { ...tooltip }
    />
  );
});

CompanyUnitCostCell.propTypes = {
  element: object.isRequired,
  displayCellContent: bool,
  allowEdition: bool,
  useCompanyCurrency: bool,
  profitabilityMode: bool,
};
