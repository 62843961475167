
import { APIMethod, fetchData } from "@client";

export async function saveShareSettingsQuery(
  projectUuid,
  shareSettings
) {
  await fetchData(`/project/set/${projectUuid}`, APIMethod.PATCH, {
    data: { settings: JSON.stringify(shareSettings) },
  });
}
