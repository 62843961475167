import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import {
  useStructureStore,
  useSummaryTeamInputTooltipAlert,
  useVisibilityModeCellClassName
} from "@hooks";
import { ValueCell } from "@components";
import useStyle from "../../TeamMembersCell.style"
import classnames from "classnames";

export const TeamMembersCell = observer(({
  element,
  allowEdition,
  profitabilityMode
}) => {
  const classes = useStyle();
  const structure = useStructureStore();
  const tooltip = useSummaryTeamInputTooltipAlert();
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryTeam);
  
  const { usesAnyValue } = structure;
  const { isTimelineTableUsed } = structure.settings;
  
  const handleTeamMembersChange = (members) => {
    element.setTeamMembers(members);
  };

  return (
    <ValueCell
      wider
      displayContent
      min={1}
      emptyInputs={!usesAnyValue}
      value={element.teamMembers || 1}
      onValueChange={handleTeamMembersChange}
      editable={allowEdition && !profitabilityMode && !isTimelineTableUsed}
      className={className}
      textPresetClass={classnames("preset-summaryText", classes.input)}
      inputClassName="input-team-members"
      { ...tooltip }
    />
  );
});

TeamMembersCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  profitabilityMode: bool,
};