import { Totals } from "project-structure";
import { TotalCost, TotalHours, TotalTime } from "../totalsTypes";
import { useStructureStore } from "@hooks";
import { observer } from "mobx-react";
import { bool, string } from "prop-types";

export const TotalsFactory = observer(({
  name,
  isProposal,
  allowEdition,
  showDivider,
  ...totalsProps
}) => {
  const structure = useStructureStore();
  const {
    apply,
    hideTotalHours,
    hideTotalCost,
    hideTotalTime,
  } = structure.visibility;
  
  const props = {
    isProposal,
    ...totalsProps,
    divider: showDivider,
  };
  
  switch (name) {
    case Totals.COST:
      return isProposal && apply && hideTotalCost
        ? <></>
      : <TotalCost allowEdition={allowEdition} {...props} />;
      
    case Totals.HOURS:
      return isProposal && apply && hideTotalHours
        ? <></>
        : <TotalHours allowEdition={allowEdition} {...props} />;
      
    case Totals.TIME:
      return isProposal && apply && hideTotalTime
        ? <></>
        : <TotalTime allowEdition={allowEdition} {...props} />;
  }
});

TotalsFactory.propTypes = {
  name: string.isRequired,
  isProposal: bool,
  allowEdition: bool,
  showDivider: bool,
}