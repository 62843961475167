import { number, string, object, arrayOf, oneOfType } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TextClearInput } from "@components";
import { useLibraryTaskNameEditor } from "./useLibraryTaskNameEditor";

export const LibraryTaskNameEditor = observer(({
  element,
  parentIds=[],
}) => {
  const {
    focused,
    anchorEl,
    searchValue,
    taskNameAlreadyUsed,
    handleFocus,
    handleInputChange,
    handleModuleSelect,
  } = useLibraryTaskNameEditor({ element, parentIds });

  const { t } = useTranslation();

  return (
    <TextClearInput
      onChange={handleInputChange}
      value={searchValue}
      focused={focused}
      error={taskNameAlreadyUsed}
      onFocus={handleFocus}
      onAccept={handleModuleSelect}
      placeholder={t("views.editor.dialogs.features.new_name_library")}
      ref={anchorEl}
      confirmOnEnter
      acceptOnClickAway
    />
  );
});

LibraryTaskNameEditor.propTypes = {
  element: object.isRequired,
  parentIds: arrayOf(oneOfType([number, string])),
};
