import {
  number,
  string,
  object,
  arrayOf,
  node,
  oneOf,
  func,
  oneOfType,
} from "prop-types";
import { default as MuiAvatar } from "@material-ui/core/Avatar";
import { default as Badge } from "@material-ui/core/Badge";
import { default as Tooltip } from "@material-ui/core/Tooltip";
import { Close } from "@material-ui/icons";
import { Star } from "@assets";
import { getFullName, getInitials } from "@utils";
import useStyle from "./Avatar.style";
import classnames from "classnames";
import { useMemo } from "react";

export const Avatar = ({
  id,
  avatar,
  firstname,
  lastname,
  fullname,
  emailTooltip,
  color,
  borderWidth,
  borderColor,
  className,
  style,
  children,
  removeHandler,
  level=0,
  size=24,
  variant="circular",
}) => {
  const classes = useStyle();

  const onClick = () => {
    removeHandler && removeHandler(id || firstname);
  };

  const styles = {
    ...style,
    width: size,
    height: size,
    fontSize: size / 2 - 1,
    borderWidth,
    borderColor,
    backgroundColor: color,
  };
  
  const name = useMemo(() => (
    fullname ? getInitials(...fullname.split(" ")) : getInitials(firstname, lastname)
  ), [fullname, firstname, lastname]);

  const comp = (
    <MuiAvatar
      aria-label={`${firstname}${lastname ? " " + lastname : ""}`}
      alt={`${firstname}${lastname ? " " + lastname : ""}`}
      variant={variant}
      style={{ ...styles }}
      className={classnames(classes.root, className)}
    >
      {avatar && avatar.length > 8 ? (
        <img
          src={avatar}
          className={classes.image}
          alt={name}
        />
      ) : (
        <span>{name}</span>
      )}
      {Boolean(removeHandler) && (
        <div
          className={classes.removeSection}
          onClick={onClick}
          aria-label="remove avatar"
        >
          <Close />
        </div>
      )}
      {children}
    </MuiAvatar>
  );

  const tooltipWrapper = emailTooltip ? (
    <Tooltip
      title={
        <>
          <p>{fullname || getFullName(firstname, lastname)}</p>
          {emailTooltip && <p>{emailTooltip}</p>}
        </>
      }
    >
      {comp}
    </Tooltip>
  ) : (
    comp
  );

  return level ? (
    <Badge
      badgeContent={<Star className={classes["star-" + level]} />}
      overlap="circular"
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      aria-label={`${
        level === 3 ? "author" : level === 2 ? "owner" : "user"
      } badge`}
      className={className}
    >
      {tooltipWrapper}
    </Badge>
  ) : (
    tooltipWrapper
  );
};

Avatar.propTypes = {
  id: oneOfType([string, number]),
  avatar: string,
  color: string,
  borderColor: string,
  borderWidth: number,
  fullname: string,
  firstname: string,
  lastname: string,
  email: string,
  removeHandler: func,
  className: string,
  size: number,
  style: object,
  level: oneOf([0, 1, 2, 3]),
  emailTooltip: string,
  variant: oneOf(["circle", "circular", "rounded", "square"]),
  children: oneOfType([arrayOf(node), node]),
};
