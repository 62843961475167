import { bool, node } from "prop-types";
import { observer } from "mobx-react";
import {
  useContainerScrollHandler,
  useEditorWebsocket,
  useProjectEditorStore,
  useResizeObserver, useStores
} from "@hooks";
import { pdfjs, Document, Page } from "react-pdf";
import Grid from "@material-ui/core/Grid";
import useStyle from "./ViewBox.style";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useEffect } from "react";
import { SCALE_PRESETS } from "@utils";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const ViewBox = observer(({
  children,
  showPageImages
}) => {

  const editorStore = useProjectEditorStore();
  const { stateStore } = useStores();
  const socket = useEditorWebsocket();
  const {
    pdfDocument,
    pdfPagesLoaded,
    headerPages,
    footerPages,
    scale,
    scaleValue
  } = editorStore;

  const classes = useStyle();
  
  const handleContainerScroll = useContainerScrollHandler()
  
  const handleContainerRef = (ref) => {
    editorStore.setPageViewRef(ref);
    stateStore.setAuxiliaryScrollRef(ref);
  }
  
  useEffect(() => {
    stateStore.setUseAuxiliaryScrollRef(true);
    return () => {
      stateStore.setUseAuxiliaryScrollRef(false);
    }
  })
  
  useEffect(() => {
    if(pdfPagesLoaded && scaleValue !== SCALE_PRESETS.CUSTOM)
      handleRescale();
  }, [pdfPagesLoaded])

  const handleScroll = (e) => {
    editorStore.findCurrentPage();
    handleContainerScroll(e);
  };
  
  const onDocumentLoad = async (data) => {
    const initialUsedPdfPages = await editorStore.setupDocument(data);
    if(initialUsedPdfPages) {
      socket?.updateCoverPages(pdfDocument.id, initialUsedPdfPages.usedPdfPages, editorStore.proposalTablePosition);
      if(initialUsedPdfPages.scale)
        socket?.updateCoverScale(pdfDocument.id, { scale: initialUsedPdfPages.scale})
    }
  }
  
  const handleRescale = () => {
    editorStore.setScale()
  }
  
  useResizeObserver(handleRescale, editorStore.pageViewRef);
  
  const handleRenderSuccess = (e) => {
    editorStore.handlePageLoaded(e.pageNumber, e.originalWidth, e.originalHeight)
  }
  
  return (
    <Grid
      item container
      className="w-full h-full overflow-hidden"
    >
      <div
        ref={handleContainerRef}
        onScroll={handleScroll}
        className="w-full h-full overflow-auto overflow-anchor"
      >
        {/*<ResizeObserver onResize={handleRescale} />*/}
        <Grid
          item
          container
          // justifyContent="flex-end"
          alignItems="center"
          direction="column"
          ref={editorStore.setContainerRef}
          wrap="nowrap"
          className={classes.container}
        >
          {
            pdfDocument
              ? (
                <Document
                  file={ pdfDocument.file.path }
                  onLoadSuccess={onDocumentLoad}
                >
                  {
                    headerPages.map((p) => (
                      <Page
                        key={`page${p}`}
                        pageNumber={p}
                        className={classes.page}
                        scale={scale}
                        onRenderSuccess={handleRenderSuccess}
                      >
                        {
                          showPageImages &&
                          <img
                            className={classes.image}
                            src={editorStore.getPageImageUrl(p)}
                            draggable={false}
                            alt=""
                          />
                        }
                      </Page>
                    ))
                  }
                  {children}
                  {footerPages.map((p) => {
                    return (
                      <Page
                        key={`page${p}`}
                        pageNumber={p}
                        className={classes.page}
                        scale={scale}
                        onRenderSuccess={handleRenderSuccess}
                      >
                        {
                          showPageImages &&
                          <img
                            className={classes.image}
                            src={editorStore.getPageImageUrl(p)}
                            draggable={false}
                            alt=""
                          />
                        }
                      </Page>
                    );
                  })}
                </Document>
              )
              : children
          }
        </Grid>
      </div>
    </Grid>
  );
});

ViewBox.propTypes = {
  showPageImages: bool,
  children: node,
};
