import { APIMethod, fetchData } from "@client";

export async function addProjectPdfCoverQuery(
  projectUuid,
  s3etag,
  filename,
  s3path,
  usedPages,
  proposalIndex = -1,
  scale = undefined
) {
  const data = {
    uuid: s3etag,
    name: filename,
    path: s3path,
    scale,
    usedPages,
    tablePos: proposalIndex,
  };
  const res = await fetchData(
    `/v1/project/cover/${projectUuid}`,
    APIMethod.POST,
    { data, mapRequestKeysToSnakeCase: false }
  );
  return res.results;
}
