import { SvgIcon } from "@material-ui/core";

export const ModuleArrow = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20" style={{ fill: "none" }}>
    <path
      d="M13.75 8.125L17.5 11.875L13.75 15.625"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 4.375C2.5 6.36412 3.29018 8.27178 4.6967 9.6783C6.10322 11.0848 8.01088 11.875 10 11.875L17.5 11.875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
