import { bool, string, node, arrayOf, oneOfType } from "prop-types";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import useStyle from "./PageTitle.style";
import { useEffect } from "react";

export const PageTitle = ({
  title,
  titleTag,
  translate,
  children,
  hideTitle,
  pinned,
  small,
  // showBoardName,
  center,
}) => {
  const classes = useStyle(isMobile);
  const location = useLocation();
  const { t } = useTranslation();

  const pathnames = location.pathname.split("/").filter((x) => x);
  const current = pathnames.slice(-1);

  useEffect(() => {
    document.title = `Apropo App | ${
      title ? (translate ? t(`routes.${title}`) : title) : t(`routes.${current}`)
    }`;
  }, [])

  // let name = null;
  // if (showBoardName && userStore.board)
  //   name = userStore.board.name;

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
      style={pinned ? { top: 0 } : undefined}
    >
      <Grid
        item
        container
        justifyContent={center ? "center" : "space-between"}
        alignItems="center"
        wrap="nowrap"
        className={classnames({
          [classes.container]: true,
          [classes.containerSmall]: small,
        })}
      >
        {!hideTitle && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            className={classes.titleContainer}
          >
            <Grid item container alignItems="center">
              {!title || typeof title === "string" ? (
                <h1>
                  {title
                    ? translate
                      ? t(`routes.${title}`)
                      : title
                    : t(`routes.${current}`)}
                </h1>
              ) : (
                title
              )}
              {titleTag}
            </Grid>
            {/*{Boolean(name) && (*/}
            {/*  <p className={classes.boardName} color="primary">*/}
            {/*    {name}*/}
            {/*  </p>*/}
            {/*)}*/}
          </Grid>
        )}
        {children}
      </Grid>
    </Grid>
  );
};

PageTitle.propTypes = {
  title: string,
  titleTag: node,
  translate: bool,
  children: oneOfType([arrayOf(node), node]),
  hideTitle: bool,
  pinned: bool,
  small: bool,
  // showBoardName: bool,
  center: bool,
};
