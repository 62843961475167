import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";

export async function createNewProjectQuery(
  userUuid,
  workspaceUuid,
  companyId,
  workTypes,
  name,
  description,
  status,
  templateId,
  xlsStructure,
  createNewTemplate,
) {
  const res = await fetchData("/api/project/create", APIMethod.POST, {
    baseURL: ACCESS_DOMAINS.editor,
    isJSONData: true,
    data: {
      workspaceUuid,
      userUuid,
      companyId,
      name,
      description,
      workTypes,
      status,
      templateId,
      xlsStructure,
      createNewTemplate,
    }
  });

  return res.projectUuid;
}