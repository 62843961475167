import { useEffect } from "react";
import { arrayOf, object, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { Button, ControlledTextField, InfoLabel } from "@components";
import { Grid } from "@material-ui/core";
import { TagSelect } from "./TagSelect";
import { Trash } from "@assets";

export const Rules = ({
  control,
  labels,
}) => {
  const { t } = useTranslation();
  
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "rules",
  });
  
  useEffect(() => {
    if(labels)
      addInput();
  }, [])
  
  const addInput = () => {
    append({ name: "", selection: null })
  }
  
  return (
    <>
      {
        Boolean(labels?.length) &&
        <InfoLabel spacing={8} label="views.bt.import_tickets" />
      }
      {fields.map((field, index) => (
        <Grid
          key={field.id}
          item
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <InfoLabel spacing={0} label="views.bt.import_tickets_tag" />
          </Grid>
          <Grid item>
            {!!labels && (
              <TagSelect
                control={control}
                labels={labels}
                name={`rules.${index}.selection`}
              />
            )}
          </Grid>
          <Grid item>
            <InfoLabel spacing={0} label="as" />
          </Grid>
          <Grid item>
            <ControlledTextField
              control={control}
              placeholder={t("views.bt.enter_select_name")}
              className="m-2 child-h-9"
              name={`rules.${index}.name`}
            />
          </Grid>
          <Grid item>
            {fields.length > 1 && (
              <Button type="button" onClick={() => remove(index)}>
                <Trash color="error" />
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
      {
        Boolean(labels?.length) && fields.length < 10 &&
        <Button type="button" onClick={addInput}>
          {t("views.bt.add_import_rule")}
        </Button>
      }
    </>
  );
};

Rules.propTypes = {
  control: object,
  labels: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired
};
