import { useEffect, useMemo } from "react";
import { bool } from "prop-types";
import {
  useStructureStore,
  usePresetFieldNames,
  useTableOverflowChecker,
  useTableExpandHandler, useProjectEditorStore, useVisibilityModeCellClassName,
} from "@hooks";
import ResizeObserver from "react-resize-observer";
import { observer } from "mobx-react";
import { Tables, hashCode } from "project-structure";
import { isMobile } from "react-device-detect";
import { TimelineContentSettings } from "@dialogs";
import { ButtonSwitch, ScrollButtonContainer, TableTitle, VisibilityButton } from "@components";
import { TableContainer, Grid, Collapse } from "@material-ui/core";
import { TableHeader } from "./components";
import { TimelineBilling, TimelineSections, TimelineTeamAllocation } from "./sections";
import useStyle from "../commonStyles.style";
import classnames from "classnames";

export const TableTimeline = observer(({
  hasEditorPrivileges,
  hasSellerPrivileges,
  useClientActions,
  isProposal,
  readOnly,
  tableBreakdownNext,
  tableBreakdownPrev,
  presetEditionMode,
  forceAllowExpansions,
}) => {
  
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const classes = useStyle();

  const {
    settings,
    visibility,
    usesTwoValues,
    usesAnyValue,
    totalDevelopmentTime,
    usedTimelineMode
  } = structure;
  const {
    showTimeline,
    useMinMax,
    useTimelineSections,
    useTimelineAllocation,
    useTimelineBilling,
    expandedMonthList,
  } = settings;
  const {
    apply,
    hideTimeline,
    hideTimelineSections: vHideTimelineSections,
    hideTimelineAllocation: vHideTimelineAllocation,
    hideTimelineBilling: vHideTimelineBilling,
  } = visibility;
  
  const className = useVisibilityModeCellClassName(hideTimeline || !usesAnyValue);

  const allowEdition = useMemo(
    () => !readOnly && hasEditorPrivileges,
    [readOnly, hasEditorPrivileges]
  );
  const blockExpansions = useMemo(
    () => readOnly && !useClientActions && !forceAllowExpansions,
    [readOnly, useClientActions, forceAllowExpansions]
  );
  const isSellerOrClient = useMemo(
    () => isProposal || hasSellerPrivileges,
    [isProposal, hasSellerPrivileges]
  );
  const isEditorOrClient = useMemo(
    () => isProposal || hasEditorPrivileges,
    [isProposal, hasEditorPrivileges]
  );
  const timelineModeValues = useMemo(
    () => [
      {
        value: "min",
        label: getFieldName("pt-min"),
        className: classnames(
          "pt-min",
          `preset-timelineMode${usedTimelineMode === "min" ? "Active" : ""}`
        ),
      },
      {
        value: "max",
        label: getFieldName("pt-max"),
        className: classnames(
          "pt-max",
          `preset-timelineMode${usedTimelineMode === "max" ? "Active" : ""}`
        ),
      },
    ],
    [usedTimelineMode, getFieldName("pt-max"), getFieldName("pt-min")]
  );

  const tableContainerIdentifier = "timelineContainer";
  const tableIdentifier = "timelineHeader";

  const {
    ref,
    onResize,
    onScroll,
    isTableContentOverflowing,
    scrollButtonProps
  } = useTableOverflowChecker(tableIdentifier);
  
  useEffect(() => {
    onResize()
  }, [ useMinMax, expandedMonthList, totalDevelopmentTime ]);

  const handleTimelineMode = (mode) => {
    if (!useClientActions) return;
    structure.historyManager.startGroup();
    settings.setTimelineMode(mode);
    structure.generateTimeline();
    structure.historyManager.stopGroup();
  };
  
  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    !blockExpansions,
    showTimeline,
    settings.setShowTimeline
  );
  
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      className={classnames(
        "preset-paperTimeline",
        classes.whiteContainer,
        tableBreakdownNext && classes.whiteContainerLast,
        tableBreakdownPrev && classes.whiteContainerFirst,
      )}
    >
      <Grid item container justifyContent="flex-end" className="vCon">
        <TableTitle
          disabled={visibilityMode && (hideTimeline || !usesAnyValue)}
          expandable={!blockExpansions}
          presetClass="timeline"
          buttonPresetClass="preset-tableTimelineExpandButton"
          id={hashCode(Tables.TIMELINE)}
          title={getFieldName("pt-timeline")}
          showContent={showTimeline}
          onContentAction={handleOpen}
          presetEditionMode={presetEditionMode}
          action={<>
            {!readOnly && <TimelineContentSettings />}
            { visibilityMode &&
              <VisibilityButton hidden={hideTimeline} onChange={visibility.setHideTimeline} />
            }
          </>}
        >
          {
            (allowEdition || useMinMax) &&
            <Grid
              item
              container
              alignItems="center"
              className="w-max my-1"
            >
              {
                usesTwoValues &&
                <ButtonSwitch
                  value={usedTimelineMode}
                  setValue={handleTimelineMode}
                  values={timelineModeValues}
                  containerClassName={classes.buttonSwitch}
                  size="small"
                />
              }
            </Grid>
          }
        </TableTitle>
        <Collapse
          in={showTimeline}
          timeout="auto"
          mountOnEnter
          unmountOnExit
          className={classnames(classes.collapse, className)}
          data-id={hashCode("cont")}
          data-t="timeline"
          data-p={hashCode(Tables.TIMELINE)}
          { ...collapseProps }
        >
          <Grid
            container
            justifyContent="center"
            wrap="nowrap"
            direction="column"
          >
            {
              isTableContentOverflowing && !isMobile &&
              <ScrollButtonContainer {...scrollButtonProps} />
            }
            <TableContainer
              ref={ref}
              id={tableContainerIdentifier}
              onScroll={onScroll}
              className={classnames(
                "pb-3",
                classes.tableContainer,
              )}
            >
              <Grid
                container
                role="rowgroup"
                direction="column"
              >
                <ResizeObserver onResize={onResize} />
                <TableHeader
                  isProposal={isProposal}
                  tableIdentifier={tableIdentifier}
                  blockExpansions={blockExpansions}
                  allowEdition={allowEdition}
                  useClientActions={useClientActions}
                />
                {
                  useTimelineSections && (!apply || visibilityMode || !vHideTimelineSections) &&
                  <TimelineSections
                    isProposal={isProposal}
                    allowEdition={allowEdition}
                    useClientActions={useClientActions}
                    tableContainerIdentifier={tableContainerIdentifier}
                    hasEditorPrivileges={hasEditorPrivileges}
                  />
                }
                {
                  useTimelineAllocation && (!apply || visibilityMode || !vHideTimelineAllocation) &&
                  <TimelineTeamAllocation
                    isProposal={isProposal}
                    allowEdition={allowEdition}
                    useClientActions={useClientActions}
                    isSellerOrClient={isSellerOrClient}
                    isEditorOrClient={isEditorOrClient}
                    blockExpansions={blockExpansions}
                    hasEditorPrivileges={hasEditorPrivileges}
                  />
                }
                {
                  isSellerOrClient && useTimelineBilling && (!apply || visibilityMode || !vHideTimelineBilling) &&
                  <TimelineBilling
                    isProposal={isProposal}
                    useClientActions={useClientActions}
                    hasEditorPrivileges={hasEditorPrivileges}
                  />
                }
              </Grid>
            </TableContainer>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
});

TableTimeline.propTypes = {
  hasEditorPrivileges: bool,
  hasSellerPrivileges: bool,
  isProposal: bool,
  readOnly: bool,
  useClientActions: bool,
  tableBreakdownNext: bool,
  tableBreakdownPrev: bool,
  presetEditionMode: bool,
  forceAllowExpansions: bool,
};
