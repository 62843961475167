import { SvgIcon } from "@material-ui/core";

export const Monitor = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 19V20H7V22H17V20H15V19H21C22.1046 19 23 18.1046 23 17V4C23 2.89543 22.1046 2 21 2H3C1.89543 2 1 2.89543 1 4V17C1 18.1046 1.89543 19 3 19H9ZM21 17H3V4H21V17Z"
      fill="currentColor"
    />
  </SvgIcon>
);
