import { arrayOf, object, shape, string } from "prop-types";
import { Controller } from "react-hook-form";
import { Autocomplete } from "@components";

export const UsersSelect = ({
  control,
  name,
  users,
  label,
  selectedUsers
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value, name } }) => (
      <Autocomplete
        value={value}
        options={users}
        name={name}
        idKey="id"
        labelKey="name"
        inputLabel={label}
        fullWidth
        onChange={onChange}
        size="small"
        // style={{width: 192}}
        fieldClassName="m-0"
        getOptionDisabled={
          selectedUsers && (
            (option) => {
              return (
                selectedUsers.includes(option.id) && value !== option.id
              )
            }
          )
        }
      />
    )}
  />
)

UsersSelect.propTypes = {
  control: object.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  selectedUsers: arrayOf(string),
  users: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired
}