import { bool, string, node, arrayOf, oneOfType } from "prop-types";
import { observer } from "mobx-react";
import { useEditorWebsocket, useProjectEditorStore } from "@hooks";
import Grid from "@material-ui/core/Grid";
import { Sidebar, SortableList } from "@components";
import { PdfPageThumbnail } from "../index";
import useStyle from "./ThumbnailList.style";
import { PROPOSAL_THUMB_ID } from "@utils";

export const ThumbnailList = observer(({
  visible,
  noRescale,
  readOnly,
  actions,
  containerId,
  showPageImages
}) => {
  
  const editorStore = useProjectEditorStore();
  const socket = useEditorWebsocket();
  const classes = useStyle();

  const { pdfDocument, pdfPageThumbnails, pdfPagesLoaded } = editorStore;

  const onListReorderEnd = (l) => {
    if(!pdfPagesLoaded) return;
    const { usedPages, tablePos } = editorStore.reorderPages(l);
    socket?.updateCoverPages(pdfDocument.id, usedPages, tablePos);
  };

  return (
    <Sidebar
      noRescale={noRescale}
      noBorder
      actions={actions}
      containerId={containerId}
      visible={visible}
    >
      <Grid
        item container
        className="px-6 py-2 h-full overflow-auto"
        wrap="nowrap"
        ref={(r) => editorStore.setThumbViewRef(r?._container)}
      >
        <SortableList
          list={pdfPageThumbnails}
          group="pageThumbnails"
          onListReorder={onListReorderEnd}
          disabled={readOnly}
          className={classes.sortable}
          forceFallback
        >
          {pdfPageThumbnails?.map((page, i) => (
            <PdfPageThumbnail
              key={"thumb" + (i + 1)}
              page={page}
              pageIndex={i}
              isRemovable={!readOnly && page.id !== PROPOSAL_THUMB_ID}
              showPageImages={showPageImages}
            />
          ))}
        </SortableList>
      </Grid>
    </Sidebar>
  );
});

ThumbnailList.propTypes = {
  noRescale: bool,
  readOnly: bool,
  showPageImages: bool,
  containerId: string,
  actions: oneOfType([
    arrayOf(node),
    node,
  ]),
};
