import { forwardRef, useMemo } from "react";
import { bool, string, object, node, func, oneOf, oneOfType } from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Button as MuiButton, Link, Tooltip } from "@material-ui/core";
import useStyle from "./CommonButtons.style";
import classnames from "classnames";

export const Button = forwardRef(( {
  icon,
  children,
  to,
  href,
  className,
  tooltip,
  isSubmit,
  tooltipText,
  name,
  endIcon,
  size="medium",
  square,
  disabled,
  color="primary",
  ...muiButtonProps
}, ref) => {

  const classes = useStyle();
  const location = useLocation();

  const component = useMemo(
    () => (href ? Link : to ? RouterLink : undefined),
    [href, to]
  );

  const buttonComponent = (
    <MuiButton
      ref={ref}
      type={isSubmit ? "submit" : "button"}
      className={classnames(
        !children && classes.noTextIcon,
        square && classes["buttonSquare-"+size],
        className
      )}
      component={component}
      to={to}
      state={to ? { from: location.pathname } : undefined}
      href={href}
      name={name || (typeof children === "string" ? children : "")}
      aria-label={name || (typeof children === "string" ? children : "")}
      endIcon={endIcon ? endIcon : null}
      startIcon={!endIcon ? icon : null}
      size={size}
      disabled={disabled}
      color={color}
      {...muiButtonProps}
    >
      {children}
    </MuiButton>
  );

  return ((tooltip && name) || tooltipText) && !disabled ? (
    <Tooltip title={tooltipText || name}>{buttonComponent}</Tooltip>
  ) : (
    buttonComponent
  );
});

Button.propTypes = {
  children: oneOfType([
    string,
    object,
    node,
  ]),
  icon: node,
  endIcon: node,
  onClick: func,
  to: string,
  href: string,
  variant: oneOf(["text", "outlined", "contained"]),
  style: object,
  className: string,
  disabled: bool,
  isSubmit: bool,
  square: bool,
  name: string,
  color: oneOf(["default", "inherit", "primary", "secondary"]),
  size: oneOf(["small", "medium", "large"]),
  tooltip: bool,
  tooltipText: string,
};
