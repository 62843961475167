// import { format } from "date-fns";
// import { APIMethod, fetchData } from "@client";

export async function sendBrokenStructureFeedbackFileQuery(structure) {
  const data = new window.Blob([structure], {
    type: "text/plain;charset=utf-8",
  });

  ({ data, contentType: "text/plain" });
  /** @todo */
  // const Key = `${this.rootStore.userStore.board.uuid}/${this.rootStore.projectUuid}/_broken_structures/${format(new Date, "yyyy_MM_dd_HH_mm_ss")}.json`;
  // const upload = this.rootStore.s3.upload({...this.rootStore.s3Params, Key, ContentType, Body});
  // const res = await upload.promise();
  // await fetchData(
  //   "/feedback",
  //   APIMethod.POST,
  //   { data: { data: res.Location } }
  // );
}
