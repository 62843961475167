import { memo, useEffect, useState } from "react";
import { arrayOf, bool, func, node, object, oneOf, string } from "prop-types";
import { useResizeObserver } from "@hooks";
import { quillBlocksRegex, textOverflows, trimEmptyRows } from "@utils";
import ReactQuill from "react-quill";
import Mention from "quill-mention";
import MagicUrl from "quill-magic-url";
import Grid from "@material-ui/core/Grid";
import { ReadOnlyContent } from "./components/ReadOnlyContent/ReadOnlyContent";
import { EditableContent } from "./components/EditableContent/EditableContent";
import classnames from "classnames";
import useStyle from "./WysiwygEditor.style";
import "react-quill/dist/quill.core.css";

ReactQuill.Quill.register("modules/magicUrl", MagicUrl);
ReactQuill.Quill.register("modules/mentions", Mention);

export const WysiwygEditor = memo(({
  value: defaultValue,
  name,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  noEmptyHtmlStrings,
  changeOnClickAway,
  changeOnLooseForcedFocus,
  className,
  moreButtonClassName,
  style,
  readOnly,
  blockContentButton,
  autoFocus,
  forcedFocus,
  toolbarVariant="inline",
  children,
  shorten,
  showPlaceholderOnBlurred,
  useBorder,
  showMore,
  onShowMore,
  editorMentionUsers,
  editorMentionSearchKeys,
  toolbarPlacement,
}) => {
  
  const classes = useStyle();
  
  const [ value, setValue ] = useState(defaultValue || "");
  const [ mentions, setMentions ] = useState(defaultValue || "");
  const [ focused, setFocused] = useState(autoFocus && !readOnly);
  const [ selection, setSelection] = useState(0);
  const [ showFull, setShowFull] = useState(!shorten || showMore);
  const [ overflows, setOverflows] = useState(!shorten);
  
  const containerRef = useResizeObserver(
    (el) => setOverflows(textOverflows(el.clientWidth, value, 14))
  );
  
  useEffect(() => {
    setShowFull(!shorten || showMore);
  }, [showMore, shorten])
  
  useEffect(() => {
    if(!defaultValue) {
      setValue(""); return;
    }
    setValue(defaultValue.match(quillBlocksRegex) ? defaultValue : `<p>${defaultValue}</p>`);
  }, [defaultValue]);
  
  useEffect(() => {
    if(typeof forcedFocus === "boolean") {
      if ( !forcedFocus && focused && changeOnLooseForcedFocus )
        commitChange(value);
      setFocused(forcedFocus);
    }
  }, [forcedFocus]);
  
  const commitChange = (val, localMentions) => {
    if(noEmptyHtmlStrings)
      val = trimEmptyRows(val.trim());
    
    val = val.replace(/<a href=/g, '<a target="_blank" href=')
    const isDifferent = Boolean(val.length && defaultValue?.toString() !== val);
    onChange(val, isDifferent, localMentions || mentions);
  }
  
  const handleChange = (val, mentions) => {
    setValue(val);
    setMentions(mentions)
    if(changeOnClickAway || changeOnLooseForcedFocus)
      return;
    commitChange(val, mentions);
  }
  
  const handleFocus = (s) => {
    setFocused(true);
    setSelection(s.index)
    onFocus && onFocus();
  }
  
  const handleBlur = () => {
    changeOnClickAway && commitChange(value);
    setFocused(false);
    setShowFull(!shorten);
    setSelection(0);
    onBlur && onBlur();
  }
  
  const handleShowMore = () => {
    onShowMore && onShowMore(!showFull);
    setShowFull(!showFull);
  }
  
  return (
    <Grid
      item container
      wrap="nowrap"
      style={style}
      direction={showFull ? "column" : "row"}
      alignItems={showFull ? "flex-end" : "center"}
      className={classnames(
        "noDrag",
        classes.root,
        className
      )}
      ref={containerRef}
    >
      {
        (
          focused
            ? <EditableContent
              name={name}
              value={value}
              onBlur={handleBlur}
              onChange={handleChange}
              popupAnchor={containerRef.current}
              toolbarVariant={toolbarVariant}
              selection={selection}
              useBorder={useBorder}
              editorMentionUsers={editorMentionUsers}
              editorMentionSearchKeys={editorMentionSearchKeys}
              placeholder={placeholder}
              toolbarPlacement={toolbarPlacement}
            >
              {children}
            </EditableContent>
            : <ReadOnlyContent
              shorten={shorten}
              showFull={showFull}
              setShowFull={setShowFull}
              overflows={overflows}
              readOnly={readOnly}
              value={value}
              placeholder={placeholder}
              onFocus={handleFocus}
              onShowMore={handleShowMore}
              blockContentButton={blockContentButton}
              showPlaceholder={showPlaceholderOnBlurred}
              moreButtonClassName={moreButtonClassName}
              useBorder={useBorder}
            />
          )
      }
    </Grid>
  )
});

WysiwygEditor.propTypes = {
  value: string,
  name: string.isRequired,
  onChange: func.isRequired,
  onFocus: func,
  onBlur: func,
  placeholder: string,
  readOnly: bool,
  blockContentButton: bool,
  autoFocus: bool,
  changeOnClickAway: bool,
  changeOnLooseForcedFocus: bool,
  noEmptyHtmlStrings: bool,
  toolbarVariant: oneOf(["none", "inline", "popup"]),
  className: string,
  moreButtonClassName: string,
  style: object,
  children: node, // Toolbar children
  shorten: bool,
  showPlaceholderOnBlurred: bool,
  forcedFocus: bool,
  useBorder: bool,
  showMore: bool,
  onShowMore: func,
  editorMentionUsers: arrayOf(object),
  editorMentionSearchKeys: arrayOf(string),
  toolbarPlacement: oneOf(["top", "bottom"]),
}

const icons = ReactQuill.Quill.import("ui/icons");
icons.bold = null;
icons.italic = null;
icons.underline = null;
icons.code = null;
icons["code-block"] = null;
icons.blockquote = null;
icons.strike = null;
icons.underline = null;
icons.link = null;
icons.header = {
  1: null,
  2: null,
};
icons.list = {
  ordered: null,
  bullet: null,
};
