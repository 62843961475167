import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  root: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    marginLeft: -32,
    marginTop: -32,
    width: 64
  },
}));

export default useStyle;
