import { createRoot } from "react-dom/client";
import { Buffer } from "buffer";
import * as Sentry from "@sentry/react";
import { AppStoreProvider } from "@stores";
import { WorkspaceSocketProvider } from "@client";
import "@locale/i18n";
import App from "./App";
import { UnathorizedFrameWarning } from "./UnathorizedFrameWarning";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "@client";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import "@styles/index.css";

window.Buffer = Buffer;

setupLogRocketReact(LogRocket);
const msalInstance = new PublicClientApplication(msalConfig);

if (process.env.REACT_APP_SENTRY_INGEST) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_INGEST,
    tracesSampleRate: 1.0,
  });
}

const root = createRoot(document.getElementById("root"));
root.render(
  self == top ||
    top.location.href.indexOf("https://app.logrocket.com/") === 0
    ? <MsalProvider instance={msalInstance}>
      <AppStoreProvider>
        <WorkspaceSocketProvider>
          <App />
        </WorkspaceSocketProvider>
      </AppStoreProvider>
    </MsalProvider>
    : <UnathorizedFrameWarning />
);