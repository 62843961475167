import { SvgIcon } from "@material-ui/core";

export const FileAdd = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4142 1H5C3.89543 1 3 1.89543 3 3V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V6.58579L15.4142 1ZM5 3H13V7C13 8.10457 13.8954 9 15 9H19V21H5V3ZM15 7V3.41421L18.5858 7H15ZM11 10V13H8V15H11V18H13V15H16V13H13V10H11Z"
      fill="currentColor"
    />
  </SvgIcon>
);