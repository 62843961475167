import { arrayOf, bool, number, object, shape, string } from "prop-types";
import { useStructureStore } from "@hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { CELL_WIDTHS, ELEMENT_TYPES } from "@utils";
import { Row, RowGroup, TitleCell, WorkTypeResourceSelect } from "@components";
import { SummaryNestedRowActions } from "./components/SummaryNestedRowActions";
import { ColumnFactory } from "./ColumnFactory";
import { Tables } from "project-structure";
import classnames from "classnames";

export const SummaryNestedRow = observer(({
  resource,
  parentIds,
  isSellerOrClient,
  isEditorOrClient,
  useClientActions,
  allowEdition,
  isProposal,
  isLastGroupRow,
  columns,
  maxTitleCellWidth,
  useProfitability,
  turnOff,
  noTurningOff,
}) => {
  const { t } = useTranslation();
  const { workTypesHaveResources } = useStructureStore();

  const { id, name } = resource;

  const tableDepth = 2;
  
  return (
    <RowGroup
      originTableId={Tables.SUMMARY}
      parentIds={parentIds}
      tableDepth={tableDepth}
      isStatic={!allowEdition}
      elementId={id}
      elementType={ELEMENT_TYPES.RESOURCE}
    >
      <Row
        presetClass="preset-rowSummary"
        useExpandCell={!isProposal || workTypesHaveResources}
        useVisibilityCell={useClientActions && !noTurningOff}
        hideVisibilitySwitch
        isLastGroupRow={isLastGroupRow}
        tableDepth={tableDepth}
        elementId={id}
        elementType="r"
        visible={!turnOff}
      >
        <TitleCell
          minWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : CELL_WIDTHS.TITLE_SUMMARY}
          maxWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : maxTitleCellWidth}
          pinnedLeft={!isProposal || workTypesHaveResources ? 2 : 0}
          allowOverflowDisplay
          actions={
            !useProfitability &&
            <SummaryNestedRowActions
              resource={resource}
              allowEdition={allowEdition}
              showComments={isEditorOrClient && useClientActions}
            />
          }
        >
          {allowEdition ? (
            <WorkTypeResourceSelect resource={resource} />
          ) : (
            <span
              className={classnames(
                "name",
                "preset-summaryTitleText",
                !name?.length && "semi-transparent"
              )}
            >
              {name || t("common.unnamed")}
            </span>
          )}
        </TitleCell>
        {
          columns.map(({ name }) => (
            <ColumnFactory
              key={name}
              name={name}
              element={resource}
              allowEdition={allowEdition}
              isProposal={isProposal}
              isSellerOrClient={isSellerOrClient}
              useClientActions={useClientActions}
              profitabilityMode={useProfitability}
            />
          ))
        }
      </Row>
    </RowGroup>
  );
});

SummaryNestedRow.propTypes = {
  resource: object.isRequired,
  isSellerOrClient: bool,
  isEditorOrClient: bool,
  isLastGroupRow: bool,
  isProposal: bool,
  turnOff: bool,
  useClientActions: bool,
  allowEdition: bool,
  useProfitability: bool,
  columns: arrayOf(shape({
    name: string.isRequired
  })).isRequired,
  maxTitleCellWidth: number
};
