import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore, useTimelineBarChartWidth } from "@hooks";
import { useDraggable } from "@dnd-kit/core";
import { Tooltip } from "@material-ui/core";
import { TimelineSectionBarTooltip } from "./TimelineSectionBarTooltipContent";
import useStyle from "./TimelineSectionBar.style"
import classnames from "classnames";
import { useMemo } from "react";
import { roundFloat } from "project-structure";


export const TimelineSectionBar =  observer(({
  section,
  disabled,
  index,
  isDraggable,
  isPlaceholder,
}) => {
  const classes = useStyle();
  
  const structure = useStructureStore();
  const { workTypes, settings } = structure;
  const { timelineHoursKey, modifier, usedRoundHours, usedRoundDecimals } = settings;
  
  const {
    id,
    name,
    timelineStartWeek,
    timelineStartMonth,
    timelineEndWeek,
    timelineEndMonth,
    workTypes: sectionWorkTypes,
    taskWorkTypes,
    taskValues,
  } = section;
  
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
  } = useDraggable({
    id,
    disabled,
    attributes: {
      tabIndex: index,
      role: "timeline section"
    }
  });
  
  const tooltipValues = useMemo(() => (
    workTypes
      .filter(wT => (
        sectionWorkTypes.find(wT => wT.id === wT.id) && !wT.areHoursOverwritten
      ))
      .map(wT => ({
        name:  wT.name,
        hours: roundFloat((section.getTotalHoursPerWorkType(wT.id)?.[timelineHoursKey] || 0) / modifier, usedRoundHours, false, usedRoundDecimals)
      }))
  ), [workTypes, sectionWorkTypes, taskWorkTypes, taskValues, timelineHoursKey, modifier, usedRoundHours, usedRoundDecimals]);
  
  const { width, offsetLeft, offsetRight, exceeds } = useTimelineBarChartWidth({
    isDraggable,
    startWeek: timelineStartWeek,
    startMonth: timelineStartMonth,
    endWeek: timelineEndWeek,
    endMonth: timelineEndMonth,
  })
  
  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={{
        width,
        "--translate-x": `${transform?.x ?? 0}px`,
        "--translate-y": `${transform?.y ?? 0}px`,
        paddingLeft: offsetLeft || undefined,
        paddingRight: offsetRight || undefined,
      }}
      className={classnames(classes.container, isPlaceholder && "semi-transparent", exceeds && !isPlaceholder && classes.containerExceeds)}
    >
      <Tooltip
        placement="top"
        title={(
          <TimelineSectionBarTooltip
            name={name}
            values={tooltipValues}
          />
        )}
      >
        <div className={classnames(classes.block, exceeds && !isPlaceholder && classes.exceeds)}>
          {name}
        </div>
      </Tooltip>
    </div>
  );
});

TimelineSectionBar.propTypes = {
  index: number.isRequired,
  section: object.isRequired,
  disabled: bool,
  isDraggable: bool,
  isPlaceholder: bool,
};