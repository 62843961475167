import { forwardRef } from "react";
import { bool, func, string } from "prop-types";
import { Check, ErrorOutline } from "@material-ui/icons";
import { IconButton, InputBase } from "@material-ui/core";
import classnames from "classnames";
import useStyle from "../TextClearInput.style";

export const Input = forwardRef(({
  value,
  placeholder,
  error,
  minimal,
  confirmOnEnter,
  handleAccept,
  handleChange,
  handleClick,
  multiline,
  className,
}, ref) => {
  
  const classes = useStyle();
  
  const checkKey = (e) => {
    if (confirmOnEnter && e.keyCode === 13 && !error)
      handleAccept();
  };
  
  return (
    <InputBase
      minRows={1}
      multiline={multiline}
      inputProps={{
        className: classnames(
          classes.input,
          classes.inheritFont,
          multiline && classes.inputMultiline,
          className,
        ),
        ref,
      }}
      className={classnames(
        classes.inputBase,
        classes.inheritFont,
        multiline && classes.inputBaseMultiline,
        "w-full"
      )}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onFocus={focus}
      autoFocus
      error={error}
      onKeyDown={checkKey}
      onClick={handleClick}
      endAdornment={
        minimal ? undefined : error ? (
          <ErrorOutline
            color="error"
            className="text-2xl -mx-0-5"
          />
        ) : (
          <IconButton
            onClick={handleAccept}
            className="p-0 -mx-0-5 text-xl"
            color="primary"
          >
            <Check />
          </IconButton>
        )
      }
    />
  )
})

Input.propTypes = {
  value: string,
  placeholder: string,
  className: string,
  error: bool,
  minimal: bool,
  confirmOnEnter: bool,
  multiline: bool,
  handleChange: func.isRequired,
  handleClick: func.isRequired,
  handleAccept: func.isRequired
}