import { bool, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { useStores } from "@hooks";
import { observer } from "mobx-react";
import { isMobile } from "react-device-detect";
import { Grid, Toolbar, AppBar, Hidden } from "@material-ui/core";
import { ClearButton, Button } from "@components";
import { MenuUser, MenuNotifications, TrialInfo } from "./components";
import { ApropoLogo, File, Info, LibraryIcon } from "@assets";
import { home, templates, help, library } from "@paths";
import useStyle from "./AppToolBar.style";

export const AppToolBar = observer(({
  noActions,
  iconLink=home
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { userStore } = useStores();

  return (
    <AppBar position="static" role="banner" className="relative">
      <Toolbar
        style={{ justifyContent: "space-between" }}
        variant={isMobile ? "dense" : "regular"}
        className={isMobile ? "px-2" : ""}
      >
        <Grid
          item
          container
          alignItems="center"
          className="w-max"
        >
          <Button
            className="rounded-2"
            to={iconLink}
            aria-label={t("routes.home")}
            variant="text"
          >
            <ApropoLogo />
          </Button>
        </Grid>
        {!noActions && (
          <>
            <Grid item container alignItems="center" className="w-max">
              <Hidden smDown>
                <TrialInfo />
                {!userStore.isMiniUser && (
                  <ClearButton
                    to={library}
                    className={classes.actionButton}
                    icon={<LibraryIcon />}
                  >
                    {t("routes.library")}
                  </ClearButton>
                )}
                {!userStore.isMiniUser && (
                  <ClearButton
                    to={templates}
                    className={classes.actionButton}
                    icon={<File />}
                  >
                    {t("routes.templates")}
                  </ClearButton>
                )}
              </Hidden>
              <Hidden xsDown>
                <ClearButton
                  to={help}
                  icon={<Info />}
                  className={classes.actionButton}
                >
                  {t("routes.help")}
                </ClearButton>
              </Hidden>
              <MenuNotifications />
              <MenuUser />
            </Grid>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
});

AppToolBar.propTypes = {
  noActions: bool,
  iconLink: string,
};
