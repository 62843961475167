import { useEffect, useState } from "react";
import {
  getCurrencies,
  generateEntryId,
  generateSectionId,
  structureParser,
  DEFAULT_PROJECT_SETTINGS,
  DEFAULT_VERSION_INFO,
} from "project-structure";
import { createCustomWorkTypeQuery } from "@query";
import { useProjectCreatorStore } from "@hooks";
import { TemplatePreview } from "@tools";
import { CircularProgress, Grid } from "@material-ui/core";
import useStyle from "./Steps.style";

export const Preview = () => {
  const classes = useStyle();
  const creator = useProjectCreatorStore();

  const [isLoading, setLoading] = useState(true);

  const {
    importWorkTypes,
    xlsProjectStructure,
    currentBreakdownData,
    importModules,
    useMinMax,
    importTimeModifier,
    descColumn,
  } = creator;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const tableData = currentBreakdownData.sheet.data;

      const m = importModules.sort((a, b) => (a.y > b.y ? 1 : -1));

      const sections = m.filter(({ type }) => type === "section");
      let modules = m.filter(({ type }) => type === "module");
      let features = m.filter(({ type }) => type === "feature");
      let tasks = m.filter(({ type }) => type === "task");

      if (!sections.length) sections.push({ name: "Section 1", x: -1, y: -1 });
      if (!modules.length && features.length) {
        modules = [...features];
        features = [];
      }
      if (!modules.length && !features.length && tasks.length) {
        modules = [...tasks];
        tasks = [];
      }
      if (!features.length && tasks.length) {
        features = [...tasks];
        tasks = [];
      }

      // create systems without ids
      // const createdSystemNames = []; // to avoid creating duplicates
      if (importWorkTypes.length)
        await Promise.all(
          importWorkTypes.map(async (system) => {
            if (system.id) return;
            const newTag = await createCustomWorkTypeQuery(system.name);
            creator.addWorkType(newTag);
            if (newTag) system.id = newTag.id;
          })
        );

      let level = 0;
      if (tasks.length) level = 3;
      else if (features.length) level = 2;
      else if (modules.length) level = 1;

      const getSystemValues = (y) => {
        if(typeof y !== "number" || y < 0 || !tableData[y]) return;
        return importWorkTypes.reduce((val, system) => {
          const obj = {};

          if (useMinMax) {
            obj.min =
              parseFloat(tableData[y][system.column] || 0) *
              60 *
              importTimeModifier;
            obj.max =
              parseFloat(tableData[y][system.columnMax] || 0) *
              60 *
              importTimeModifier;
          } else
            obj.avg =
              parseFloat(tableData[y][system.column] || 0) *
              60 *
              importTimeModifier;

          return { ...val, [system.id]: obj };
        }, {});
      };
      
      const getDescription = (y) => (
        typeof y === "number" && y >= 0 && tableData[y]
          ? tableData[y][descColumn]
          : ""
      )
      
      const checkSharedDescription = (y, children) => Boolean(
        children.find(child => child.y === y)
      )
      
      let index = 0;

      const data = sections.map((section, i) => {
        const nextSectionRow = sections[i + 1] ? sections[i + 1].y : Infinity;
        const sharesDescription = checkSharedDescription(section.y, modules);
        return {
          id: generateSectionId(++index),
          name: section.name,
          content: sharesDescription ? undefined : getDescription(section.y),
          workTypes: importWorkTypes.map(({ id }) => ({ id })),
          values:
            level === 0 || !modules.length
              ? getSystemValues(section.y)
              : {},
          children: modules
            .filter(({ y }) => y >= section.y && y < nextSectionRow)
            .map((module) => {
              const nextModuleRow =
                modules.find((n) => n.y > module.y)?.y || Infinity;
              const submodules = features.filter(
                ({ y }) =>
                  y >= module.y && y < nextModuleRow && y < nextSectionRow
              );
              const sharesDescription = checkSharedDescription(module.y, submodules);
              return {
                id: generateEntryId(++index),
                name: module.name,
                content: sharesDescription ? undefined : getDescription(module.y),
                isOpened: !!submodules.length,
                values:
                  level === 1 || !submodules.length
                    ? getSystemValues(module.y)
                    : {},
                children: submodules.map((feature) => {
                  const nextFeatureRow =
                    features.find((n) => n.y > feature.y)?.y || Infinity;
                  const submodules = tasks.filter(
                    ({ y }) =>
                      y >= feature.y &&
                      y < nextFeatureRow &&
                      y < nextModuleRow &&
                      y < nextSectionRow
                  );
                  const sharesDescription = checkSharedDescription(feature.y, submodules);
                  return {
                    id: generateEntryId(++index),
                    name: feature.name,
                    content: sharesDescription ? undefined : getDescription(feature.y),
                    isOpened: !!submodules.length,
                    values:
                      level === 2 || !submodules.length
                        ? getSystemValues(feature.y)
                        : {},
                    children: submodules.map((task) => ({
                      id: generateEntryId(++index),
                      name: task.name,
                      content: getDescription(task.y),
                      values: getSystemValues(task.y),
                    })),
                  };
                }),
              };
            }),
        };
      });

      creator.setXlsProjectStructure(
        structureParser({
          structureData: {
            ver: DEFAULT_VERSION_INFO,
            settings: {
              ...DEFAULT_PROJECT_SETTINGS,
              timeModifier: importTimeModifier,
              useMinMax,
              currency: getCurrencies()[0]?.code,
              viewLevel: level,
              descriptionMode: 3,
              titleCellWidth: 420,
              descriptionCellWidth: 240,
            },
            sections: data,
          },
          workTypes: importWorkTypes,
          createNewTags: true,
        })
      );

      setLoading(false);
    })();
  }, []);

  return (
    <Grid item container className={classes.previewRoot}>
      {isLoading ? (
        <CircularProgress color="primary" size={48} />
      ) : (
        <TemplatePreview
          useTemplateWorkTypes
          projectStructure={JSON.stringify(xlsProjectStructure)}
          workTypes={importWorkTypes}
        />
      )}
    </Grid>
  );
};
