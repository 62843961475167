import { useState } from "react";
import { bool, number, string, arrayOf, func, shape } from "prop-types";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import ResizeObserver from "react-resize-observer";
import { Avatar, ClearButton, PopMenu } from "@components";
import useStyle from "./AvatarList.style";

export const AvatarList = ({
  users,
  hideExcess = false,
  removeHandler,
  size = 24,
  borderWidth = 1,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const [width, setWidth] = useState(0);
  const [menuVisible, showMenu] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handlePopup = (event) => {
    event.stopPropagation();
    showMenu(!menuVisible);
  };
  const clickAwayHandler = (event) => {
    event.stopPropagation();
    showMenu(false);
  };

  if (!hideExcess)
    return (
      <>
        {users.map((m) => (
          <Avatar
            {...m}
            key={`${m.id}_${m.level || 0}`}
            id={m.id}
            color={m.avatarColor}
            className={classes.avatar}
            removeHandler={m.level === 3 ? undefined : removeHandler}
            emailTooltip={m.email}
            size={size}
            style={{ marginRight: (-1 * size) / 4 }}
            borderWidth={borderWidth}
          />
        ))}
      </>
    );

  let vEl = width ? Math.min(Math.floor((width - 100) / 28), 6) : 6;
  if (vEl < 0) vEl = 0;
  const visibleMembers = users.slice(0, vEl);
  const rest = users.slice(vEl).length;

  return (
    <>
      {visibleMembers.map((m) => (
        <Avatar
          {...m}
          id={m.id}
          key={`${m.id}_${m.level || 0}`}
          color={m.avatarColor}
          className={classes.avatar}
          emailTooltip={m.email}
          size={size}
          style={{ marginRight: (-1 * size) / 4 }}
          borderWidth={borderWidth}
        />
      ))}
      {Boolean(rest) && (
        <>
          <ClearButton
            className={classes.more}
            style={!visibleMembers.length ? { margin: 0 } : {}}
            onClick={handlePopup}
            ref={setMenuAnchor}
          >
            {visibleMembers.length
              ? `${rest > 3 ? "3+" : rest} ${t("common.more")}`
              : t("common.showAvatars")}
          </ClearButton>
          <PopMenu
            show={menuVisible}
            anchor={menuAnchor}
            onClickAway={clickAwayHandler}
            style={{ width: 187, padding: "14px 0" }}
            showArrow
          >
            <Grid container direction="row">
              {users.map((m) => {
                return (
                  <Grid
                    key={m.id}
                    item
                    container
                    direction="row"
                    className={classes.listItem}
                  >
                    <Avatar
                      {...m}
                      key={`${m.id}_${m.level || 0}`}
                      id={m.id}
                      color={m.avatarColor}
                      className={classes.listAvatar}
                      size={size}
                      borderWidth={borderWidth}
                    />
                    <p>
                      {m.fullname}
                    </p>
                  </Grid>
                );
              })}
            </Grid>
          </PopMenu>
        </>
      )}
      <ResizeObserver onResize={(r) => setWidth(r.width)} />
    </>
  );
};

AvatarList.propTypes = {
  users: arrayOf(
    shape({
      fullname: string,
      color: string,
      avatar: string,
    })
  ).isRequired,
  hideExcess: bool,
  removeHandler: func,
  size: number,
  borderWidth: number,
};
