import { string, node, arrayOf } from "prop-types";
import Grid from "@material-ui/core/Grid";
import useStyle from "./Table.style";

export const TableRow = (props) => {
  const classes = useStyle();
  const { className, children } = props;

  return (
    <Grid
      item
      container
      role="row"
      wrap="nowrap"
      className={`${classes.row} ${className}`}
    >
      {children}
    </Grid>
  );
};

TableRow.propTypes = {
  children: arrayOf(node),
  className: string,
};