import { arrayOf, bool, number, shape, string } from "prop-types";
import { observer } from "mobx-react";
import { usePresetFieldNames, useStructureStore } from "@hooks";
import { CELL_WIDTHS, TABLE_DRAGGABLE_CONTAINER } from "@utils";
import { HeaderRow, TitleCell, SortableList } from "@components";
import Grid from "@material-ui/core/Grid";
import { HeaderColumnFactory } from "./HeaderColumnFactory";

export const TableHeader = observer(({
  tableIdentifier,
  isProposal,
  useClientActions,
  allowEdition,
  showPrices,
  columns,
  maxTitleCellWidth,
  useExpandCell,
  useProfitability,
  noTurningOff,
}) => {
  const { getFieldName } = usePresetFieldNames(isProposal);
  
  const structure = useStructureStore();
  const { settings, allWorkTypesExpanded } = structure;
  const { visibleSummaryColumns } = settings;
  
  const handleOpenAction = () => {
    structure.expandAllWorkTypes();
  };

  const handleColumnReorder = (list) => {
    settings.reorderSummaryColumns(list);
  };

  return (
    <HeaderRow
      expandable
      useExpandCell={useExpandCell}
      hideVisibilitySwitch
      useVisibilityCell={ useClientActions && !noTurningOff }
      expanded={allWorkTypesExpanded}
      onExpand={handleOpenAction}
      id={tableIdentifier}
      className="preset-paper"
      altBackground
      expandCellPresetClass="preset-tableSummaryHeader"
    >
      <TitleCell
        minWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : CELL_WIDTHS.TITLE_SUMMARY}
        maxWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : maxTitleCellWidth}
        pinnedLeft={useExpandCell ? 1 : 0}
      >
        <span className="preset-tableSummaryHeader pt-work">
          {getFieldName("pt-work")}
        </span>
      </TitleCell>
      <Grid
        className={TABLE_DRAGGABLE_CONTAINER}
        item
        container
        wrap="nowrap"
        alignItems="center"
        style={{ width: "initial" }}
      >
        <SortableList
          list={visibleSummaryColumns}
          group="summary-columns"
          onListReorder={handleColumnReorder}
          displayAsFlexbox
          disabled={!allowEdition || useProfitability}
          itemIdKey="name"
        >
          {columns.map(({ name }) => (
            <HeaderColumnFactory
              key={name}
              columnName={name}
              showPrices={showPrices}
              allowEdition={allowEdition}
              isProposal={isProposal}
              profitabilityMode={useProfitability}
            />
          ))}
        </SortableList>
      </Grid>
    </HeaderRow>
  );
});

TableHeader.propTypes = {
  tableIdentifier: string,
  showPrices: bool,
  isProposal: bool,
  readOnly: bool,
  useClientActions: bool,
  allowEdition: bool,
  useProfitability: bool,
  columns: arrayOf(shape({
    name: string.isRequired
  })).isRequired,
  maxTitleCellWidth: number,
};
