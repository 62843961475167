import { useEffect, useState, useRef, useMemo } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  useCheckProjectAccess,
  usePresetStore,
  useProjectEditorStore, useStores,
} from "@hooks";
import { AppBar, CircularProgress, Grid, Toolbar } from "@material-ui/core";
import { Alert, Button, PresetPicker } from "@components";
import { FontPicker } from "./FontPicker";
import { ToolPopup } from "./ToolPopup/ToolPopup";
import { Tooltip } from "./Tooltip/Tooltip";
import { Share, PdfExport, NameEditorModal } from "@dialogs";
import { removeCompanyPresetQuery } from "@query";
import { Trash } from "@assets";
import { Check } from "@material-ui/icons";

export const PresetToolbar = observer(({ previewMode }) => {
  const presetStore = usePresetStore();
  const { projectUuid } = useParams();
  const editorStore = useProjectEditorStore();
  const { userStore } = useStores();
  const { t } = useTranslation();

  const { projectUsers } = editorStore;
  const { isAdmin } = userStore;

  const { isBusinessUser, hasSharePrivileges } =
    useCheckProjectAccess(projectUsers);

  const [isLoading, setLoadingState] = useState(true);
  const [isRemoving, setRemovingState] = useState(false);
  const [presetCreateModalVisible, showPresetCreateModal] = useState(false);
  const [currentElement, _setCurrentElement] = useState(null);
  const [currentHelperElement, _setCurrentHelperElement] = useState(null);
  const [openToolMenu, _setToolMenuOpen] = useState(false);
  const [closingAlertVisible, showClosingAlert] = useState(false);
  const [removePresetAlertVisible, showPresetAlertVisible] = useState(false);

  const openToolMenuRef = useRef(openToolMenu);
  const currentElementRef = useRef(currentElement);
  const currentHelperElementRef = useRef(currentHelperElement);

  const { selectedPresetId, presets, presetChanged, defaultPresetId } = presetStore;

  const setCurrentElement = (d) => {
    currentElementRef.current = d;
    _setCurrentElement(d);
  };
  const setCurrentHelperElement = (d) => {
    currentHelperElementRef.current = d;
    _setCurrentHelperElement(d);
  };
  const setToolMenuOpen = (d) => {
    openToolMenuRef.current = d;
    _setToolMenuOpen(d);
  };

  useEffect(() => {
    (async () => {
      setLoadingState(true);
      await presetStore.getPresets(t("views.editor.preset.apropo"));
      setLoadingState(false);
    })();

    if (!previewMode) {
      window.document.addEventListener("mousemove", lookupPresetElements);
      window.document.addEventListener("click", handlePresetElementClick);
    }

    return () => {
      if (!editorStore.previewMode && presetStore.presetChanged)
        handlePresetCommit(selectedPresetId === -1)();

      if (!previewMode) {
        window.document.removeEventListener("mousemove", lookupPresetElements);
        window.document.removeEventListener("click", handlePresetElementClick);
      }
    };
  }, []);

  useEffect(() => {
    if (previewMode) {
      _setToolMenuOpen(false);
      window.document.removeEventListener("mousemove", lookupPresetElements);
      window.document.removeEventListener("click", handlePresetElementClick);
    } else {
      window.document.addEventListener("mousemove", lookupPresetElements);
      window.document.addEventListener("click", handlePresetElementClick);
    }
  }, [previewMode]);

  useEffect(() => {
    if (presetChanged && selectedPresetId === -1) showPresetCreateModal(true);
  }, [presetChanged, selectedPresetId]);

  const lookupPresetElements = (e) => {
    if (openToolMenuRef.current) return;
    const el = e.target.closest(getPresetClassesSelector());
    if (
      el &&
      (!currentElementRef?.current ||
        currentElementRef?.current.isVirtual ||
        !el.isEqualNode(currentElementRef.current))
    ) {
      // if class is "preset-page" virtual element should be passed as an element
      const isPage = el.className.match(/preset-((page)|(paperTimeline)|(paper))/);
      setCurrentElement(
        isPage
          ? {
              getBoundingClientRect: () => ({
                top: e.clientY,
                left: e.clientX,
                width: 10,
                height: 10,
              }),
              isVirtual: true,
              textClass: el.className.match(/pt-\w+/)?.[0],
              elClass: isPage[0],
            }
          : el
      );
      setCurrentHelperElement({
        getBoundingClientRect: () => ({
          top: e.clientY,
          left: e.clientX,
          width: 10,
          height: 10,
        }),
        isVirtual: true,
        elClass: isPage
          ? isPage[0]
          : el.className.match(/preset-\w+(-\w+)?/)?.[0],
        textClass: el.className.match(/pt-\w+/)?.[0],
        tableDepth: isPage ? null : el.className.match(/(d)(\d)/)?.[2],
      });
    }
  };

  const handlePresetElementClick = (e) => {
    if (openToolMenuRef.current || e?.target?.closest("[role='presentation']"))
      return;
    const el = e.target.closest(getPresetClassesSelector());
    if (
      currentElementRef?.current?.isVirtual ||
      (el &&
        currentElementRef?.current &&
        el.isEqualNode(currentElementRef.current))
    ) {
      e.preventDefault();
      e.stopPropagation();
      setToolMenuOpen(true);
    }
  };

  const handleNewPresetModalClose = async (newName) => {
    try {
      if (newName) {
        await presetStore.createPreset(newName);
        await presetStore.addPresetToProject(projectUuid);
      } else if (selectedPresetId === -1) presetStore.resetDefaultPreset();
      showPresetCreateModal(false);
    } catch(e) {
      showPresetCreateModal(false);
    }
  };

  const saveAndClose = () => {
    handlePresetCommit(selectedPresetId === -1)();
    showClosingAlert(false);
    editorStore.setProposalStep(0);
  };

  const closePresetEditor = () => {
    showClosingAlert(false);
    editorStore.setProposalStep(0);
    const n = presets.find(({ id }) => id === selectedPresetId);
    presetStore.setSelectedPreset(selectedPresetId, n?.name, n ? n.data : null, n.font?.id ? n.font : undefined);
  };
  
  const handleDefaultPreset = async () => {
    await presetStore.setDefaultPresetId(selectedPresetId);
  }

  const handlePresetCommit =
    (isNew = false) =>
    async () => {
      if (!isNew) await presetStore.updatePreset();
      else showPresetCreateModal(true);
    };
  
  const handlePresetRemove = async () => {
    setRemovingState(true);
    const presetId = selectedPresetId;
    const p = presets.find(({ id }) => id === -1);
    await presetStore.setSelectedPreset(p.id, p.name, p.data);
    await presetStore.addPresetToProject(projectUuid);
    if(presetId === presetStore.defaultPresetId)
    await presetStore.setDefaultPresetId(-1);
    
    await removeCompanyPresetQuery(presetId);
    await presetStore.removePreset(presetId);
    showPresetAlertVisible(false);
    setRemovingState(false);
  }
  
  const isDefault = useMemo(() => (
    defaultPresetId === selectedPresetId
  ), [defaultPresetId, selectedPresetId]);
  
  if (isLoading) return null;

  return (
    <AppBar
      position="static"
      role="banner"
      color="secondary"
      style={{ zIndex: 10 }}
    >
      <Toolbar style={{ justifyContent: "space-between" }}>
        <Grid item container wrap="nowrap" alignItems="center">
          <PresetPicker
            isLoading={isLoading}
            previewMode={previewMode}
            commitPresetSelection
            className="mr-2"
          />
          {!previewMode && <FontPicker />}
          {!previewMode && (
            <Button
              variant="outlined"
              onClick={handlePresetCommit(true)}
              style={{ maxWidth: 136 }}
              className="mr-2"
            >
              {selectedPresetId === -1
                ? t("common.add_new")
                : t("views.editor.preset.save_as")}
            </Button>
          )}
          {
            !isDefault && isAdmin &&
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              className="mr-2"
              onClick={handleDefaultPreset}
            >
              {t("common.default_set")}
            </Button>
          }
          {
            selectedPresetId > 0 &&
            <Button
              square
              size="medium"
              variant="outlined"
              className="mr-2"
              icon={
                <Trash className="color-error text-md" />
              }
              onClick={() => showPresetAlertVisible(true)}
              color="secondary"
              name={t("views.editor.preset.remove")}
              tooltip
            />
          }
          {
            isDefault &&
            <Grid item container wrap="nowrap" alignItems="center" className="w-max text-xs color-primary">
              <Check className="mr-1 text-xl" />
              {t("views.editor.preset.default")}
            </Grid>
          }
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          wrap="nowrap"
          className="w-max"
          spacing={1}
        >
          {hasSharePrivileges && (
            <Grid item container className="w-max">
              <PdfExport />
            </Grid>
          )}
          {isBusinessUser && (
            <Grid item container className="w-max">
              <Share usePresetModeButton />
            </Grid>
          )}
        </Grid>
        {!previewMode && (
          <ToolPopup
            show={openToolMenu}
            anchor={currentElement}
            // presetName={selectedPresetName}
            isDefaultPreset={selectedPresetId === -1}
            onClose={() => setToolMenuOpen(false)}
          />
        )}
        {!previewMode && (
          <Tooltip
            show={!openToolMenu && !presetCreateModalVisible}
            anchor={currentHelperElement}
          />
        )}
        {!previewMode && (
          <NameEditorModal
            open={presetCreateModalVisible}
            onClose={handleNewPresetModalClose}
            title={t("views.editor.preset.name")}
            confirmButtonTitle={t("views.editor.preset.create_new")}
          />
        )}
        <Alert
          title={t("views.editor.preset.alert.title")}
          isOpen={closingAlertVisible}
          onAccept={saveAndClose}
          acceptText={t("views.editor.preset.alert.accept")}
          onCancel={closePresetEditor}
          cancelText={t("common.close")}
        />
        <Alert
          title={t("views.editor.preset.alert_remove")}
          isOpen={removePresetAlertVisible}
          onAccept={handlePresetRemove}
          onCancel={() => showPresetAlertVisible(false)}
          acceptText={isRemoving ? <CircularProgress size={16} className="text-alt" /> : t("common.confirm_remove")}
          cancelText={t("common.cancel_remove")}
        />
      </Toolbar>
    </AppBar>
  );
});

PresetToolbar.propTypes = {
  previewMode: bool,
};

const getPresetClassesSelector = () => {
  const classes = [
    "preset-title",
    "preset-logo",
    "preset-bar",
    "preset-total",
    "preset-summaryTitleText",
    "preset-summaryText",
    "preset-timelineTitleText",
    "preset-timelineText",
    "preset-titleTextSection",
    "preset-titleText",
    "preset-text",
    "preset-version-active",
    "preset-version",
    "preset-timelineMode",
    "preset-timelineModeActive",
    "preset-moreDesc",
    "preset-moreDescSection",
    "preset-sectionDesc",
    "preset-tableBreakdownExpandButton",
    "preset-tableSummaryExpandButton",
    "preset-tableTimelineExpandButton",
    "preset-section",
    "preset-sectionRow",
    "preset-timelineSubHeader",
    "preset-timelineHeader",
    "preset-tableBreakdownHeader",
    "preset-tableTimelineHeader",
    "preset-tableSummaryHeader",
    "preset-tableTimelineBilling",
    "preset-tableFooter",
    "preset-summaryHeader",
    "preset-totalTimeTitle",
    "preset-totalHoursTitle",
    "preset-totalCostTitle",
    "preset-totalTime",
    "preset-totalHours",
    "preset-totalCost",
    "preset-breakdownHeader",
    "preset-price",
    "preset-active-wT",
    "preset-active-1",
    "preset-active-2",
    "preset-activeSummary",
    "preset-activeTimeline",
    "preset-rowSummary",
    "preset-rowTimeline",
    "preset-rowDesc",
    "preset-row",
    "preset-export",
    "preset-paperTimeline",
    "preset-paper",
    "preset-page:not(.preset-noedit)",
  ];
  return classes.reduce((p, c) => p + ", ." + c, "").slice(1);
};
