import { bool } from "prop-types";
import { observer } from "mobx-react";
import { usePresetFieldNames, useProjectEditorStore, useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { CurrencyEditor } from "@dialogs";
import { ValueCell, VisibilityButton } from "@components";

export const RateCell = observer(({
  allowEdition,
  isProposal,
}) => {
  
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { currency } = structure.settings;
  const { hideSummaryRate } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryRate);
  
  return (
    <ValueCell
      widest 
      isHeader
      size="xSmall"
      className={className}
      style={{ cursor: allowEdition && "grab", }}
    >
      <span className="preset-tableSummaryHeader pt-rate">
        {`${getFieldName("pt-rate")} (${currency})`}
      </span>
      {allowEdition && <CurrencyEditor />}
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={-4}
          hidden={hideSummaryRate}
          onChange={structure.visibility.setHideSummaryRate}
        />
      }
    </ValueCell>
  )
})

RateCell.propTypes = {
  isProposal: bool,
  allowEdition: bool,
}