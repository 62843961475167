import { arrayOf, object, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Autocomplete } from "@components";

export const TagSelect = ({ control, name, labels }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <Autocomplete
          value={value}
          options={labels}
          name={name}
          idKey="id"
          labelKey="name"
          inputLabel={t("views.bt.tag")}
          fullWidth
          onChange={onChange}
          size="small"
          style={{ width: 192 }}
          fieldClassName="m-0"
        />
      )}
    />
  );
};

TagSelect.propTypes = {
  control: object.isRequired,
  name: string.isRequired,
  labels: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
    })
  ).isRequired,
};
