import { observer } from "mobx-react";
import { ValueCell, VisibilityButton } from "@components";
import { usePresetFieldNames, useProjectEditorStore, useStructureStore, useVisibilityModeCellClassName } from "@hooks";

export const TeamCell = observer(({
  allowEdition,
  isProposal,
}) => {
  
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { hideSummaryTeam } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryTeam);
  
  return (
    <ValueCell
      wider
      isHeader
      size="xSmall"
      className={className}
      style={{ cursor: allowEdition && "grab", }}
    >
      <span className="preset-tableSummaryHeader pt-teamMembers">
        {getFieldName("pt-teamMembers")}
      </span>
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={-8}
          hidden={hideSummaryTeam}
          onChange={structure.visibility.setHideSummaryTeam}
        />
      }
    </ValueCell>
  )
})