import { useState } from "react";
import { string } from "prop-types";
import { removeProjectQuery } from "@query";
import { Alert, MenuButton } from "@components";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Trash } from "@assets";
import { useActiveProjectStore, useActiveProjectsWebsocket } from "@hooks";

export const DeleteProject = ({ uuid }) => {
  
  const { t } = useTranslation();
  const activeProjects = useActiveProjectStore();
  const socket = useActiveProjectsWebsocket();
  
  const [alertText, setAlertText] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  
  const openDeleteDialog = (s) => (e) => {
    e.stopPropagation();
    setDeleteDialog(s);
  };
  
  const removeProject = async () => {
    setLoading(true);
    try {
      await removeProjectQuery(uuid);
      socket?.requestProjectRemove(uuid);
      activeProjects.editLocalProjectData(uuid, "remove");
    } catch (e) {
      setAlertText(e.response.data.error);
    }
    setDeleteDialog(false);
    setLoading(false);
  };
  
  return <>
    <MenuButton
      icon={<Trash color="error" />}
      onClick={openDeleteDialog(true)}
    >
      {t("common.delete")}
    </MenuButton>
    <Alert
      isOpen={deleteDialog}
      onAccept={removeProject}
      onCancel={openDeleteDialog(false)}
      title={t("common.delete_project_question")}
      acceptText={
        isLoading ? (
          <CircularProgress
            aria-label="progress indicator"
            size={24}
            color="inherit"
          />
        ) : (
          t("common.delete_accept")
        )
      }
    />
    <Alert
      title={alertText}
      isOpen={!!alertText}
      onAccept={() => setAlertText("")}
      acceptText={t("common.close")}
    />
  </>
}

DeleteProject.propTypes = {
  uuid: string.isRequired
}