import React from "react";
import { Alert } from "@components";
import { home } from "@paths";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const NewEditorVersionAlert = () => {
  
  const {t} = useTranslation();
  const navigate = useNavigate();
  
  const reloadPage = () => {
    location.replace(location.href);
  }
  
  const backToHome = () => {
    navigate(home)
  }
  
  return (
    <Alert
      isOpen
      title={t("alerts.editor.new_version.title")}
      acceptText={t("alerts.editor.new_version.accept")}
      cancelText={t("alerts.editor.new_version.cancel")}
      onAccept={reloadPage}
      onCancel={backToHome}
    />
  );
};