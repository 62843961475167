import { bool, string, node, arrayOf, oneOfType, oneOf } from "prop-types";
import { Row } from "../Row/Row";
import useStyle from "./HeaderRow.style";
import classnames from "classnames";

export const HeaderRow = ({
  className,
  altBackground,
  size="xSmall",
  ...props
}) => {
  const classes = useStyle(Boolean(altBackground));

  return (
    <Row
      {...props}
      isHeader
      noArrow
      size={size}
      className={classnames(
        "preset-noedit",
        classes.headerRow,
        altBackground ? "preset-paper" : "preset-page",
        className
      )}
    >
      {/* title cell with a button */}
      {/* valueCells */}
      {/* valueCells */}
    </Row>
  );
};

HeaderRow.propTypes = {
  id: string,
  altBackground: bool,
  children: oneOfType([node, arrayOf(node)]).isRequired,
  className: string,
  useExpandCell: bool,
  useVisibilityCell: bool,
  expandCellPresetClass: string,
  size: oneOf(["xSmall", "small", "medium", "large", "xLarge"]),
};
