import { useEffect, useState } from "react";
import { ProjectCreatorStoreProvider } from "@stores";
import { getCompanySettingsQuery, getWorkTypesQuery, setCompanySettingsQuery } from "@query";
import { TemplatesView } from "./TemplatesView";
import { PageCircularProgress } from "@components";
import { COMPANY_SETTINGS } from "@client";

export const Templates =() => {
  
  
  const [defaultTemplateId, setDefaultTemplateId] = useState();
  const [workTypes, setWorkTypes] = useState(null);
  
  useEffect(() => {
    load();
  }, []);
  
  const load = async () => {
    const templateId = await getCompanySettingsQuery(COMPANY_SETTINGS.TEMPLATE);
    const wT = await getWorkTypesQuery();
    setDefaultTemplateId(templateId);
    setWorkTypes(wT);
  };
  
  const handleDefaultTemplateId = async (templateId) => {
    setDefaultTemplateId(templateId);
    await setCompanySettingsQuery(COMPANY_SETTINGS.TEMPLATE, templateId);
  }
  
  return !workTypes
    ? <PageCircularProgress/>
    : (
      <ProjectCreatorStoreProvider workTypes={workTypes}>
        <TemplatesView
          defaultTemplateId={defaultTemplateId}
          setDefaultTemplateId={handleDefaultTemplateId}
        />
      </ProjectCreatorStoreProvider>
    );
};
