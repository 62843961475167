import { bool, string, node, oneOf } from "prop-types";
import { IconButton, Tooltip, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Info } from "@assets";
import useStyle from "./InfoLabel.style";
import classnames from "classnames";

export const InfoLabel = ({
  label,
  tooltipText,
  color="primary",
  translate=true,
  spacing=16,
  action,
  titleAction,
  className
}) => {

  const { t } = useTranslation();
  const classes = useStyle();

  const hasAction = Boolean(action);

  return (
    <Grid
      item
      container
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
      className={classnames(
        spacing && classes["spacing-" + spacing],
        className
      )}
    >
      <Grid item container alignItems="center">
        <p className={classes.label}>
          {translate ? t(label) : label}
        </p>
        {Boolean(tooltipText) && (
          <Tooltip
            title={translate ? t(tooltipText) : tooltipText}
            placement="right"
            arrow
          >
            <IconButton
              color={color}
              className="ml-1"
              size="small"
            >
              <Info />
            </IconButton>
          </Tooltip>
        )}
        {titleAction}
      </Grid>
      {hasAction && <Grid item>{action}</Grid>}
    </Grid>
  );
};

InfoLabel.propTypes = {
  label: string.isRequired,
  tooltipText: string,
  color: oneOf(["primary", "secondary"]),
  translate: bool,
  spacing: oneOf([0, 8, 16, 32]),
  action: node,
  titleAction: node,
  className: string,
};
