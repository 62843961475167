import { uploadCompanyFileToS3 } from "@client";
import {
  addProjectAttachmentQuery,
  addProjectPdfCoverQuery,
  triggerProjectCoverPrerenderWebhookQuery,
} from "@query";
import { useStores } from "@hooks";

export const useS3Uploader = () => {
  const { stateStore } = useStores();

  const onIndexProgress = (index) => (progress) => {
    stateStore.setFilesUploadProgress(
      index,
      Math.min((progress.loaded / progress.total) * 100, 100)
    );
  };

  const uploadPresetLogo = async (presetName, file) => {
    return await uploadCompanyFileToS3(
      stateStore.getPresetPath(presetName),
      file.slice(),
      file.type,
      onIndexProgress(0)
    );
  };

  const uploadProjectFile = async (projectUuid, file, fileName, index = 0, version = "v1") => {
    return await uploadCompanyFileToS3(
      stateStore.getAttachmentPath(projectUuid, fileName, version),
      file.slice(),
      file.type,
      onIndexProgress(index)
    );
  };

  const uploadAttachment = async (projectUuid, file, index, version) => {
    const { Location, Key, ETag } = await uploadProjectFile(
      projectUuid,
      file,
      file.name,
      index,
      version
    );

    const uuid = ETag.replace(/"/g, "");

    const id = await addProjectAttachmentQuery(
      projectUuid,
      uuid,
      file.name,
      Location,
      Key
    );

    return { id, uuid, file: Key, name: file.name, path: Location };
  };

  const uploadProposal = async (
    projectUuid,
    file,
    index,
    version,
    usedPdfPages,
    proposalIndex,
    scale,
  ) => {
    const fileName = `cover_${new Date().getTime()}.pdf`;
    const { Location, ETag } = await uploadProjectFile(
      projectUuid,
      file,
      fileName,
      index,
      version
    );
    
    triggerProjectCoverPrerenderWebhookQuery(Location);

    return await addProjectPdfCoverQuery(
      projectUuid,
      ETag.replace(/"/g, ""),
      fileName,
      Location,
      usedPdfPages ? JSON.stringify(usedPdfPages) : "[]",
      proposalIndex,
      scale
    );
  };

  return {
    uploadAttachment,
    uploadProposal,
    uploadPresetLogo
  };
};
