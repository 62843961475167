import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useSearchParams } from "react-router-dom";
import { unzipStructure } from "project-structure";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { omit } from "lodash";
import {
  ActiveProjectStore,
  ActiveProjectStoreProvider,
  ProjectCreatorStoreProvider,
} from "@stores";
import {
  getActiveProjectsQuery,
  getCompanySettingsQuery,
  initDemoProjectQuery,
  setCompanySettingsQuery,
  getAnnouncementsQuery,
  setAnnouncementReadStatusQuery,
  getWorkTypesQuery,
} from "@query";
import { ActiveProjectsSocketProvider, COMPANY_SETTINGS } from "@client";
import { useStores } from "@hooks";
import { PROJECT_COLUMN_COLORS, PROJECT_COLUMNS } from "@utils";
import { JiraBTSetupDialog, JiraExportAndBTInfoDialog, JiraExportModal, Announcements } from "@dialogs";
import { PageCircularProgress, UploadSnackBar } from "@components";
import { ActiveProjectsView } from "./ActiveProjectsView";

export const ActiveProjects = observer(() => {
  const { t } = useTranslation();
  const { userStore, stateStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  
  const [ columns, setColumns ] = useState();
  const [ btQuery, setBTQuery ] = useState();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ announcements, setAnnouncements ] = useState();
  const [ jiraExportId, setJiraExportId ] = useState();
  const [ projectName, setProjectName ] = useState("");
  const [ openBTSetupDialog, setOpenBTSetupDialog ] = useState(false);
  const [ openJiraExportDialog, setOpenJiraExportDialog ] = useState(false);

  const activeProjectStore = useRef(new ActiveProjectStore());
  
  useEffect(() => { load() }, []);
  
  useEffect(() => {
    const btCb = searchParams.get("btCb");
    const btSu = searchParams.get("btSu");
    if (btCb) {
      setBTQuery(JSON.parse(unzipStructure(btCb)));
      setOpenJiraExportDialog(Boolean(btCb));
    }
    if (btSu) {
      setBTQuery(JSON.parse(unzipStructure(btSu)));
      setOpenBTSetupDialog(Boolean(btSu));
    }
    setSearchParams();
  }, []);
  
  const checkAnnouncements = async () => {
    const an = await getAnnouncementsQuery(userStore.userUuid);
    setAnnouncements(an);
  }

  const handleOpenInfo = (jiraExportId) => {
    setJiraExportId(jiraExportId);
  };

  const handleCloseInfo = () => {
    enqueueSnackbar(
      <UploadSnackBar jiraExportId={jiraExportId} />,
      {
        variant: "info",
        key: jiraExportId,
        persist: true,
        preventDuplicate: true,
      }
    );
    setJiraExportId(undefined);
  };

  const load = async () => {
    
    await checkAnnouncements();
    
    if (stateStore.firstLogin) {
      await initDemoProjectQuery();
    }
    
    const workTypes = await getWorkTypesQuery();
    activeProjectStore.current.setWorkTypes(workTypes);

    const p = await getActiveProjectsQuery(userStore.workspaceUuid);
    activeProjectStore.current.setProjects(p);
    const projectName =
      jiraRedirectProjectName ||
      p.find((p) => p.uuid === jiraRedirectProjectId)?.name ||
      "";
    setProjectName(projectName);
    
    const namedCols = await getCompanySettingsQuery(
      COMPANY_SETTINGS.HOME_COLUMNS
    );
    
    const cols = Object.keys(PROJECT_COLUMNS)
      .reduce((o, col) => [
        ...o,
        {
          code: PROJECT_COLUMNS[col],
          color: PROJECT_COLUMN_COLORS[col],
          name: namedCols?.[col]
        },
      ], []);

    setColumns(cols);
    /**
     * @note: 10.10.24 - temporary removed this BT-Api call:
     
     try {
       const j = await getCompanyProjectsStatusQuery(userStore.data.companyId);
       activeProjectStore.current.setBtProjectsStatus(j.projects);
     } catch (e) {
       console.error("Could not fetch BT projects");
     }
     */
  };
  
  const handleAnnouncementModalClose = () => {
    shownAnnouncements.announcements.forEach(a => {
      setAnnouncementReadStatusQuery(a.id);
    })
    const u = omit(announcements, shownAnnouncements.type);
    setAnnouncements(Object.keys(u).length ? u : undefined)
  }
  
  const handleColumnNameChange = useCallback(async (code, newName) => {
    const c = [...columns];
    const column = c.find((col) => col.code === code);
    if (!column) return;

    column.name = newName;

    await setCompanySettingsQuery(
      COMPANY_SETTINGS.HOME_COLUMNS,
      c.reduce((o, { code, name }) => ({ ...o, [code]: name }), {})
    );
    setColumns(c);
  }, [columns]);
  
  const shownAnnouncements = useMemo(() => {
    if(!announcements) return;
    const keys = Object.keys(announcements);
    if(!keys.length) return;
    return {
      announcements: announcements[keys[0]],
      type: keys[0]
    };
  }, [announcements]);
  
  const jiraRedirectProjectId = useMemo(() => btQuery?.projectId, [btQuery]);
  
  const jiraRedirectProjectName = useMemo(() => btQuery?.jiraProjectName, [btQuery]);

  return !columns ? (
    <PageCircularProgress />
  ) : (
    <ActiveProjectStoreProvider value={activeProjectStore.current}>
      <ProjectCreatorStoreProvider>
        <ActiveProjectsSocketProvider>
          <ActiveProjectsView
            columns={columns}
            handleColumnNameChange={handleColumnNameChange}
          />
        </ActiveProjectsSocketProvider>
      </ProjectCreatorStoreProvider>
      {
        shownAnnouncements &&
        <Announcements
          updates={shownAnnouncements.announcements}
          variant={shownAnnouncements.type}
          onClose={handleAnnouncementModalClose}
        />
      }
      {openJiraExportDialog && (
        <JiraExportModal
          onClose={() => setOpenJiraExportDialog(false)}
          uuid={jiraRedirectProjectId}
          projectJiraName={jiraRedirectProjectName}
          onOpenInfo={handleOpenInfo}
        />
      )}
      {openBTSetupDialog && (
        <JiraBTSetupDialog
          onClose={() => setOpenBTSetupDialog(false)}
          uuid={jiraRedirectProjectId}
          isOpen={openBTSetupDialog}
        />
      )}
      {jiraExportId && (
        <JiraExportAndBTInfoDialog
          hideNotClose
          openModal
          title={t("views.bt.info_create_project", {
            projectName: `${projectName}`,
          })}
          onClose={handleCloseInfo}
          width={600}
          actions={<UploadSnackBar jiraExportId={jiraExportId} />}
        />
      )}
    </ActiveProjectStoreProvider>
  );
});
