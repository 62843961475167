import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";

export async function forceProjectSaveQuery(
  projectUuid,
) {
  const res = await fetchData("/api/project/save", APIMethod.POST, {
    baseURL: ACCESS_DOMAINS.editor,
    mapRequestKeysToSnakeCase: false,
    params: {
      uuid: projectUuid,
    },
  });

  return res.results;
}
