import { useTranslation } from "react-i18next";
import { func, oneOf, string } from "prop-types";
import { TrendingUp } from "@material-ui/icons";
import { pricing } from "@paths";
import { Button } from "@components";

export const UpgradeButton = ({ color, className, onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color={color}
      size="small"
      icon={<TrendingUp />}
      className={className}
      onClick={onClick}
      to={pricing}
    >
      {t("bars.menu.pro_user")}
    </Button>
  );
};

UpgradeButton.propTypes = {
  color: oneOf(["primary", "secondary"]),
  className: string,
  onClick: func,
};
