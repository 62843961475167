import { APIMethod, fetchData } from "@client";

export async function removeProjectCoverQuery(
  uuid,
  proposalId
) {
  return await fetchData(
    `/v1/project/cover/${uuid}/${proposalId}`,
    APIMethod.DELETE
  );
}
