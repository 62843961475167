import { useState } from "react";
import { bool, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { useFeatureGuardian, useStores } from "@hooks";
import { Alert, Dialog } from "@components";
import { NewBoardForm } from "@forms";
import { LOCKED_FEATURES } from "@utils";
import { createWorkspaceQuery } from "@query";


export const CreateWorkspaceDialog = ({ open, onClose }) => {

	const { t } = useTranslation();
	const { userStore } = useStores();
  
  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.BOARDS
  );

	const [alertText, setAlertText] = useState("");
  
  const createBoard = async (data) => {
    const allowed = await checkAccess();
    if (!allowed)
      return;
    
    try {
      const workspace = await createWorkspaceQuery(data);
      userStore.addAvailableWorkspaces(workspace);
    } catch (e) {
      setAlertText(e.message);
    }
  };
  
	return (
		<Dialog
			open={open}
			onClose={() => onClose()}
			title={t("bars.menu.board_add")}
			width={420}
		>
      <NewBoardForm
        onSubmit={createBoard}
      />
      <FeatureAlert />
			<Alert
				title={alertText}
				isOpen={!!alertText}
				useCancel={false}
				onAccept={() => setAlertText("")}
				acceptText={t("common.close")}
			/>
		</Dialog>
	);
};

CreateWorkspaceDialog.propTypes = {
	open: bool.isRequired,
	onClose: func.isRequired,
};