import { useState } from "react";
import {
  bool,
  number,
  string,
  object,
  shape,
  arrayOf,
  oneOfType,
} from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useActiveProjectStore, useActiveProjectsWebsocket } from "@hooks";
import {
  removeProjectObserverQuery,
  addProjectObserverQuery,
  setProjectOwnerQuery,
  removeProjectAttachmentQuery, removeUserFromWorkTypeQuery
} from "@query";
import Grid from "@material-ui/core/Grid";
import {
  AddButton,
  Alert,
  AsyncPicker,
  AvatarList,
  ClearButton,
  InfoLabel,
  TagList, UserItem,
} from "@components";
import { AttachmentsEditor } from "../index";
import useStyle from "./ActionPanel.style.js";
import { downloadFile, flattenProjectUsers } from "@utils";

export const ActionPanel = observer(({
  uuid,
  isOwner,
  isMini,
  users,
  owner,
  author,
  teamMembers,
  tags,
  attachments,
  sticky,
}) => {
  const { t } = useTranslation();
  const activeProjects = useActiveProjectStore();
  const socket = useActiveProjectsWebsocket();
  const classes = useStyle();

  const [memberSelectAnchorEl, setMemberSelectAnchorEl] = useState(null);
  const [ownerAnchorEl, setOwnerAnchorEl] = useState(null);
  const [memberSelectVisible, showMemberSelect] = useState(false);
  const [ownerPickerVisible, showOwnerPicker] = useState(false);
  const [alertText, setAlertText] = useState("");

  const allUsers = flattenProjectUsers({
    author,
    owner,
    observer: users,
  });

  const setOwner = async (newOwner) => {
    try {
      const observer = users.filter((u) => u.email !== newOwner.email);
      if (owner && owner.id !== author.id)
        observer.push({ ...owner });
      await setProjectOwnerQuery(uuid, newOwner.email);
      socket?.requestProjectUserPromote(uuid, newOwner.uuid);
      activeProjects.editLocalProjectData(uuid, "owner", newOwner);
      activeProjects.editLocalProjectData(uuid, "observers", observer);
      showOwnerPicker(null);
    } catch (e) {
      setAlertText(e.response.data.error);
    }
  };

  const handleMemberAdd = async (v) => {
    try {
      await addProjectObserverQuery(uuid, v.email);
      socket?.requestProjectUserAdd(uuid, v);
      activeProjects.editLocalProjectData(uuid, "observers", [...users, v]);
    } catch (e) {
      setAlertText(e.response.data.error);
    }
  };
  
  const handleMemberRemove = async (userId) => {
    try {
      const user = users.find((u) => u.id === userId);
      const email=user?.email;
      
      await removeProjectObserverQuery(uuid, email);
      socket?.requestProjectUserRemove(uuid, user.uuid);
      const observer = users.filter((u) => u.id !== userId);
      if (owner && email === owner.email) {
        const newOwner = author;
        observer.push(owner);
        await setProjectOwnerQuery(uuid, newOwner.email);
        activeProjects.editLocalProjectData(uuid, "observers", observer);
        activeProjects.editLocalProjectData(uuid, "owner", newOwner);
      } else {
        activeProjects.editLocalProjectData(uuid, "observers", observer);
        tags.forEach(t => {
          if(t.estimatorUuid === user.uuid)
            removeUserFromWorkTypeQuery(uuid, t.id, user.uuid);
        })
      }
    } catch (e) {
      setAlertText(e.response.data.error);
    }
  };

  const handleFileRemove = async (fileId) => {
    try {
      const attachment = attachments.find((f) => f.id === fileId);
      await removeProjectAttachmentQuery(
        uuid,
        attachment.name,
        attachment.uuid
      );
      activeProjects.editLocalProjectData(
        uuid,
        "attachmentRemove",
        attachment.uuid
      );
    } catch (e) {
      setAlertText(e?.response?.data?.error);
    }
  };

  const handleFileDownload = async (e, fileId) => {
    const attachment = attachments.find((f) => f.id === fileId);
    await downloadFile(attachment.name, undefined, attachment.path);
  };

  return (
    <Grid item container className={sticky ? classes.sticky : undefined}>
      <InfoLabel
        label="views.active.popup.members"
        spacing={32}
        action={
          isOwner ? (
            <ClearButton
              size="small"
              ref={setOwnerAnchorEl}
              onClick={() => showOwnerPicker(!ownerPickerVisible)}
            >
              {owner
                ? t("views.project.change_owner")
                : t("views.project.add_owner")}
            </ClearButton>
          ) : undefined
        }
      />
      <Grid item container id="projectTeamMembers">
        <AvatarList
          users={allUsers}
          removeHandler={isOwner ? handleMemberRemove : undefined}
          size={32}
        />
        {isOwner && (
          <>
            <AddButton
              onClick={() => showMemberSelect(!memberSelectVisible)}
              ref={setMemberSelectAnchorEl}
              style={{ width: 32, height: 32 }}
              className="ml-4"
            />
            <AsyncPicker
              inputName="members-search"
              selected={allUsers}
              isOpen={memberSelectVisible}
              anchorEl={memberSelectAnchorEl}
              disableOptions={(o) => o.status === 0 || o.id === author.id}
              labelKey="fullname"
              inputLabel={t("views.active.search_member")}
              ContentItem={UserItem}
              noItemsText={t("views.active.popup.no_members")}
              title={t("views.active.popup.members")}
              options={teamMembers}
              onClickAway={() => showMemberSelect(false)}
              onAdd={handleMemberAdd}
              onRemove={handleMemberRemove}
            />
            <AsyncPicker
              inputName="owner-picker"
              selected={[owner]}
              isOpen={ownerPickerVisible}
              anchorEl={ownerAnchorEl}
              labelKey="fullname"
              disableOptions={(o) => o.status === 0 || o.id === owner.id}
              inputLabel={t("views.active.search_member")}
              ContentItem={UserItem}
              noItemsText={t("views.active.popup.no_members")}
              title={t("views.active.popup.members")}
              options={teamMembers}
              onClickAway={() => showOwnerPicker(false)}
              onAdd={setOwner}
              idKey="email"
            />
          </>
        )}
      </Grid>

      <InfoLabel
        label={`views.active.popup.${
          isMini ? "pdf_proposals" : "attachments"
        }`}
        spacing={32}
        action={
          isOwner && !isMini ? (
            <AttachmentsEditor
              attachments={attachments}
              uuid={uuid}
              multiple
            />
          ) : undefined
        }
      />
      <Grid item container>
        <TagList
          tags={attachments.map((a) => ({
            id: a.id,
            name: formatFileName(a.name),
          }))}
          onClick={handleFileDownload}
          onRemove={isOwner && !isMini ? handleFileRemove : undefined}
        />
      </Grid>

      <InfoLabel label="views.active.popup.tags" spacing={32} />
      <Grid item container>
        <TagList tags={tags} />
      </Grid>

      <Alert
        title={alertText}
        isOpen={!!alertText}
        onAccept={() => setAlertText("")}
        acceptText={t("common.close")}
      />
    </Grid>
  );
});

const formatFileName = (f) => {
  const spl = f.split(".");
  const name = spl[0],
    ext = spl[1];
  const short = name.slice(0, 20);

  return `${short}${short.length < name.length ? "..." : ""}.${ext}`;
};

const userObj = shape({
  id: number,
  email: string,
  avatarColor: string,
});

ActionPanel.propTypes = {
  uuid: string.isRequired,
  isOwner: bool.isRequired,
  isMini: bool.isRequired,
  owner: userObj,
  author: userObj,
  users: arrayOf(userObj),
  teamMembers: arrayOf(userObj),
  attachments: arrayOf(object).isRequired,
  tags: arrayOf(
    shape({
      id: oneOfType([string, number]).isRequired,
      name: string.isRequired,
    })
  ).isRequired,
  sticky: bool,
};
