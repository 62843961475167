import { bool, number, string, oneOfType, object } from "prop-types";
import { Divider, Grid } from "@material-ui/core";
import useStyle from "../Totals.style";
import classnames from "classnames";
import { NumericFormat } from "react-number-format";

export const TotalValue = ({
  name,
  min,
  max,
  symbolStart,
  symbolEnd,
  showMaxValue,
  textSmaller,
  style,
  showDecimals,
  decimals=2,
  allowNegative=false,
}) => {
  const classes = useStyle();
  
  const formatterProps = {
    allowNegative,
    decimalScale: showDecimals ? decimals : 0,
    thousandSeparator: " ",
    valueIsNumericString: true,
    displayType: "text",
    className: "white-space-pre",
    style,
  };
  
  return (
    <Grid
      item
      container
      alignItems="center"
      className={classnames(
        `preset-total${name}`,
        "w-max font-bold",
        textSmaller ? "text-2xl mt-2 text-secondary" : "text-4xl",
        name === "Time" && "pt-totalTimeMonths"
      )}
    >
      <span className="white-space-pre" style={style}>
        {symbolStart}
      </span>
      <NumericFormat
        value={min}
        {...formatterProps}
      />
      {
        showMaxValue &&
        <Divider className={classnames("total-divider", classes.totalCostDivider)} />
      }
      {
        showMaxValue &&
        <NumericFormat
          value={max}
          {...formatterProps}
        />
      }
      <span className="white-space-pre" style={style}>
        {symbolEnd}
      </span>
    </Grid>
  )
}

TotalValue.propTypes = {
  name: string.isRequired,
  min: oneOfType([number, string]).isRequired,
  max: oneOfType([number, string]),
  symbolStart: string,
  symbolEnd: string,
  showMaxValue: bool,
  textSmaller: bool,
  style: object,
  showDecimals: bool,
  decimals: number,
  allowNegative: bool,
}