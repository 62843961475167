import { SvgIcon } from "@material-ui/core";

export const FileAlt = (props) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <path
      d="M21.334 25C21.334 21.508 24.176 18.666 27.668 18.666H39.846C39.406 17.132 38.006 16 36.334 16H21C18.978 16 17.334 17.644 17.334 19.666V40.332C17.334 42.356 18.978 44 21 44H21.334V25Z"
      fill="#4098F4"
      fillOpacity="0.5"
    />
    <path
      d="M43 21.334H27.666C25.644 21.334 24 22.978 24 25V44.334C24 46.356 25.644 48 27.666 48H43C45.022 48 46.666 46.356 46.666 44.334V25C46.666 22.978 45.022 21.334 43 21.334ZM40.334 44H30.334C29.782 44 29.334 43.552 29.334 43C29.334 42.448 29.782 42 30.334 42H40.334C40.886 42 41.334 42.448 41.334 43C41.334 43.552 40.886 44 40.334 44ZM40.334 38.666H30.334C29.782 38.666 29.334 38.218 29.334 37.666C29.334 37.114 29.782 36.666 30.334 36.666H40.334C40.886 36.666 41.334 37.114 41.334 37.666C41.334 38.218 40.886 38.666 40.334 38.666ZM40.334 34H30.334C29.782 34 29.334 33.552 29.334 33C29.334 32.448 29.782 32 30.334 32H40.334C40.886 32 41.334 32.448 41.334 33C41.334 33.552 40.886 34 40.334 34ZM40.334 28.666H30.334C29.782 28.666 29.334 28.218 29.334 27.666C29.334 27.114 29.782 26.666 30.334 26.666H40.334C40.886 26.666 41.334 27.114 41.334 27.666C41.334 28.218 40.886 28.666 40.334 28.666Z"
      fill="#4098F4"
    />
  </SvgIcon>
);