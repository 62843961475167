import { bool } from "prop-types";
import { observer } from "mobx-react";
import { usePresetFieldNames, useProjectEditorStore, useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { TimelineSettings } from "@dialogs";
import { ValueCell, VisibilityButton } from "@components";

export const TimeCell = observer(({
  allowEdition,
  isProposal,
}) => {
  
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { hideSummaryTime } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryTime);
  
  return (
    <ValueCell
      widest
      isHeader
      size="xSmall"
      className={className}
      style={{ cursor: allowEdition && "grab", }}
    >
      <span className="preset-tableSummaryHeader pt-timeMta">
        {getFieldName("pt-timeMta")}
      </span>
      {
        allowEdition &&
        <TimelineSettings
          small
          useWeekMonthSwitch
          className="noDrag"
        />}
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={8}
          hidden={hideSummaryTime}
          onChange={structure.visibility.setHideSummaryTime}
        />
      }
    </ValueCell>
  )
})

TimeCell.propTypes = {
  isProposal: bool,
  allowEdition: bool,
}