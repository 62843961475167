import { bool, func, object } from "prop-types";
import { ExportWorkType } from "./ExportWorkType";
import { parsePrice, roundFloat, Totals } from "project-structure";
import { usePresetFieldNames } from "@hooks";
import { Columns } from "project-structure";
import { capitalize } from "@material-ui/core";
import { ExportTotals } from "./ExportTotals";

export const ExportTableSummary = ({
  translate,
  editorStore,
  usePreset,
  allowShowingPrices,
}) => {
  const { currentProjectStructure } = editorStore;

  const {
    workTypes,
    settings,
    visibility,
    hasFixedBreakdownPrice,
    fixedBreakdownPrice,
  } = currentProjectStructure;

  const {
    currencyObj,
    hasTeamMembersCol,
    hasTimeCol,
    useMinMax,
    roundPrice,
    visibleSummaryColumns,
    visibleTotals,
  } = settings;
  
  const {
    apply,
    hideSummaryTime,
    hideSummaryTeam,
    hideSummaryHours,
    hideSummaryRate,
    hideSummaryCost,
    hideMinValues,
    hideMaxValues,
  } = visibility;

  const { getFieldName } = usePresetFieldNames(usePreset, editorStore);
  const fixedPrice = roundFloat(
    useMinMax ? fixedBreakdownPrice.min : fixedBreakdownPrice.avg,
    roundPrice ? 3 : 0
  );
  const fixedPriceMax = roundFloat(fixedBreakdownPrice.max, roundPrice ? 3 : 0);

  return (
    <div className="table-summary">
      <h3 className="summary">{getFieldName("pt-summary")}</h3>
      <table>
        <thead>
          <tr>
            <th className="summary" style={{ textAlign: "left" }}>
              {getFieldName("pt-work")}
            </th>
            {visibleSummaryColumns.map(({ name }) => {
              switch (name) {
                case Columns.TEAM:
                  if(apply && hideSummaryTeam) return <></>
                  return (
                    <th key={name} className="summary">
                      {getFieldName("pt-teamMembers")}
                    </th>
                  );
                case Columns.TIME:
                  if(apply && hideSummaryTime) return <></>
                  return (
                    <th key={name} className="summary">
                      {getFieldName("pt-timeMta")}
                    </th>
                  );
                case Columns.RATE:
                  if(apply && hideSummaryRate) return <></>
                  return (
                    allowShowingPrices && (
                      <th key={name} className="summary">
                        {`${getFieldName("pt-rate")} (${currencyObj.code})`}
                      </th>
                    )
                  );
                case Columns.HOURS:
                  if(apply && hideSummaryHours) return <></>
                  return (
                    <th key={name} className="summary">
                      {getFieldName("pt-time")}
                    </th>
                  );
                case Columns.COST:
                  if(apply && hideSummaryCost) return <></>
                  return (
                    allowShowingPrices && (
                      <th key={name} className="summary">
                        {getFieldName("pt-cost")}
                      </th>
                    )
                  );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {workTypes
            .filter(workType => !apply || !workType.isHidden)
            .map((workType) => (
              <ExportWorkType
                key={workType.id}
                element={workType}
                allowShowingPrices={allowShowingPrices}
                translate={translate}
                visibility={visibility}
                settings={settings}
              />
            ))}
          {hasFixedBreakdownPrice && (
            <tr className="system">
              <td
                className="summary-name"
                style={{ textAlign: "left", paddingLeft: 16 }}
                colSpan={
                  2 +
                  (hasTeamMembersCol ? 1 : 0) +
                  (hasTimeCol ? 1 : 0) +
                  (allowShowingPrices ? 1 : 0)
                }
              >
                {getFieldName("pt-fixed")}
              </td>
              {allowShowingPrices && (
                <td>
                  <p className="price">
                    {
                      (!apply || !hideMinValues) &&
                      <>
                        {currencyObj.symbolStart || ""}
                        {parsePrice(
                          roundFloat(
                            fixedPrice,
                            roundPrice ? 3 : 0,
                            !roundPrice,
                          )
                        )}
                        {currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : ""}
                      </>
                    }
                    { useMinMax && fixedPrice && fixedPrice !== fixedPriceMax && (!apply || !(hideMaxValues || hideMinValues)) && "  -  " }
                    { useMinMax && fixedPrice && fixedPrice !== fixedPriceMax && (!apply || !hideMaxValues) && (
                      <>
                        {currencyObj.symbolStart || ""}
                        {parsePrice(
                          roundFloat(
                            fixedPriceMax,
                            roundPrice ? 3 : 0,
                            !roundPrice,
                          )
                        )}
                        {currencyObj.symbolEnd
                          ? " " + currencyObj.symbolEnd
                          : ""}
                      </>
                    )}
                  </p>
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
      <div className="total-container">
        {
          visibleTotals
            .map(({name}) => {
              if(name !== Totals.COST || allowShowingPrices)
                return (
                  <div className="total" key={name}>
                    <p className={`total-${capitalize(name)}-title`}>
                      {getFieldName(`pt-total${capitalize(name)}`)}
                    </p>
                    <ExportTotals
                      name={name}
                      currentProjectStructure={currentProjectStructure}
                      translate={translate}
                    />
                  </div>
                )
            })
        }
      </div>
    </div>
  );
};

ExportTableSummary.propTypes = {
  editorStore: object,
  usePreset: bool,
  allowShowingPrices: bool,
  translate: func,
};
