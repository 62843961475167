import { bool, number, string } from "prop-types";
import { CircularProgress, Grid } from "@material-ui/core";
import useStyle from "./PageCircularProgress.style";
import classnames from "classnames";

export const PageCircularProgressWithLabel = ({
  value,
  label,
  error,
}) => {
  const classes = useStyle();

  return <Grid
    item
    container
    justifyContent="center"
    alignItems="center"
    className={classes.root}
  >
    <CircularProgress
      size={64}
      value={value}
      variant="determinate"
      className={classnames("absolute", error && "color-error")}
    />
    <span className="text-lg transparent-2 color-primary">
      {value}{"%"}
    </span>
  </Grid>;
};

PageCircularProgressWithLabel.propTypes = {
  value: number,
  label: string,
  error: bool,
}