import { useProjectEditorStore, useStructureStore } from "@hooks";
import { useMemo } from "react";
import classnames from "classnames";

export const useVisibilityModeCellClassName = (hide=false) => {
  
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  
  const { apply } = structure.visibility;
  
  return useMemo(() => (
    classnames(visibilityMode && apply && hide && "transparent-2")
  ), [visibilityMode, apply, hide])
}