import { number, object, func, bool } from "prop-types";
import { roundFloat } from "project-structure";
import classnames from "classnames";
import { chunk } from "lodash";

export const ExportTimelineRow = ({
  translate,
  element,
  totalDevelopmentTime,
  modifier,
  roundHours,
  showDecimals,
  usedRoundDecimals,
  depth = 1,
}) => {
  const {
    name,
    presentedTimeline,
    isTimelineOpened,
    hasResources,
    resources,
    isOpened,
    totalTeamMembers,
    teamMembers,
  } = element;

  const opened = hasResources && isOpened;
  const chunkedTimeline = chunk(presentedTimeline?.weeks || [], 4);
  
  return (
    <>
      <tr className={classnames("timeline", opened && "hover")}>
        <td
          className="timeline-name"
          style={{ textAlign: "left", paddingLeft: 16 * depth }}
        >
          {`${name || translate("common.unnamed")}  `}
          {(totalTeamMembers > 1 || teamMembers > 1) && (
            <span className="teamMembers">
              {"   "}
              {totalTeamMembers || teamMembers}
            </span>
          )}
        </td>
        {chunkedTimeline.map((month, i) => {
          const value = roundFloat(
            month.reduce((t, w) => t + (w.hours || 0), 0) / modifier,
            showDecimals ? roundHours : 0,
            false, usedRoundDecimals
          );
          return (
            <td key={`period${i}`} className="timeline">
              {
                (!hasResources || !isOpened) && Boolean(value) && value
              }
            </td>
          )
        })}
      </tr>
      {isTimelineOpened &&
        hasResources &&
        resources.map((resource) => (
          <ExportTimelineRow
            translate={translate}
            key={resource.id}
            element={resource}
            totalDevelopmentTime={totalDevelopmentTime}
            roundHours={roundHours}
            modifier={modifier}
            depth={depth + 1}
            showDecimals={showDecimals}
            usedRoundDecimals={usedRoundDecimals}
          />
        ))}
    </>
  );
};

ExportTimelineRow.propTypes = {
  translate: func.isRequired,
  element: object.isRequired,
  totalDevelopmentTime: number,
  roundHours: number,
  modifier: number,
  depth: number,
  roundType: number,
  usedRoundDecimals: number,
  showDecimals: bool,
};
