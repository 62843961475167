import { ActiveProjectsSocketProvider } from "@client";
import { ArchivedProjectsView } from "./ArchivedProjectsView";

export const ArchivedProjects = () => {

  return (
    <ActiveProjectsSocketProvider>
      <ArchivedProjectsView />
    </ActiveProjectsSocketProvider>
  );
};
