import { observer } from "mobx-react";
import { useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { PriceCell } from "@components";

export const CostCell = observer(() => {
  const structure = useStructureStore();
  const {
    displayFixedBreakdownPrice,
    displayFixedBreakdownPriceMax,
    showMaxFixedBreakdownPrice,
    usesTwoValues
  } = structure;
  const { currencyObj, roundPrice } = structure.settings;
  const { hideSummaryCost } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryCost);
  
  return (
    <PriceCell
      displayContent
      value={displayFixedBreakdownPrice}
      valueMax={displayFixedBreakdownPriceMax}
      displayMax={showMaxFixedBreakdownPrice}
      widest={usesTwoValues}
      showDecimals={!roundPrice}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      allowNegative
      className={className}
    />
  );
});
